import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footerAccessibilityPageFooter";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import pswimg from "../../assets/images/psw-flow1.svg";
import { ResetPassword } from "../../helpers/backendHelper";
import "../../styles/Forgetpsw.css";

const Editpswd = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Must be at least 8 characters")
        .max(20, "Must be at most 20 characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords do not match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      try {
        await ResetPassword({
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        });
        Swal.fire("Success!", "Password reset successfully", "success");
        navigate("/signup");
      } catch (e) {
        console.log("error", e);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error resetting your password. Please try again.",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Close",
        });
      }
    },
  });

  return (
    <>
      <Navbar />
      <div className="d-flex align-items-center pswd">
        <Container className="mt-5">
          <Row className="pswd-row d-flex justify-content-between align-items-center">
            <Col md={6} lg={5}>
              <Card className="pswd-content">
                <h4 className="fw-bold">Set New Password</h4>
                <p className="p-title">Must be at least 8 characters</p>
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="password"
                    name="newPassword"
                    placeholder="New Password"
                    className={`form-control mt-3 mb-2 py-2 input-height ${
                      formik.touched.newPassword && formik.errors.newPassword
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newPassword}
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="invalid-feedback">
                      {formik.errors.newPassword}
                    </div>
                  )
                   : null}
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    className={`form-control mt-3 mb-2 py-2 input-height ${
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={formik.handleChange}     
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                  />
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="invalid-feedback">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                  <button
                    type="submit"
                    className="btn btn-color-primary input-height w-100 py-2 mt-3"
                  >
                    <strong>Continue</strong>
                  </button>
                </form>
              </Card>
            </Col>
            <Col md={5} className="d-none d-md-block">
              <Image
                className="pswd-img"
                src={pswimg}
                alt="Password Reset Illustration"
                fluid
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Editpswd;
