// import React, { useState, useEffect } from 'react';
// import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
// import "../../assets/css/InterestedButtons.css";
 
// const InterestedButtons = ({
//   onSuccess,
//   onMaybe,
//   onRemove,
//   isContacting,
//   onHire,
//   onScheduleInterview,
//   candidate,
//   showScheduleButton
// }) => {
//   const { subApplicationStatus } = candidate.appliedJobs;

//   const isShortlisted = subApplicationStatus?.includes('shortlist') && subApplicationStatus?.includes('reviewed');
//   const isReviewed = subApplicationStatus?.includes('reviewed') && !subApplicationStatus?.includes('shortlist');

//   const handleShortlistClick = () => {
//     const newStatus = isShortlisted ? 'awaitingReview' : 'shortlist';
//     onSuccess(newStatus);
//   };

//   const handleMaybeClick = () => {
//     const newStatus = isReviewed ? 'awaitingReview' : 'reviewed';
//     onMaybe(newStatus);
//   };

//   return (
//     <ButtonGroup>
//       {isContacting ? (
//         <Button variant="primary" size="sm" onClick={onHire}>
//           Hire
//         </Button>
//       ) : (
//         <>
//           <Button
//             variant={isShortlisted ? "success" : "secondary"}
//             size="sm"
//             onClick={handleShortlistClick}
//           >
//             {isShortlisted ? "Shortlisted" : "Add to Shortlist"}
//           </Button>

//           <Button
//             variant={isReviewed ? "warning" : "secondary"}
//             size="sm"
//             onClick={handleMaybeClick}
//           >
//             {isReviewed ? "Marked as Maybe" : "Maybe"}
//           </Button>

//           <Button variant="danger" size="sm" onClick={onRemove}>
//             ✖ Remove
//           </Button>
//         </>
//       )}
//        {showScheduleButton && (<Dropdown as={ButtonGroup}>
//         <Dropdown.Toggle split as={CustomToggle} id="dropdown-custom-components" />
//         <Dropdown.Menu>
//           <Dropdown.Item onClick={() => onScheduleInterview(candidate)}>Schedule Interview</Dropdown.Item>
//           <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
//         </Dropdown.Menu>
//       </Dropdown>)}
//     </ButtonGroup>
//   );
// };
// const CustomToggle = React.forwardRef(({ onClick }, ref) => (
//   <Button
//     variant="light"
//     ref={ref}
//     onClick={(e) => {
//       e.preventDefault();
//       onClick(e);
//     }}
//     className="three-dot-button"
//   >
//     &#x2022;&#x2022;&#x2022;
//   </Button>
// ));


// export default InterestedButtons;

import React from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import "../../assets/css/InterestedButtons.css"; 
const InterestedButtons = ({
  onSuccess,
  onMaybe,
  onRemove,
  isContacting,
  onHire,
  onScheduleInterview,
  candidate,
  showScheduleButton
}) => {
  const { subApplicationStatus,applicationStatus } = candidate?.appliedJobs;
  // const {subApplicationStatus} = candidate

  const isShortlisted = subApplicationStatus?.includes('shortlist') && subApplicationStatus?.includes('reviewed');
  const isReviewed = subApplicationStatus?.includes('reviewed') && !subApplicationStatus?.includes('shortlist');
  const isRejected = applicationStatus === "rejected" ? true : false;
  console.log('isRejected.',isRejected);

  const handleShortlistClick = () => {
    const newStatus = isShortlisted ? 'awaitingReview' : 'shortlist';
    onSuccess(newStatus);
  };

  const handleMaybeClick = () => {
    const newStatus = isReviewed ? 'awaitingReview' : 'reviewed';
    onMaybe(newStatus);
  };

  return (
    <ButtonGroup className="interested-buttons-group">
      <Button
        variant={isShortlisted ? "success" : "light"}
        className={`interested-button ${isShortlisted ? "btn-approved" : ""}`}
        onClick={handleShortlistClick}
      >
        &#10003; 
      </Button>

      <Button
        variant={isReviewed ? "warning" : "light"}
        className={`interested-button ${isReviewed ? "btn-maybe" : ""}`}
        onClick={handleMaybeClick}
      >
        &#10068; 
      </Button>

      <Button
        variant={isRejected ? "danger" : "light"}
        className={`interested-button ${isRejected ? "btn-remove" : ""} `}
        onClick={onRemove}
      >
        &#10005;                                                                                            
      </Button>

      {showScheduleButton && (
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle split as={CustomToggle} id="dropdown-custom-components" />
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onScheduleInterview(candidate)}>Schedule Interview</Dropdown.Item>
            {/* <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </ButtonGroup>
  );
};

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <Button
    variant="light"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="three-dot-button"
  >
    &#x2022;&#x2022;&#x2022;
  </Button>
));

export default InterestedButtons;

