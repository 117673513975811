import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";
import EmployerSidebar from "../../../components/EmployerSidebar";
import Swal from "sweetalert2";
import { employerPostJob, getJobsByJobId } from "../../../helpers/backendHelper";
import { useNavigate, useParams } from "react-router-dom";
import '../../../assets/css/employer/reviewJobPost.css';
import EditModal from "../../../components/common/EditModal";
import "bootstrap-icons/font/bootstrap-icons.css";  // Import Bootstrap Icons
import { isDraft } from "@reduxjs/toolkit";
import { decryptParam } from "../../../helpers/UrlEncryptDecrypt";

const ReviewJobPost = () => {
    const [sidebarExpanded, setSidebarExpanded] = useState(false);
    const [jobData, setJobData] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editField, setEditField] = useState("");
    const [editValue, setEditValue] = useState([]);

    const navigate = useNavigate();
    const secretKey = process.env.REACT_APP_WALKUPP_SECRET_KEY || "walkupp-secret-key";

    const params = useParams();
    let job_id = params.job_id;
    job_id = decryptParam(job_id,secretKey);

    useEffect(() => {
        fetchJobs(job_id);
    }, []);

    const fetchJobs = async (job_id) => {
        try {
            const response = await getJobsByJobId(job_id);
            console.log("respone",response);
            
            if (response && response.statusCode === 200) {
                if (response.jobs.length > 0) {
                    setJobData(response.jobs[0]);
                }
            }
        } catch (e) {
            console.log("error", e);
        }
    };

    const handleConfirmClick = async () => {
        const data = {
            jobId: job_id,
            isDraft: false,
            status: 'opened',
            // salary: {
            //     range: {
            //         minimum: "50000",
            //         maximum: "70000"
            //     }
            // },
        };
        try {
            const response = await employerPostJob(data);
            if (response && response.statusCode === 200) {
                navigate('/employers/jobpostdashbord');
            }
        } catch (e) {
            console.log("error", e);
            Swal.fire({ 
                icon: "error",
                title: "Failed to Post Job",
                text: "Error occurred",
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: "Close",
            });
        }
    };
    const handleEditClick = (field, value, value2) => {             
        setEditField(field);
        if (field === "Schedule" || field === "Job Type") {
            setEditValue(value);
        }
        else if(field === "minimum and maximum salary"){
            setEditValue([value,value2]);
            const edit = [value,value2];
            console.log("editvalue",edit);
            
        }
        else {
            setEditValue(value);
        }
        setShowEditModal(true);
    };

    const handleSaveEdit = async () => {
        const field = editField.toLowerCase();
        let payload = {}
        console.log("editvalue",editValue);
        
        if (field === 'job title') {
            payload = {
                jobId: job_id,
                jobBasics: {
                    jobTitle: editValue
                },
                isDraft: true
            }
        }
        else if (field === 'job description') {
            payload = {
                jobId: job_id,
                jobDescription: editValue,
                isDraft: true
            }
        }
        else if (field === 'schedule') {
            payload = {
                jobId: job_id,
                jobDetails: {
                    schedule: editValue
                },
                isDraft: true
            }
        }
        else if (field === 'job type') {
            payload = {
                jobId: job_id,
                jobDetails: {
                    jobType: editValue
                },
                isDraft: true
            }
        }
        else if (field === 'job type 2'){
            payload = {
                jobId: job_id,
                jobBasics: {
                    jobType: editValue
                },
                isDraft: true
            }
        }

        else if (field === 'planned date'){
            payload = {
                jobId: job_id,
                jobDetails: {
                    plannedDate: editValue
                },
                isDraft: true
            }
        }

        else if (field === 'date'){
            payload = {
                jobId: job_id,
                jobDetails: {
                    date: editValue
                },
                isDraft: true
            }
        }

        else if (field === 'experience'){
            payload = {
                jobId: job_id,
                jobDetails: {
                    experiance: editValue
                },
                isDraft: true
            }
        }

        else if (field === 'number of hire'){
            payload = {
                jobId: job_id,
                jobDetails: {
                    numberOfPositions: editValue
                },
                isDraft: true
            }
        }

        else if (field === 'recruitment timeline'){
            payload = {
                jobId: job_id,
                jobDetails: {
                    recruitmentTimeline: editValue
                },
                isDraft: true
            }
        }

        else if (field === 'minimum and maximum salary'){
            payload = {
                jobId: job_id,
                salary: {
                    range : {
                        minimum: editValue[0],
                        maximum: editValue[1],
                    }
                },
                isDraft: true
            }
        }

        console.log("payload of save",payload);
        

        const updatedJobData = {
            jobId: job_id,
            value: editValue,
            field: editField.toLowerCase()
        };
        
        try {
            const response = await employerPostJob(payload);
            if (response && response.statusCode === 200) {
                setJobData((prevData) => ({
                    ...prevData,
                    jobBasics: {
                        ...prevData.jobBasics,
                        [editField === "Job Title" ? "jobTitle" : 
                            editField === "Job Type 2" ? "jobType" : "companyName"
                        ]:
                            editField === "Job Title" ? editValue :
                            editField === "Job Type 2" ? editValue :
                                prevData.jobBasics.companyName,
                    },
                    jobDetails: {
                        ...prevData.jobDetails,
                        [editField === "Job Type" ? "jobType" :
                        editField === "Schedule" ? "schedule" :
                        editField === "Job Description" ? "description" :
                        editField === "planned date" ? "plannedDate" :
                        editField === "date" ? "date" :
                        editField === "experience" ? "experiance" :
                        editField === "number of hire" ? "numberOfPositions" :
                        editField === "recruitment timeline" ? "recruitmentTimeline" :
                        prevData.jobDetails.description]:
                            editField === "Job Type" ? editValue :
                            editField === "Schedule" ? editValue :
                            editField === "plannedDate" ? editValue :
                            editField === "date" ? editValue :
                            editField === "experience" ? editValue :
                            editField === "number of hire" ? editValue :
                            editField === "recruitment timeline" ? editValue :
                            editValue, // Handle job description update
                    },
                    salary: {
                        range: {
                          ...prevData.salary?.range,
                          // Update minimum and maximum salary based on editField
                          minimum: editField === "minimum and maximum salary" ? editValue[0] : prevData.salary?.range.minimum,
                          maximum: editField === "minimum and maximum salary" ? editValue[1] : prevData.salary?.range.maximum,
                        },
                    },
                    jobDescription: editField === "Job Description"
                    ? editValue
                    : prevData.jobDescription,
                }));
                Swal.fire({
                    icon: "success",
                    title: "Field Updated",
                    text: `${editField} has been successfully updated.`,
                    showConfirmButton: true,
                    confirmButtonText: "Close",
                });
            } else {
                throw new Error("Update failed");
            }
        } catch (error) {
            console.error("Update failed:", error);
            Swal.fire({
                icon: "error",
                title: "Failed to Update",
                text: `Failed to update ${editField}. Please try again.`,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: "Close",
            });
        }
        setShowEditModal(false);
    };

    console.log("jobData",jobData);
    
    
    return (
        <>
            <div id="wrapper">
                <EmployerSidebar sidebarExpanded={sidebarExpanded} />
                <div id="navbar-wrapper">
                    <EmployerNavBar />
                </div>
                <Container className="job-post-page d-flex justify-content-center align-items-center">
                    <div className="section">
                        <h2 className="text-center mb-4">Job Review</h2>
                        {jobData ? (
                            <>
                                <Row className="">
                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Job Title</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobBasics.jobTitle}</span>
                                            {/* <Button variant="link" onClick={() => handleEditClick("Job Title", jobData.jobBasics.jobTitle)}>
                                                <i className="bi bi-pencil-square"></i>
                                            </Button> */}
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Title", jobData.jobBasics.jobTitle)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>

                                    {/* <Col className="mb-4" xs={12}>
                                        <Form.Label>Company Name</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.companyName}</span>

                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Company Name", jobData.companyName)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>

                                        </div>
                                    </Col> */}

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Job Posting Location</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobBasics.jobType}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Type 2", jobData.jobBasics.jobType)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Job Location</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobBasics.jobLocation ? jobData.jobBasics.jobLocation.city : ""}, {jobData.jobBasics.jobLocation ? jobData.jobBasics.jobLocation.area : ""}, {jobData.jobBasics.jobLocation ? jobData.jobBasics.jobLocation.street : ""}, {jobData.jobBasics.jobLocation ? jobData.jobBasics.jobLocation.pincode : ""}</span>
                                            {/* <Button variant="link" className="custom-edit-button"  onClick={() => handleEditClick("Job Location", `${jobData.jobBasics.jobLocation.city}, ${jobData.jobBasics.jobLocation.area}, ${jobData.jobBasics.jobLocation.pincode}`)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button> */}
                                        </div>
                                    </Col>

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Job Type</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.jobType}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Type", jobData.jobDetails.jobType)}>
                                                <i className="bi bi-pencil edit-icon"></i>

                                            </Button>
                                        </div>
                                    </Col>

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Schedule</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.schedule.join(", ")}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Schedule", jobData.jobDetails.schedule)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>is Planned to start date job?</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.plannedDate}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("planned date", jobData.jobDetails.plannedDate)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                    
                                    { jobData.jobDetails.plannedDate === "No" ?
                                        null 
                                        :<Col className="mb-4" xs={12}>
                                            <Form.Label>Planned Start Date</Form.Label>
                                            <div className="d-flex justify-content-between">
                                                <span>{jobData.jobDetails.date}</span>
                                                <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("date", jobData.jobDetails.date)}>
                                                    <i className="bi bi-pencil edit-icon"></i>
                                                </Button>
                                            </div>
                                        </Col>
                                    }

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Experience</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.experiance}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("experience", jobData.jobDetails.experiance)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Number of people you wish to hire for this job</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.numberOfPositions}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("number of hire", jobData.jobDetails.numberOfPositions)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Recruitment timeline for this job</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.recruitmentTimeline}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("recruitment timeline", jobData.jobDetails.recruitmentTimeline)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>

                                    {/* <Col className="mb-4" xs={12}>
                                        <Form.Label>Minimum and Maximum salary</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{ jobData.salary.range.minimum ? jobData.salary.range.minimum : ""} to { jobData.salary.range.maximum ? jobData.salary.range.maximum : ""}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("minimum and maximum salary", jobData.salary.range.minimum, jobData.salary.range.maximum )}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col> */}

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Minimum and Maximum salary</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>
                                                {jobData?.salary?.range?.minimum ? jobData.salary.range.minimum : ""} {jobData?.salary?.range?.minimum ? "to" : ""} {" "}
                                                {jobData?.salary?.range?.maximum ? jobData.salary.range.maximum : ""}
                                            </span>
                                            <Button
                                                variant="link"
                                                className="custom-edit-button"
                                                onClick={() =>
                                                    handleEditClick(
                                                        "minimum and maximum salary",
                                                        jobData?.salary?.range?.minimum || "",
                                                        jobData?.salary?.range?.maximum || ""
                                                    )
                                                }
                                            >
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col className="mb-4" xs={12}>
                                        <Form.Label>Job Description</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <div dangerouslySetInnerHTML={{ __html: jobData.jobDescription }} />
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Description", jobData.jobDescription)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>

                                </Row>


                                {/* <Row className="mb-4">
                                    <Col className="mb-4">
                                        <Form.Label>Job Posting Location</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobBasics.jobType}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Type 2", jobData.jobBasics.jobType)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Company Name</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.companyName}</span>

                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Company Name", jobData.companyName)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Job Location</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobBasics.jobLocation ? jobData.jobBasics.jobLocation.city : ""}, {jobData.jobBasics.jobLocation ? jobData.jobBasics.jobLocation.area : ""}, {jobData.jobBasics.jobLocation ? jobData.jobBasics.jobLocation.pincode : ""}</span>
                                            <Button variant="link" className="custom-edit-button"  onClick={() => handleEditClick("Job Location", `${jobData.jobBasics.jobLocation.city}, ${jobData.jobBasics.jobLocation.area}, ${jobData.jobBasics.jobLocation.pincode}`)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Job Type</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.jobType.join(", ")}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Type", jobData.jobDetails.jobType)}>
                                                <i className="bi bi-pencil edit-icon"></i>

                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Schedule</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.schedule.join(", ")}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Schedule", jobData.jobDetails.schedule)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Job Description</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <div dangerouslySetInnerHTML={{ __html: jobData.jobDescription }} />
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Description", jobData.jobDescription)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row> */}

                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="primary" onClick={handleConfirmClick}>Confirm</Button>
                                </div>
                            </>
                        ) : (
                            <p>Loading job details...</p>
                        )}
                    </div>
                </Container>
            </div>

            <EditModal
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                handleSave={handleSaveEdit}
                field={editField}
                value={editValue}
                setValue={setEditValue}
            />
        </>
    );
};

export default ReviewJobPost;
