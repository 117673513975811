import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Select from "react-select";
// import CreatableSelect from "react-select/creatable";

// import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import Swal from "sweetalert2";
import userImage from "../../../assets/images/user.webp";
import BuildInResumePageFooter from "../../../components/BuildInResumePageFooter";
import CustomProfileModal from "../../../components/common/CustomProfileModal";
import Loader from "../../../components/common/Loader";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";
import EmployerSidebar from "../../../components/EmployerSidebar";
import {
  employerImageUpdate,
  employerProfileUpdate,
  getCityState,
  getEmployer,
} from "../../../helpers/backendHelper";
import * as url from "../../../helpers/urlHelper";
import "../../../styles/Myprofile.css";
import TwoFactorAuth from "./TwoFactorAuth";
import { Button } from "react-bootstrap";

const EmployerProfile = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [dummyImage, setDummyImage] = useState(userImage);
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    employerCity: "",
    employerCountry: "",
    employerArea: "",
    employerPincode: "",
    employerStreet: "",
    isTwoFAEnabled: false,
    isTwoFASetup: false,
    isMobileNumberUser: false,
    isEmailUser: false,
    employerProfileImage: userImage,
    employerCoverImage: userImage,
  });
  const [editMode, setEditMode] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [isSetup2Fa, setIsSetup2Fa] = useState(false);
  const [editableImage, setEditableImage] = useState();
  const [cityStateSuggestions, setCityStateSuggestions] = useState([]);

  useEffect(() => {
    getCityStateSuggestions();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [editMode]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Company Name is required"),
    employerCountry: Yup.string().required("Country is required"),
    employerCity: Yup.string().required("City is required"),
    employerStreet: Yup.string().required("Street is required"),
    employerArea: Yup.string().required("Area is required"),
    employerPincode: Yup.string().required("Pincode is required"),
  });

  const updateAvatar = async (imgSrc) => {
    setProfileImageLoading(true);
    const base64ToFile = (base64String, fileName) => {
      const arr = base64String.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    };

    const formData = new FormData();
    if (editableImage === "profileImage") {
      const file = base64ToFile(imgSrc, "profileImage.png");
      formData.append("profileImage", file);
    } else if (editableImage === "coverImage") {
      const file = base64ToFile(imgSrc, "coverImage.png");
      formData.append("coverImage", file);
    }

    try {
      await employerImageUpdate(formData);
      fetchUserData();
    } catch (error) {
      console.error("Error updating avatar:", error);
    }
  };

  const getCityStateSuggestions = async () => {
    try {
      const response = await getCityState();

      if (response && response.statusCode === 200) {
         const data = response.cities;
        // setCityStateSuggestions(data.map(city => (`${city.city},${city.state}`)));
        // setCityStateSuggestions(data.map(city => (`${city.city}, ${city.state}`))); // Note the space after the comma
        // setCityStateSuggestions(data.map(city => ({ value: `${city.city},${city.state}`, label: `${city.city},${city.state}` })));
        setCityStateSuggestions(data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const closeSidebar = (event) => {
    if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
      setSidebarExpanded(false);
      const wrapper = document.getElementById("wrapper");
      wrapper.classList.remove("toggled");
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await getEmployer();
      if (response && response.statusCode === 200) {
        const data = response.employer;
        setUserData(data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  // const handleChangeTwoFA = () => {
  //   setIsSetup2Fa(!isSetup2Fa);
  // };

  const handleClose2Fa = () => {
    setIsSetup2Fa(false);
  };

  const handleSave = async (values) => {
    try {
       setIsLoading(true);
      const payload = {
        email: userData.email,
        mobileNumber: values.mobileNumber,
        name: values.name,
        employerCountry: values.employerCountry,
        employerCity: values.employerCity, // Extracting the value property

        employerArea: values.employerArea,
        employerPincode: values.employerPincode,
        employerStreet: values.employerStreet,
        isTwoFAEnabled: userData.isTwoFAEnabled,
      };

      const updateData = await employerProfileUpdate(payload);

      if (updateData && updateData.statusCode === 200) {
        Swal.fire("Updated!", "Profile updated successfully", "success");
        setIsLoading(false);
        navigate("/employers/jobpostdashbord");
      }
      setEditMode(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleCamaraClick = (value) => {
    setEditableImage(value);
    setModalOpen(true);
  };

  return (
    <div id="wrapper" onClick={closeSidebar}>
      <EmployerSidebar sidebarExpanded={sidebarExpanded} />

      <div id="navbar-wrapper">
        <EmployerNavBar />
      </div>
      <Container className="mt-lg-4 mt-3">
        <Row className="">
          {modalOpen && (
            <CustomProfileModal
              updateAvatar={updateAvatar}
              closeModal={() => setModalOpen(false)}
              showModal={modalOpen}
              editableImage={editableImage}
            />
          )}
          <Col lg={6}>
            <Card className="bg-white border-0">
              {/* <div className="rounded-3 mb-4 position-relative bg-white rounded">
                <Image
                  src={
                    userData.employerCoverImage === "null"
                      ? dummyImage
                      : `${url.IMAGE_HOST_URL}${userData.employerCoverImage}`
                  }
                  className="img-fluid rounded-3 cover-image w-100"
                />
                <span>
                  <FontAwesomeIcon
                    icon={faCamera}
                    size="lg"
                    className="position-absolute camera-icon"
                    onClick={() => handleCamaraClick("coverImage")}
                  />
                </span>
                {profileImageLoading}
                <Image
                  src={
                    userData.employerProfileImage === "null"
                      ? dummyImage
                      : `${url.IMAGE_HOST_URL}${userData.employerProfileImage}`
                  }
                  className="img-fluid rounded-3 rounded-circle profile-image-circle"
                />
                <FontAwesomeIcon
                  icon={faCamera}
                  size="lg"
                  className="camera-icon2 position-absolute"
                  onClick={() => handleCamaraClick("profileImage")}
                />
              </div> */}

              <div className="background-container">
                  <Image
                    src={
                      userData.employerCoverImage === "null"
                        ? dummyImage
                        : `${url.IMAGE_HOST_URL}${userData.employerCoverImage}`
                    }
                    className="background-image"
                  />
                <div className="profile-container">
                      <Image
                        src={
                          userData.employerProfileImage === "null"
                            ? dummyImage
                            : `${url.IMAGE_HOST_URL}${userData.employerProfileImage}`
                        }
                        className="profile-image"
                      />
                    <FontAwesomeIcon
                      icon={faCamera}
                      size="lg"
                      className="camera-icon"
                      onClick={() => handleCamaraClick("profileImage")}
                    />
                </div>
                  <FontAwesomeIcon
                    icon={faCamera}
                    size="lg"
                    className="background-camera"
                    onClick={() => handleCamaraClick("coverImage")}
                  />
              </div>

              <div className="text-center mt-sm-4">
                <h5 className="mt-5">{userData.name}</h5>
                <p className="text-muted">
                  {userData.employerCity}, {userData.employerCountry}
                </p>
              </div>
              <div className="p-3">
                <h5>Info</h5>
                <p className="text-justify">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>

              {/* <div className="profile-container">
                  <Image
                    src={
                      userData.employerProfileImage === "null"
                        ? dummyImage
                        : `${url.IMAGE_HOST_URL}${userData.employerProfileImage}`
                    }
                    className="profile-image"
                  />
                  <FontAwesomeIcon
                    icon={faCamera}
                    size="lg"
                    className="camera-icon-new"
                    onClick={() => handleCamaraClick("profileImage")}
                  />
              </div> */}

            </Card>
          </Col>
          <Col className="mt-4 mt-lg-0" lg={6}>
            <Card className="border-0 bg-white">
              <CardHeader>
                <Nav tabs>
                  <NavItem className="cursor-pointer">
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => toggleTab("1")}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem className="cursor-pointer">
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => toggleTab("2")}
                    >
                      Two Factor Authentication Setup
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                     <Formik
                      initialValues={{
                        name: userData.name,
                        email: userData.email,
                        employerCountry: userData.employerCountry,
                        employerCity: userData.employerCity,
                        employerStreet: userData.employerStreet,
                        employerArea: userData.employerArea,
                        employerPincode: userData.employerPincode,
                        mobileNumber: userData.mobileNumber,
                      }}
                      validationSchema={validationSchema}
                      onSubmit={handleSave}
                      enableReinitialize
                    >
                      {(formik) => (
                        <Form>
                          <Row>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="name">Company Name</Label>
                                <Field
                                  type="text"
                                  name="name"
                                  id="name"
                                  className="form-control"
                                  disabled={!editMode}
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <h5>Company Address</h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="employerCountry">Country</Label>
                                <Field
                                  type="text"
                                  name="employerCountry"
                                  id="employerCountry"
                                  className="form-control"
                                  disabled={!editMode}
                                />
                                <ErrorMessage
                                  name="employerCountry"
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="employerCity">CityState</Label>

                                <select
                                  id="employerCity"
                                  name="employerCity"
                                  className="form-control"
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "employerCity",
                                      e.target.value
                                    )
                                  }
                                  value={formik.values.employerCity}
                                  disabled={!editMode}
                                >
                                  <option value="">Select a city</option>
                                  {cityStateSuggestions.map((cityObj) => (
                                    <option
                                      key={cityObj._id}
                                      value={`${cityObj.city}, ${cityObj.state}`}
                                    >
                                      {cityObj.city}, {cityObj.state}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage
                                  name="employerCity"
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="employerStreet">Street</Label>
                                <Field
                                  type="text"
                                  name="employerStreet"
                                  id="employerStreet"
                                  className="form-control"
                                  disabled={!editMode}
                                />
                                <ErrorMessage
                                  name="employerStreet"
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="employerArea">Area</Label>
                                <Field
                                  type="text"
                                  name="employerArea"
                                  id="employerArea"
                                  className="form-control"
                                  disabled={!editMode}
                                />
                                <ErrorMessage
                                  name="employerArea"
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="employerPincode">Pincode</Label>
                                <Field
                                  type="text"
                                  name="employerPincode"
                                  id="employerPincode"
                                  className="form-control"
                                  disabled={!editMode}
                                />
                                <ErrorMessage
                                  name="employerPincode"
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="email">Email</Label>
                                <Field
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  disabled={!editMode || userData.isEmailUser}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="mobileNumber">Phone</Label>
                                <Field
                                  type="text"
                                  name="mobileNumber"
                                  id="mobileNumber"
                                  className="form-control"
                                  disabled={
                                    !editMode || userData.isMobileNumberUser
                                  }
                                />
                                <ErrorMessage
                                  name="mobileNumber"
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Button
                            className="edit-btn"
                            onClick={
                              editMode ? formik.handleSubmit : handleEdit
                            }
                          >
                            {isLoading ? (
                              <Loader />
                            ) : editMode ? (
                              "Save"
                            ) : (
                              "Edit"
                            )}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </TabPane>
                  <TabPane tabId="2">
                    <Button
                      className="edit-btn"
                      onClick={() => setIsSetup2Fa(!isSetup2Fa)}
                    >
                      Setup 2FA
                    </Button>
                    {isSetup2Fa && (
                      <TwoFactorAuth
                        handleClose={handleClose2Fa}
                        emailOrMobileNumber={userData.email}
                        onComplete={() => toggleTab("1")}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <BuildInResumePageFooter />
    </div>
  );
};

export default EmployerProfile;
