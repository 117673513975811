import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Table, Button, Form, Image } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import '../../../assets/css/employer/Candidate.css';
import EmployerSidebar from '../../../components/EmployerSidebar';
import EmployerNavBar from '../../../components/Employer/EmployerNavbar';
import { getApplicationStatusCount, getJobApplicationByEmployerId, scheduleInterView, updateApplicationByStatusByEmployer } from '../../../helpers/backendHelper';
import InterestedButtons from '../../../components/common/InterestedButtonGroup';
import InterviewScheduler from '../../../components/InterviewScheduler';
import Swal from 'sweetalert2';
import { IsNullOrEmpty, isObjectEmpty } from '../../../helpers/utility';
import { useParams } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import { decryptParam } from '../../../helpers/UrlEncryptDecrypt';
import { IMAGE_HOST_URL } from '../../../helpers/urlHelper';
import Nodata from "../../../assets/images/no-data.webp";

const CandidateDashboard = () => {

  const secretKey = process.env.REACT_APP_WALKUPP_SECRET_KEY || "walkupp-secret-key";

  const params = useParams();
  const jobId = decryptParam(params.jobId, secretKey);
  const isFromJob = params.isFromJob;
  console.log("params candidate", jobId, isFromJob);

  const [activeTab, setActiveTab] = useState('active');
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [candidateId, setCandidateId] = useState();
  const [appliedJobId, setAppliedJobId] = useState();
  const [candidatesData, setCandidateData] = useState({
    active: [],
    shortlist: [],
    awaitingReview: [],
    reviewed: [],
    contacting: [],
    rejected: [],
    hired: [],
  });

  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [statusCounts, setStatusCounts] = useState([]);
  const [jobApplicationPayload, setJobApplicationPayload] = useState({
    page: activePage,
    status: activeTab,
    subStatus: ""
  });

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
    // const total = Math.ceil(candidatesData[activeTab].length/8);
    // console.log("total",total);
    // setTotalPage(total > 0 ? total : 1);

    if (['active', 'hired', 'rejected'].includes(tab)) {
      const tabPayload = {
        page: 1,
        status: tab,
        subStatus: ""
      };
      fetchCandidates(tabPayload);

      setJobApplicationPayload(tabPayload);
      console.log("jobApplicationPayload 1", jobApplicationPayload);
    } else {
      const tabPayload = {
        page: 1,
        status: "active",
        subStatus: tab,
      };
      fetchCandidates(tabPayload);

      setJobApplicationPayload(tabPayload);
      console.log("jobApplicationPayload 2", jobApplicationPayload);
    }
  };

  useEffect(() => {
    if (loading || activePage) {
      const payload = {
        page: activePage,
        status: "active",
        subStatus: ""
      }
      console.log("jobApplicationPayload", jobApplicationPayload);

      fetchCandidates(jobApplicationPayload);

      // if(jobApplicationPayload === payload){
      //   fetchCandidates(jobApplicationPayload);
      // }
      // else{
      //   setJobApplicationPayload(payload);
      //   fetchCandidates(payload); 
      // }
    }
  }, [loading, activePage]);

  const handleScheduleInterview = async (candidateDetails) => {
    const candidatePayload = {
      ...candidateDetails,
      jobSeeker_id: candidateId,
      jobId: appliedJobId,
      status: 'upcoming'
    }
    const interviewResponse = await scheduleInterView(candidatePayload)
    console.log('interviewResponse', interviewResponse)
    if (!IsNullOrEmpty(interviewResponse)) {
      console.log('interviewResponse')

      if (interviewResponse.statusCode === 200) {
        Swal.fire({
          title: 'Success',
          text: 'interview schedule successfully!',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      }
      else {
        Swal.fire({
          title: 'error',
          text: interviewResponse.message,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      }
    }
    else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close",
      });
    }

  };

  console.log("candidatesData", candidatesData);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  }

  // Render pagination numbers dynamically
  const renderPageNumbersJobs = () => {
    const pages = [];

    // Display pagination for less than 5 pages without ellipsis

    if (totalPage <= 5) {
      for (let number = 1; number <= totalPage; number++) {
        pages.push(
          <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }
    }

    else {
      // Display ellipsis and limited page numbers if more than 5 pages
      if (activePage > 2) {
        pages.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>{1}</Pagination.Item>);
        if (activePage > 3) pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      for (let number = Math.max(1, activePage - 1); number <= Math.min(totalPage, activePage + 1); number++) {
        pages.push(
          <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }

      if (activePage < totalPage - 2) {
        pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
        pages.push(
          <Pagination.Item key={totalPage} onClick={() => handlePageChange(totalPage)}>
            {totalPage}
          </Pagination.Item>
        );
      }
    }

    return pages;
  };

  const fetchCandidates = async (data) => {
    console.log("fetchCandidatesPayload", data);

    try {
      const response = await getJobApplicationByEmployerId(data);
      if (response && response.statusCode === 200) {
        console.log("fetchCandidates", response);
        setStatusCounts(response.statusCounts);
        setTotalPage(response.pagination.totalPages);

        let appliedJobsData = response.appliedJobsData;
        console.log("appliedJobsData", appliedJobsData);
        if (isFromJob === 'true' || isFromJob === true && jobId) {
          appliedJobsData = appliedJobsData.filter((job) => job.appliedJobs.jobId === jobId);
        }
        splitCandidatesByStatus(appliedJobsData);
      } else {
        console.error('Failed to fetch candidates');
      }
    } catch (e) {
      console.error('Error during fetch', e);
    } finally {
      setLoading(false);
    }
  };

  const splitCandidatesByStatus = (appliedJobsData) => {
    const statusGroups = {
      active: [],
      shortlist: [],
      awaitingReview: [],
      reviewed: [],
      contacting: [],
      rejected: [],
      hired: [],
    };

    appliedJobsData.forEach((job) => {
      const { applicationStatus, subApplicationStatus } = job.appliedJobs;

      if (applicationStatus === 'active') {
        statusGroups.active.push(job);
        subApplicationStatus.forEach((status) => {
          const normalizedStatus = status.toLowerCase().replace(/\s/g, '');
          if (normalizedStatus.includes('awaitingreview')) {
            statusGroups.awaitingReview.push(job);
          }
          else if (normalizedStatus.includes('reviewed')) {
            statusGroups.reviewed.push(job);
          }
          else if (normalizedStatus.includes('shortlist')) {
            statusGroups.shortlist.push(job);
          }
          else if (normalizedStatus.includes('contacting')) {
            statusGroups.contacting.push(job);
          }
        });
      }
      if (applicationStatus === 'rejected') {
        statusGroups.rejected.push(job);
      }
      if (applicationStatus === 'hired') {
        statusGroups.hired.push(job);
      }
    });
    setCandidateData(statusGroups);
  };

  const handleInterestedClick = async (candidateId, newStatus) => {
    try {
      let data = {};
      let newSubStatuses = [];

      switch (newStatus) {
        case 'shortlist':
          newSubStatuses = ['shortlist', 'reviewed'];;
          data = { id: candidateId, payload: { status: 'active', subStatuses: newSubStatuses } };
          break;
        case 'reviewed':
          newSubStatuses = ['reviewed'];
          data = { id: candidateId, payload: { status: 'active', subStatuses: newSubStatuses } };
          break;
        case 'awaitingReview':
          data = { id: candidateId, payload: { status: 'active', subStatuses: ['awaitingReview'] } };
          break;
        case 'hired':
          data = { id: candidateId, payload: { status: 'hired' } };
          break;
        case 'remove':
          data = { id: candidateId, payload: { status: 'rejected' } };
          break;
        default:
          console.error('Unknown status');
          return;
      }

      const response = await updateApplicationByStatusByEmployer(data);

      if (response && response.statusCode === 200) {
        fetchCandidates(jobApplicationPayload);
        setCandidateData((prevData) => {
          const updatedCandidates = { ...prevData };
          for (let category in updatedCandidates) {
            updatedCandidates[category] = updatedCandidates[category].map((candidate) => {
              if (candidate.appliedJobs._id === candidateId) {
                return {
                  ...candidate,
                  appliedJobs: {
                    ...candidate.appliedJobs,
                    applicationStatus: newStatus,
                    subApplicationStatus: newSubStatuses,
                  },
                };
              }
              return candidate;
            });
          }

          return updatedCandidates;
        });
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleOpenModal = (candidate) => {
    if (!isObjectEmpty(candidate)) {
      setCandidateId(candidate.appliedJobs.applicantId);
      setAppliedJobId(candidate.appliedJobs.jobId);
    }
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div id="wrapper">
        <EmployerSidebar sidebarExpanded={sidebarExpanded} />
        <div id="navbar-wrapper">
          <EmployerNavBar />
        </div>
        <div className="candidate-dashboard">
          <div className="header">
            <h4>Candidates</h4>
            <Button variant="primary" className='btn-color-primary'>Post a job</Button>
          </div>
          {/* <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
            <Tab eventKey="active" title={`Active (${candidatesData.active.length})`} />
            <Tab eventKey="awaitingReview" title={`Awaiting Review (${candidatesData.awaitingReview.length})`} />
            <Tab eventKey="shortlist" title={`Shortlist (${candidatesData.shortlist.length})`} />
            <Tab eventKey="reviewed" title={`Reviewed (${candidatesData.reviewed.length})`} />
            <Tab eventKey="contacting" title={`Contacting (${candidatesData.contacting.length})`} />
            <Tab eventKey="rejected" title={`Rejected (${candidatesData.rejected.length})`} />
            <Tab eventKey="hired" title={`Hired (${candidatesData.hired.length})`} />
          </Tabs> */}

          <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
            {console.log('candidates', statusCounts)}
            <Tab eventKey="active" title={`Active (${statusCounts ? statusCounts.active : ""})`} />
            <Tab eventKey="awaitingReview" title={`Awaiting Review (${statusCounts ? statusCounts.awaitingReview : ""})`} />
            <Tab eventKey="shortlist" title={`Shortlist (${statusCounts ? statusCounts.shortlist : ""})`} />
            <Tab eventKey="reviewed" title={`Reviewed (${statusCounts ? statusCounts.reviewed : ""})`} />
            <Tab eventKey="contacting" title={`Contacting (${statusCounts ? statusCounts.contacting : ""})`} />
            <Tab eventKey="rejected" title={`Rejected (${statusCounts ? statusCounts.rejected : ""})`} />
            <Tab eventKey="hired" title={`Hired (${statusCounts ? statusCounts.hired : "  "})`} />
          </Tabs>

          {/* <div className="candidate-filters">
            <Form.Select className="sort-dropdown">
              <option>Sort by: Apply date (newest first)</option>
              <option>Sort by: Apply date (oldest first)</option>
              <option>Sort by: Name (A-Z)</option>
            </Form.Select>
            <Button variant="link" className="clear-btn">Clear all</Button>
          </div> */}

          {candidatesData[activeTab]?.length > 0 ? (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    {/* <th><Form.Check type="checkbox" /></th> */}
                    <th></th>
                    <th>Candidate</th>
                    <th>Job applied to</th>
                    <th>Applied Date</th>
                    <th>Resume</th>
                    {activeTab !== "hired" && activeTab !== "rejected" ? <th>Interested?</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {candidatesData[activeTab]?.map((candidate) => (
                    <tr key={candidate.appliedJobs._id}>
                      <td><Form.Check type="checkbox" /></td>
                      <td>
                        <div className="candidate-name">
                          <span className="name">{candidate.candidate?.firstName || candidate.candidate?.email}</span>
                          <span className="status">{candidate.appliedJobs.applicationStatus}</span>
                        </div>
                      </td>
                      <td>{candidate.jobDetails.jobTitle}</td>
                      <td>{new Date(candidate.appliedJobs.applicationDate).toLocaleDateString()}</td>
                      {/* <td>
                        <Button className="btn-color-primary" onClick={() => downloadFile(`http://localhost:3030${candidate.appliedJobs.resumePath}`)}>
                          <FaFilePdf />
                        </Button>
                      </td> */}
                      <td>
                        <Button
                          className="btn-color-primary"
                          onClick={() =>
                            downloadFile(
                              `${IMAGE_HOST_URL}${candidate.appliedJobs.resumePath}`,
                              `${candidate.candidate?.firstName || "candidate"}_resume.pdf`
                            )
                          }
                        >
                          <FaFilePdf />
                        </Button>
                      </td>
                      {console.log("candidatesTab", activeTab)}
                      {/* {(candidatesData[activeTab] !== candidatesData.hired || candidatesData.rejected) ? <td>
                        <InterestedButtons
                          key={candidate.appliedJobs._id}  
                          onSuccess={(newStatus) => handleInterestedClick(candidate.appliedJobs._id, newStatus)}
                          onMaybe={(newStatus) => handleInterestedClick(candidate.appliedJobs._id, newStatus)}
                          onRemove={() => handleInterestedClick(candidate.appliedJobs._id, 'remove')}
                          onHire={() => handleInterestedClick(candidate.appliedJobs._id, 'hired')}
                          onScheduleInterview={() => handleOpenModal(candidate)}
                          candidate={candidate}
                          showScheduleButton={activeTab === "shortlist"}
                        />
                      </td> : null} */}
                      {activeTab !== "hired" && activeTab !== "rejected" && (
                        <td>
                          <InterestedButtons
                            key={candidate.appliedJobs._id}
                            onSuccess={(newStatus) => handleInterestedClick(candidate.appliedJobs._id, newStatus)}
                            onMaybe={(newStatus) => handleInterestedClick(candidate.appliedJobs._id, newStatus)}
                            onRemove={() => handleInterestedClick(candidate.appliedJobs._id, "remove")}
                            onHire={() => handleInterestedClick(candidate.appliedJobs._id, "hired")}
                            onScheduleInterview={() => handleOpenModal(candidate)}
                            candidate={candidate}
                            showScheduleButton={activeTab === "shortlist"}
                          />
                        </td>
                      )}
                    </tr>
                  ))}

                </tbody>
              </Table>

              <Pagination className="justify-content-center mt-5 custom-pagination">
                <Pagination.First onClick={() => handlePageChange(1)} disabled={activePage === 1} />
                <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
                {renderPageNumbersJobs()}
                <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPage} />
                <Pagination.Last onClick={() => handlePageChange(totalPage)} disabled={activePage === totalPage} />
              </Pagination>
            </>

          ) : (
            <Image className='nodata-img' src={Nodata} />
          )}
        </div>
      </div>
      <InterviewScheduler
        showModal={showModal}
        handleScheduleInterview={handleScheduleInterview}
        handleCloseModal={handleCloseModal}
      />

    </>
  );
};

// const downloadFile = (url) => {
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', 'resume.pdf');
//   document.body.appendChild(link);
//   link.click();
//   link.remove();
// };

const downloadFile = (url, fileName = "resume.pdf") => {
  if (!url) {
    Swal.fire({
      icon: "error",
      title: "Download Failed",
      text: "No file URL found.",
    });
    return;
  }

  fetch(url, { method: "GET" })
    .then((response) => {
      if (!response.ok) throw new Error("Failed to fetch file.");
      return response.blob();
    })
    .then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Download Error",
        text: "Unable to download the resume. Please try again.",
      });
      console.error("Error downloading file:", error);
    });
};

export default CandidateDashboard;
