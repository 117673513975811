import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Card,
} from "react-bootstrap";
import "../../styles/ThemeColors.css";
import { CiFileOn } from "react-icons/ci";

function Addresume({ onContinue }) {

  document.title = "Walkupp";

  const [selectedCard, setSelectedCard] = useState(null);
  const [fileName,setFileName] = useState(null);
  const [file,setFile] = useState({});
  const [errorMessage,setErrorMessage] = useState("");
  
  const handleContinue = (e) => { 
    e.preventDefault();

    if(!fileName){
      setErrorMessage("Please upload a file before submitting.");
    }
    else{
      setErrorMessage("");
      onContinue(file);
    }
  };

  const handleCardClick = (index) => {
    setSelectedCard(index);
    document.getElementById("fileInput").click();
  };

  const handleCardHover = (index) => {
    // Implement your logic for the hover event here
  };

  const cardStyle = (index) => {
    // Change border color for the selected card
    return index === selectedCard ? { border: "2px solid #007bff" } : {};
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setFile({file})
    setFileName(file.name)
    setErrorMessage("");
  };
  return (
    
    <Container fluid className="mt-4">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="card p-3">
            <Row>
              <Col xs="auto" className="text-start">
                <a href="/" className="btn btn-link text-decoration-none d-flex text-dark gap-2">
                  <i className="bi bi-arrow-left fs-5 mt-1"></i> 
                  <p>Back</p>
                </a>
              </Col>
              <Col className="text-end">
                <a href="/" className="btn btn-link text-decoration-none text-dark">
                  Save & Exit
                </a>
              </Col>
            </Row>
            <Row >
              <Col >
                <ProgressBar now={40} className="custom-progress-bar"/>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <p className="h5">Add a CV for the employer</p>
              </Col>
            </Row>

            <Row>
              <Col className="mt-3">
                <Card
                  className=""
                  onClick={() => handleCardClick(1)}
                  onMouseEnter={() => handleCardHover(1)}
                  onMouseLeave={() => handleCardHover(null)}
                  style={cardStyle(1)}
                >
                  <Card.Body className="cursor-pointer">
                    <Row className="align-items-center">
                      <Col lg={1} xs={1} className="text-center fs-3">
                        {/* <i className="bi bi-file-earmark-text fs-4"></i> */}
                        <CiFileOn />
                      </Col>
                      <Col lg={11} xs={10}>
                        <p className="fw-bold text-dark mb-1">
                          Upload a CV
                        </p>
                          <p className="mb-1">
                            Accepted file types are PDF, DOCX, RTF or TXT.
                          </p>
                          {fileName && (
                          <p className="text-muted mb-0">
                            File Name: {fileName}
                          </p>
                        )}

                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
              </Col>
            </Row>
            
            { errorMessage && (
              <p className="my-2 text-danger">{errorMessage}</p>
            )}

            <Row className="p-2">
              <Button
                className="btn-color-two"
                xs={12}
                type="submit"
                // style={{backgroundColor:"#3790CB"}}
                onClick={handleContinue}
              >
                Submit
              </Button>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Addresume;
