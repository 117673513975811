import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import Reviews from "./MyReviewsPage/Reviews";
import Questions from "./MyReviewsPage/Questions";
import Answers from "./MyReviewsPage/Answers";
import Navbar from "../../components/Navbar";

const MyReviews = () => {
  const [tab, setTab] = useState("Reviews");

  const handleClick = (name) => {
    setTab(name);
  };

  const showingDetails = () => {
    if (tab === "Reviews") {
      return <Reviews />;
    } else if (tab === "Questions") {
      return <Questions />;
    } else if (tab === "Interviews") {
      return;
    } else if (tab === "Answers") {
      return <Answers />;
    }
  };
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <Container className="mt-2">
        <Row>
          <Col lg={12} xs={12}>
            <h1 className="mt-4">
              <strong>My Reviews & contributions</strong>
            </h1>
            <div className="text-secondary mt-3">
              <p>
                Your reviews, questions and answers will appear on the
                employer's company page . they are not associated with your
                name,CV or job applications.
              </p>
            </div>
          </Col>
          <Col lg={12} xs={12} className="mt-2">
            <Link onClick={() => handleClick("Reviews")}>
              <Button className="text me-2" variant="warning">
                Reviews
              </Button>
            </Link>
            <Link onClick={() => handleClick("Questions")}>
              <Button className="text me-2" variant="danger">
                Questions
              </Button>
            </Link>
            <Link onClick={() => handleClick("Answers")}>
              <Button className="text me-2 " variant="success">
                Answers
              </Button>
            </Link>

            <hr />
          </Col>
          {showingDetails()}
        </Row>
      </Container>
    </div>
  );
};

export default MyReviews;
