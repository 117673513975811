import React from 'react';

import { faCheck, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import "../../../assets/css/InterviewCard.css";
import getImage from '../../../assets/images/user.webp';
import { IMAGE_HOST_URL } from '../../urlHelper';
import Pagination from 'react-bootstrap/Pagination';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import Swal from 'sweetalert2';

const InterviewCard = (props) => {
  const { data, OnStatusChange, tab, totalPage, activePage, setActive } = props;

  // const downloadFile = (fileUrl) => {
  //   fetch(fileUrl)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'resume.pdf');
  //       document.body.appendChild(link);
  //       link.click();
  //       link.parentNode.removeChild(link);
  //     });
  // };

  const downloadFile = (url, fileName = "resume.pdf") => {
    if (!url) {
      Swal.fire({
        icon: "error",
        title: "Download Failed",
        text: "No file URL found.",
      });
      return;
    }
   
    fetch(url, { method: "GET" })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch file.");
        return response.blob();
      })
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Download Error",
          text: "Unable to download the resume. Please try again.",
        });
        console.error("Error downloading file:", error);
      });
  };

  // const itemsPerPage = 10;
  // const totalPages = Math.ceil(data.length / itemsPerPage);
  // const [currentPage,setCurrentPage] = useState(1);

  // const currentData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setActive(pageNumber);
  }

  // Render pagination numbers dynamically
  const renderPageNumbersJobs = () => {
    const pages = [];

    // Display pagination for less than 5 pages without ellipsis

    if (totalPage <= 5) {
      for (let number = 1; number <= totalPage; number++) {
        pages.push(
          <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }
    }

    else {
      // Display ellipsis and limited page numbers if more than 5 pages
      if (activePage > 2) {
        pages.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>{1}</Pagination.Item>);
        if (activePage > 3) pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      for (let number = Math.max(1, activePage - 1); number <= Math.min(totalPage, activePage + 1); number++) {
        pages.push(
          <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }

      if (activePage < totalPage - 2) {
        pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
        pages.push(
          <Pagination.Item key={totalPage} onClick={() => handlePageChange(totalPage)}>
            {totalPage}
          </Pagination.Item>
        );
      }
    }

    return pages;
  };

  console.log("data", data);


  return (
    <div>
      {data.map((candidate) => (
        <div key={candidate.id} className="profileCard mt-3">
          {console.log("candidate",candidate)}
          <div className="profileCard-header">
            <img src={`${candidate.jobSeekerProfileImage ? IMAGE_HOST_URL + candidate.jobSeekerProfileImage : getImage}`} alt="user" className="profileCard-image" />
            <div className="profileCard-info">
              <h2>{candidate.name}</h2>
              <p>{candidate.jobTitle}</p>
              <p>{candidate.email}</p>
            </div>
          </div>
          <div className="profileCard-footer">
            {/* <button className="resume-btn" onClick={() => downloadFile(`http://localhost:3030/${candidate.resumePath}`)}>
              Resume <FontAwesomeIcon icon={faDownload} />
            </button> */}
            <Button
              className="btn-color-primary"
              onClick={() =>
                downloadFile(
                  `${IMAGE_HOST_URL}${candidate.resumePath}`,
                  `${candidate.candidate?.firstName || "candidate"}_resume.pdf`
                )
              }
            >
              <FaFilePdf />
            </Button>
            {tab === "upcomdding" && (
              <div className="action-buttons">
                <button
                  className="select-btn"
                  onClick={() => OnStatusChange('SetupInterView', candidate)}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="reject-btn"
                  onClick={() => OnStatusChange('Reject', candidate)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )}
            <div className="action-buttons">
              {tab === "upcoming" && (
                <div className="action-buttons">
                  <button
                    className="btn-color-primary bg-success"
                    onClick={() => OnStatusChange('completed', candidate, 'hired')}
                  >
                    Complete
                  </button>

                  <button
                    className="btn-color-primary bg-danger"
                    onClick={() => OnStatusChange('Reject', candidate, 'reject')}
                  >
                    Reject
                  </button>
                </div>
              )}

              {candidate.applicationStatus === "active" && tab === "completed" ?
                <div className="action-buttons">
                  <button
                    className="btn-color-primary bg-success"
                    onClick={() => OnStatusChange('', candidate, 'hired')}
                  >
                    Complete
                  </button>

                  <button
                    className="btn-color-primary bg-danger"
                    onClick={() => OnStatusChange('', candidate, 'reject')}
                  >
                    Reject
                  </button>
                </div>
                : null
              }

            </div>

          </div>
        </div>
      ))}

      <Pagination className="justify-content-center mt-5 custom-pagination">
        <Pagination.First onClick={() => handlePageChange(1)} disabled={activePage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
        {renderPageNumbersJobs()}
        <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPage} />
        <Pagination.Last onClick={() => handlePageChange(totalPage)} disabled={activePage === totalPage} />
      </Pagination>

    </div>
  );
};

export default InterviewCard;
