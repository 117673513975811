import React from "react";
import { Table, Container } from "react-bootstrap";
import AccessibilityPageFooter from "../../components/footerAccessibilityPageFooter";

function DeviceManagement() {
  // Mock data for demonstration purposes
  const deviceData = [
    {
      device: "Device 1",
      dateLoggedIn: "2023-01-01",
      ipAddress: "192.168.1.1",
    },
    {
      device: "Device 2",
      dateLoggedIn: "2023-01-02",
      ipAddress: "192.168.1.2",
    },
  ];

  return (
    <div>
      <Container className="mt-4">
        <h2>Device Management</h2>
        <p>Your Currently signed into your indeed account these devices</p>
        <Table className="mt-4" striped bordered hover>
          <thead>
            <tr>
              <th>Device</th>
              <th>Date Logged In</th>
              <th>IP Address</th>
            </tr>
          </thead>
          <tbody>
            {deviceData.map((device, index) => (
              <tr key={index}>
                <td>{device.device}</td>
                <td>{device.dateLoggedIn}</td>
                <td>{device.ipAddress}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <div className="mt-30">
        <AccessibilityPageFooter />
      </div>
    </div>
  );
}

export default DeviceManagement;
