import React, { useState } from "react";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import EmployerSidebar from "../../../components/EmployerSidebar";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";
import '../../../assets/css/employer/salaryDetails.css';
import { employerPostJob } from "../../../helpers/backendHelper";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { isDraft } from "@reduxjs/toolkit";
import { decryptParam, encryptParam } from "../../../helpers/UrlEncryptDecrypt";

function SalaryDetails({ onContinue }) {

  const params = useParams();
  const secretKey = process.env.REACT_APP_WALKUPP_SECRET_KEY || "walkupp-secret-key";

  let job_id = params.job_id;
  job_id = decryptParam(job_id,secretKey);
  
  const navigate = useNavigate();
  
  const validationSchema = Yup.object().shape({
    minSalary: Yup.number().required("Minimum salary is required"),
    maxSalary: Yup.number()
      .required("Maximum salary is required")
      .min(Yup.ref("minSalary"), "Maximum salary must be greater than minimum salary"),
  });

  // const [minSalary, setMinSalary] = useState('');
  // const [maxSalary, setMaxSalary] = useState('');
  const [sidebarExpanded, setSidebarExpanded] = useState(false);


  const formik = useFormik({
    initialValues: {
      minSalary: "",
      maxSalary: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {      
      const data = {
        jobId:job_id,
        isDraft:true,
        salary: {
          range: {
            minimum: parseInt(values.minSalary, 10),
            maximum: parseInt(values.maxSalary, 10)
          }
        },
      };
      

      employerPostJob(data)
      .then((x) => {
          if (x && x.statusCode === 200) {
               navigate(`/employer/jobDescription/${encryptParam(job_id,secretKey)}`);
          }
      })
      .catch((e) => {
          console.log("error", e);
          Swal.fire({
              icon: "error",
              title: "Failed to Job Post",
              text: "Error occurred",
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "Close",
          });
      });
   
  
      //onContinue("", data);
    },
  });

  const closeSidebar = (event) => {
    if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
      setSidebarExpanded(false);
      const wrapper = document.getElementById("wrapper");
      wrapper.classList.remove("toggled");
    }
  };

  return (
    <div id="wrapper" onClick={closeSidebar}>
       <EmployerSidebar sidebarExpanded={sidebarExpanded} />
       <div id="navbar-wrapper">
        <EmployerNavBar />
      </div>
      <Container className="mt-3">
        <Row className="justify-content-center add-job-container">
          <Col xs={12} sm={12} md={12} lg={8} className="p-0">
            <Card className="p-lg-5 p-3 bg-light border-0 rounded">
              <Row>
                <Col>
                  <h4>Add Pay</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h5>Salary Details</h5>
                  <p>Enter the minimum and maximum package for the job.</p>
                </Col>
              </Row>
              <hr />

              {/* Salary Details */}
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Minimum Salary Per Annum</Form.Label>
                      <Form.Control
                        type="number"
                        {...formik.getFieldProps("minSalary")}
                        placeholder="10K"
                        isInvalid={formik.touched.minSalary && formik.errors.minSalary}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.touched.minSalary && formik.errors.minSalary}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Maximum Salary Per Annum</Form.Label>
                      <Form.Control
                        type="number"
                        {...formik.getFieldProps("maxSalary")}
                        placeholder="30K"
                        isInvalid={formik.touched.maxSalary && formik.errors.maxSalary}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.touched.maxSalary && formik.errors.maxSalary}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Continue Button */}
                <Row className="mt-3 justify-content-end">
                  <Col>
                    <Button variant="primary" type="submit">
                      Continue
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SalaryDetails;
