import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import icon9 from "../../../assets/images/icon-9.jpg";
import Job_image from "../../../assets/images/job_image.png";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";
import EmployerSidebar from "../../../components/EmployerSidebar";
import { getEmployer, getJobsByEmployer, getJobSuggestions, updateJobByEmployer } from "../../../helpers/backendHelper";
import "../../../styles/EmployerJobPostListedPageSidebar.css";
import Pagination from 'react-bootstrap/Pagination';
import { encryptParam } from "../../../helpers/UrlEncryptDecrypt";
import Nodata from "../../../assets/images/no-data.webp";

function EmployerJobPostListedPageSidebarMenu() {
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));  

  const secretKey = process.env.REACT_APP_WALKUPP_SECRET_KEY || "walkupp-secret-key";

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [subModeJob, setSubModeJob] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobStatus, setJobStatus] = useState("opened");
  const [sortOrder, setSortOrder] = useState("descending");
  const [searchTitle, setSearchTitle] = useState("");
  const [jobTitles, setJobTitles] = useState([]);
  const [activePage,setActivePage] = useState(1);
  const [totalPage,setTotalPage] = useState();
  const [profileDetail,setProfileDetail] = useState({});

  const fetchJobs = async (data) => {
    try {
      // const data = {};
      const response = await getJobsByEmployer(data);
      console.log('response getjobs', response)
      if (response && response.statusCode === 200) {
        if (response.jobs.length > 0) {
          console.log('inside the job len')
          setSubModeJob(true);
          setJobs(response.jobs);
          setTotalPage(response.totalPages);
        } else {
          setSubModeJob(false);
          setJobs([]);
        }
      }
      else{
        setJobs([]);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const fetchJobTitles = async () => {
    try {
      const response = await getJobSuggestions();

      if (response && response.statusCode === 200) {
        const data = response.jobTitle.map(job => `${job.name}`);
        setJobTitles(data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    fetchJobTitles();
    fetchEmployer();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      // fetchJobs(searchTitle);
      const payload = {
        page: activePage,
        status: jobStatus
      }
      fetchJobs(payload);
    }, 100);
    return () => clearTimeout(timer);
  }, [jobStatus, searchTitle, activePage]);

  const sortJobs = (jobs) => {
    return [...jobs].sort((a, b) => {
      if (sortOrder === "ascending") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else if (sortOrder === "descending") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return 0;
    });
  };

  const closeSidebar = (event) => {
    if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
      setSidebarExpanded(false);
      const wrapper = document.getElementById("wrapper");
      wrapper.classList.remove("toggled");
    }
  };

  const handleNavClick = (status) => {
    setJobStatus(status);
  };

  const handleDropdownToggle = async (jobId) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to close this job?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    });
    if (confirmed.isConfirmed) {
      try {
        const data = {
          inputData: { status: "closed" },
          id: jobId,
        };
        const jobUpdate = await updateJobByEmployer(data);
        if (jobUpdate.statusCode === 200) {
          Swal.fire({
            title: "Success",
            text: "Job closed successfully!",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
          const payload = {
            page: activePage,
            status: jobStatus
          }
          fetchJobs(payload);
        }
      } catch (error) {
        console.error("Error closing job:", error);
      }
    }
  };

  const handleTitleSelect = (title) => {
    setSearchTitle(title);    
  };
  
  const sortedCurrentData = jobs.sort((a,b)=>{
    if(a.status === "Draft" && b.status !== "Draft"){
      return -1;
    }
    else if(a.status !== "Draft" && b.status === "Draft"){
      return 1;
    }
    else{
      return 0;
    }
  });  

  
  // const itemsPerPage = 10;
  // const totalPages = Math.ceil(sortedCurrentData.length / itemsPerPage);
  // const [currentPage,setCurrentPage] = useState(1);

  // const currentData = sortedCurrentData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber); 
  }

  // Render pagination numbers dynamically
  const renderPageNumbersJobs = () => {
    const pages = [];

    // Display pagination for less than 5 pages without ellipsis
    
    if (totalPage <= 5) {
        for (let number = 1; number <= totalPage; number++) {
            pages.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }
    }

    else {
        // Display ellipsis and limited page numbers if more than 5 pages
        if (activePage > 2) {
            pages.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>{1}</Pagination.Item>);
            if (activePage > 3) pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        for (let number = Math.max(1, activePage - 1); number <= Math.min(totalPage, activePage + 1); number++) {
            pages.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (activePage < totalPage - 2) {
            pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
            pages.push(
                <Pagination.Item key={totalPage} onClick={() => handlePageChange(totalPage)}>
                    {totalPage}
                </Pagination.Item>
            );
        }
    }

    return pages;
  };

  let draftJobId = jobs.find((job)=> job.status === "Draft");
  
  const fetchEmployer = async () => {
    const response = await getEmployer();
    if(response && response.statusCode === 200){
      setProfileDetail(response);
    }
  }
  
  const navigate = useNavigate();  

  const handleButton = () => {
    console.log("profileDetail",profileDetail.employer.isProfileUpdate);
    
    if(profileDetail.employer.isProfileUpdate === true){
      if(draftJobId){
        navigate(`/employer/jobPostReview/${encryptParam(draftJobId._id,secretKey)}`);
      }
      else{
        navigate('/employer/createJobBasics');
      }
    }
    else{
      Swal.fire({
        title: "You need to update your profile!",
        text: "To create a job, you need to update your profile first.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/employer/employerProfile");
        }
      });
    }
  }

  return (
    <div id="wrapper" onClick={closeSidebar}>
      <EmployerSidebar sidebarExpanded={sidebarExpanded} />

      <div id="navbar-wrapper">
        <EmployerNavBar />
      </div>

      <section id="content-wrapper">
        <Container>
          <Row className="justify-content-between">
            <Col sm={6}>
              <h4>Jobs</h4>
            </Col>
            <Col sm={6} className="text-end mt-2 mt-sm-0">
              {/* <Link to="/employer/createJobBasics">Post Jobs</Link> */}
              <Button variant="primary" className="btn-color-primary" onClick={handleButton}>Post Jobs</Button>
            </Col>
          </Row>

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="mt-3">
              <Col xs={12} sm={12}>
                <Nav variant="pills" className="flex-row">
                  <Nav.Item className=" job-tab-btn rounded me-2 me-sm-3 my-2 my-sm-0">
                    <Nav.Link
                      eventKey="first"
                      onClick={() => handleNavClick("opened")}
                      // className="job-tab-btn"
                    >
                      Active
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Button eventKey="first" className="job-tab-btn">Active</Button> */}
                  
                  <Nav.Item className="job-tab-btn rounded my-2 my-sm-0">
                    <Nav.Link
                      eventKey="second"
                      onClick={() => handleNavClick("closed")}
                    >
                      Closed
                    </Nav.Link>
                  </Nav.Item>

                  {/* <Button eventKey="second" className="job-tab-btn">Closed</Button> */}
                </Nav>
              </Col>
            </Row>
            <Row className="mt-3 p-3 bg-white rounded">
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Row className="d-flex justify-content-start">
                      <Col lg={4} className="mb-2">
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            variant="white"
                            className="p-2 w-100 border-0 box-shadow text-truncate"
                          >
                            <span>
                              <Image src={icon9} height="23" />
                            </span>{" "}
                            Sort by: Posting date{" "}
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="ms-1"
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-100">
                            <Dropdown.Item className="text-truncate" onClick={() => setSortOrder("ascending")}>
                              Posting Date (Ascending)
                            </Dropdown.Item>
                            <Dropdown.Item className="text-truncate" onClick={() => setSortOrder("descending")}>
                              Posting Date (Descending)
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>

                      <Col lg={4} className="mb-2">
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            variant="white"
                            className="p-2 w-100 border-0 box-shadow text-truncate"
                          >
                            <span>
                              <Image src={icon9} height="23" />
                            </span>{" "}
                            {searchTitle || "Select Job Title"}{" "}
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="ms-1"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="select-job-scroll">
                            {jobTitles.map((title, index) => (
                              <Dropdown.Item
                                key={index}
                                onClick={() => handleTitleSelect(title)}
                              >
                                {title}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    
                    {!subModeJob ? (
                      <Row className="mt-5">
                        <Col sm={12}>
                          <Image
                            src={Job_image}
                            className="mx-auto d-block img-fluid mb-3"
                          />
                        </Col>
                      </Row>
                    ) : (
                      <>
                        { jobs.length > 0 ?
                          <>
                          {jobs.map((job, index) => (
                            <Row
                              key={index}
                              className="bg-job-list rounded mb-3 p-4 p-lg-1 mt-3"
                            >
                              <Col xs={12} sm={12} className="py-3">
                                <Row>
                                  <Col lg={2}>
                                    <Row>
                                      <Col xs={12} className="text-center">
                                        <h6 className="p-1 p-lg-0 cardtext">
                                          {job.jobBasics.jobTitle}
                                        </h6>
                                      </Col>
                                      <Col xs={12} className="text-center">
                                        <p className="cardtext p-1 p-lg-0">
                                          {job.jobBasics.jobLocation ? job.jobBasics.jobLocation.city : ""}
                                        </p>
                                      </Col>
                                      <Col xs={12} className="text-center">
                                        <p className="cardtext p-1 p-lg-0">{job.jobBasics.jobLocation ? job.jobBasics.jobLocation.pincode : ""}</p>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col lg={2} className="text-left-mobile-view p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                    {job.status.toLowerCase() === "draft" ? (
                                      <Link to={`/employer/jobPostReview/${encryptParam(job._id,secretKey)}`} className="text-decoration-none">
                                        <Button variant="primary" className="fw-bold">
                                          Complete Your Job Post
                                        </Button>
                                      </Link>
                                    ) : (
                                    <Link to={`/employer/candidates/${encryptParam(job._id,secretKey)}/${true}`} className="text-dark text-decoration-none">
                                      <p className="fw-bold cardtext"><span>{job.appliedJobCount}</span> Applicants</p>
                                    </Link>
                                    )}
                                  </Col>

                                  <Col lg={2} className="d-flex justify-content-center align-items-center">
                                    <p className="fw-bold cardtext">{job.status}</p>
                                  </Col>

                                  <Col lg={2} className="p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                    {job.status.toLowerCase() === "draft" ? null : (
                                      <p className="fw-bold cardtext"><span>{job.hiredCount} of{" "}
                                        {job.jobDetails.numberOfPositions}</span> Hired</p>
                                    )}
                                  </Col>

                                  <Col lg={2} className="p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                    {job.status.toLowerCase() === "draft" ? null : (
                                      <p className="fw-bold cardtext" title={moment(job.updatedAt).format("MMMM Do YYYY, h:mm a")}>
                                        Posted {moment(job.updatedAt).fromNow()}
                                      </p>
                                    )}
                                  </Col>

                                  <Col lg={2} className="text-center-mobile-view d-flex justify-content-center align-items-center">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="cardtext px-3"
                                        align={{ lg: 'end' }}
                                      >
                                        <span className="btn-color-primary"></span>Action
                                        <span className="arrow-down"></span>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {job.status.toLowerCase() !== "draft" && (
                                          <Dropdown.Item onClick={() => handleDropdownToggle(job._id)}>
                                            Close Job
                                          </Dropdown.Item>
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ))}

                          <Pagination className="justify-content-center mt-5 custom-pagination">
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={activePage === 1} />
                            <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
                            {renderPageNumbersJobs()}
                            <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPage} />
                            <Pagination.Last onClick={() => handlePageChange(totalPage)} disabled={activePage === totalPage} />
                          </Pagination>
                        </> : <Image className='nodata-img' src={Nodata} />
                        }
                      </>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    {/* Similar structure for closed jobs can be implemented here */}
                    { jobs.length > 0 ? 
                    <>
                      {jobs.map((job, index) => (
                        <Row
                          key={index}
                          className="bg-job-list rounded mb-3 p-4 p-lg-1 mt-3"
                        >
                          <Col xs={12} className="py-3">
                            <Row>
                              <Col lg={3}>
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <h6 className="p-1 p-lg-0 cardtext">
                                      {job.jobBasics.jobTitle}
                                    </h6>
                                  </Col>
                                  <Col xs={12} className="text-center">
                                    <p className="cardtext p-1 p-lg-0">
                                      {job.jobBasics.jobLocation ? job.jobBasics.jobLocation.city : ""}
                                    </p>
                                  </Col>
                                  <Col xs={12} className="text-center">
                                    <p className="cardtext p-1 p-lg-0">{job.jobBasics.jobLocation ? job.jobBasics.jobLocation.pincode : ""}</p>
                                  </Col>
                                </Row>
                              </Col>

                              <Col lg={2} className="text-left-mobile-view p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                {job.status.toLowerCase() === "draft" ? (
                                  <Link to={`/employer/jobPostReview/${encryptParam(job._id,secretKey)}`} className="text-decoration-none">
                                    <Button variant="primary" className="fw-bold">
                                      Complete Your Job Post
                                    </Button>
                                  </Link>
                                ) : (
                                <Link to={`/employer/candidates/${encryptParam(job._id,secretKey)}/${true}`} className="text-dark text-decoration-none">
                                  <p className="fw-bold cardtext"><span>{job.appliedJobCount}</span> Applicants</p>
                                </Link>
                                )}
                              </Col>

                              <Col lg={2} className="d-flex justify-content-center align-items-center">
                                <p className="fw-bold cardtext">{job.status}</p>
                              </Col>

                              <Col lg={2} className="p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                {job.status.toLowerCase() === "draft" ? null : (
                                  <p className="fw-bold cardtext"><span>{job.hiredCount} of{" "}
                                    {job.jobDetails.numberOfPositions}</span> Hired</p>
                                )}
                              </Col>

                              <Col lg={3} className="p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                {job.status.toLowerCase() === "draft" ? null : (
                                  <p className="fw-bold cardtext" title={moment(job.updatedAt).format("MMMM Do YYYY, h:mm a")}>
                                    Posted {moment(job.updatedAt).fromNow()}
                                  </p>
                                )}
                              </Col>

                            </Row>
                          </Col>
                        </Row>
                      ))}

                      <Pagination className="justify-content-center mt-5 custom-pagination">
                        <Pagination.First onClick={() => handlePageChange(1)} disabled={activePage === 1} />
                        <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
                        {renderPageNumbersJobs()}
                        <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPage} />
                        <Pagination.Last onClick={() => handlePageChange(totalPage)} disabled={activePage === totalPage} />
                      </Pagination>
                    </> :
                    <Image className='nodata-img' src={Nodata} />
                    }
                    {/* <p>Closed jobs will be displayed here.</p> */}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </div>
  );
}

export default EmployerJobPostListedPageSidebarMenu;
