import React, { useState } from "react";
import { Nav, Navbar, Container, Col, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faMobile,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import HeaderNavbar from "../../components/Navbar";
import AccountSettings from "../settings/Accountsettings";
import Devicemanagement from "../settings/Devicemanagement";
import Emailsettings from "../settings/Emailsettings";
import Privacysettings from "../settings/Privacysettings";
import Offcanvas from 'react-bootstrap/Offcanvas';

function NavbarSettingsSidebar() {
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const renderSection = () => {
    switch (activeLink) {
      case "account":
        return <AccountSettings />;
      case "email":
        return <Emailsettings />;
      case "device":
        return <Devicemanagement />;
      case "privacy":
        return <Privacysettings />;
      default:
        return <AccountSettings />;
    }
  };

  return (
    <div>
      <HeaderNavbar />
      <Container fluid
        style={{
                maxWidth:"1550px"
        }}
      >
        <Row>
          <Col className="mt-3 d-block d-md-none">
            <Button className="btn-colors" onClick={handleShow}>
              Show Menu
            </Button>

            <Offcanvas show={show} onHide={handleClose} className="offcanvas-navbar">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Menu</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Navbar>
                  <Nav className="flex-column">
                    <Nav.Link
                      style={{
                        backgroundColor:
                          activeLink === "account" ? "#ffffff" : "#f8f9fa",
                        color: activeLink === "account" ? "#495057" : undefined,
                      }}
                      onClick={() => {
                        handleLinkClick("account");
                        handleClose();
                      }}
                    >
                      <FontAwesomeIcon icon={faUser} className="me-2" />
                      Account Settings
                    </Nav.Link>
                    <Nav.Link
                      style={{
                        backgroundColor:
                          activeLink === "email" ? "#ffffff" : "#f8f9fa",
                        color: activeLink === "email" ? "#495057" : undefined,
                      }}
                      onClick={() => {
                        handleLinkClick("email");
                        handleClose();
                      }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                      Email Settings
                    </Nav.Link>
                    <Nav.Link
                      style={{
                        backgroundColor:
                          activeLink === "device" ? "#ffffff" : "#f8f9fa",
                        color: activeLink === "device" ? "#495057" : undefined,
                      }}
                      onClick={() => {
                        handleLinkClick("device");
                        handleClose();
                      }}
                    >
                      <FontAwesomeIcon icon={faMobile} className="me-2" />
                      Device Management
                    </Nav.Link>
                    <Nav.Link
                      style={{
                        backgroundColor:
                          activeLink === "privacy" ? "#ffffff" : "#f8f9fa",
                        color: activeLink === "privacy" ? "#495057" : undefined,
                      }}
                      onClick={() => {
                        handleLinkClick("privacy");
                        handleClose();
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} className="me-2" />
                      Privacy Settings
                    </Nav.Link>
                  </Nav>
                </Navbar>
              </Offcanvas.Body>
            </Offcanvas>

          </Col>
          
          <Col
          className="d-none d-md-block"
            xs={12}
            md={3}
            xl={2}
            style={{
              backgroundColor: "#f8f9fa",
              minHeight: "100vh",
            }}
          >
            <Navbar>
              <Nav className="flex-column">
                <Nav.Link
                  style={{
                    backgroundColor:
                      activeLink === "account" ? "#ffffff" : "#f8f9fa",
                    color: activeLink === "account" ? "#495057" : undefined,
                  }}
                  onClick={() => handleLinkClick("account")}
                >
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  Account Settings
                </Nav.Link>
                <Nav.Link
                  style={{
                    backgroundColor:
                      activeLink === "email" ? "#ffffff" : "#f8f9fa",
                    color: activeLink === "email" ? "#495057" : undefined,
                  }}
                  onClick={() => handleLinkClick("email")}
                >
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  Email Settings
                </Nav.Link>
                <Nav.Link
                  style={{
                    backgroundColor:
                      activeLink === "device" ? "#ffffff" : "#f8f9fa",
                    color: activeLink === "device" ? "#495057" : undefined,
                  }}
                  onClick={() => handleLinkClick("device")}
                >
                  <FontAwesomeIcon icon={faMobile} className="me-2" />
                  Device Management
                </Nav.Link>
                <Nav.Link
                  style={{
                    backgroundColor:
                      activeLink === "privacy" ? "#ffffff" : "#f8f9fa",
                    color: activeLink === "privacy" ? "#495057" : undefined,
                  }}
                  onClick={() => handleLinkClick("privacy")}
                >
                  <FontAwesomeIcon icon={faLock} className="me-2" />
                  Privacy Settings
                </Nav.Link>
              </Nav>
            </Navbar>
          </Col>
          {/* Content */}
          <Col xs={12} md={9} xl={10}>
            {/* <Navbar bg="light" expand="lg" className="d-md-none">
              <Navbar.Toggle onClick={toggleSidebar} aria-controls="sidebar" />
            </Navbar> */}
            <Container className="justify-content-center p-0">
              {renderSection()}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NavbarSettingsSidebar;
