import React, { useEffect } from "react";
// import { signInWithGoogle } from "../../helpers/Config/firebase";

const GoogleLogin = () => {
  useEffect(() => {
    handleGoogleSignIn();
  }, []);
  const handleGoogleSignIn = async () => {
    try {
      // const result = await signInWithGoogle();
      const result = ""
      console.log('result',result.user)
      const token = await result.user.getIdToken();
      console.log('google token',token)
      const response = await fetch('http://localhost:5000/auth/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  // const handleFacebookSignIn = async () => {
  //   try {
  //     const result = await signInWithFacebook();
  //     const token = await result.user.getIdToken();
  //     const response = await fetch('http://localhost:5000/auth/facebook', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ token })
  //     });
  //     const data = await response.json();
  //     console.log(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div>
      {/* <button onClick={handleGoogleSignIn}>Sign in with Google</button>
      <button onClick={handleFacebookSignIn}>Sign in with Facebook</button> */}
    </div>
  );
};

export default GoogleLogin;
