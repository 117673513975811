import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import '../../../assets/css/employer/jobBasics.css';
import { jobBasicsValidation } from '../../../helpers/Validation/jobPostValidation';
import EmployerNavBar from '../../../components/Employer/EmployerNavbar';
import EmployerSidebar from '../../../components/EmployerSidebar';
import { employerPostJob, getCityState, getJobSuggestions } from '../../../helpers/backendHelper';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Col, Row } from 'reactstrap';
import { encryptParam } from '../../../helpers/UrlEncryptDecrypt';

const JobBasics = () => {

    const navigate = useNavigate();
    const secretKey = process.env.REACT_APP_WALKUPP_SECRET_KEY || "walkupp-secret-key";

    const [sidebarExpanded, setSidebarExpanded] = useState(false);
    const { control, handleSubmit, watch, unregister, formState: { errors } } = useForm({
        resolver: zodResolver(jobBasicsValidation),
        defaultValues: { jobTitle: '', jobType: 'onSite', city: '', area: '', pinCode: '', street: '', specificCityField: '' }
    });

    const [cityOptions, setCityOptions] = useState([]);
    const [suggestionOptions, setSuggestionsOption] = useState([]);
    const [filteredJobSuggestions, setFilteredJobSuggestions] = useState([]);
    const [filteredCitySuggestions, setFilteredCitySuggestions] = useState([]);
    const [showJobSuggestions, setShowJobSuggestions] = useState(false);
    const [showCitySuggestions, setShowCitySuggestions] = useState(false);
    const [isSpecificCityRequired, setIsSpecificCityRequired] = useState(null);

    const watchJobType = watch('jobType');
    const watchIsSpecificCityRequired = watch('isSpecificCityRequired');


    useEffect(() => {
        if (watchJobType === 'onSite') {
            unregister('specificCityField');
        } else if (watchJobType === 'remote') {
            unregister('city');
            unregister('area');
            unregister('pinCode');
            unregister('street');
        }
    }, [watchJobType, unregister]);

    useEffect(() => {
        fetchCityState();
        fetchJobSuggestion();
    }, []);

    const onSubmit = (data) => {             
         const jobBasics = {
            jobTitle: data.jobTitle,
            jobLocation: {
                type: data.jobType
            },
            jobType:data.jobType
        };
        
         if (data.jobType === 'onSite') {
            jobBasics.jobLocation.city = data.city;
            jobBasics.jobLocation.area = data.area;
            jobBasics.jobLocation.pincode = data.pinCode;
            jobBasics.jobLocation.street = data.street;
        } else if (data.jobType === 'remote') {
            // jobBasics.jobLocation.isSpecificCityRequired = data.isSpecificCityRequired;
    
            // if (data.isSpecificCityRequired === 'yes') {
            //     jobBasics.jobLocation.specificCityField = data.specificCityField;
            // }
            
            jobBasics.jobLocation.city = data.city;
        }
    
        const jobBasicsPayload = {
            jobBasics,
            isDraft: true   
        };    
        console.log('Form Submitted:', jobBasicsPayload);
    
         employerPostJob(jobBasicsPayload)
            .then((x) => {
                if(x && x.statusCode === 200) {
                    const job_id = encryptParam(x.job._id,secretKey);
                    navigate(`/employer/createJobDetails/${job_id}`);
                }
            })
            .catch((e) => {
                console.log("error", e);
                Swal.fire({
                    icon: "error",
                    title: "Failed to Job Post",
                    text: "Error occurred",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: "Close",
                });
            });
    };
    


    const closeSidebar = (event) => {
        if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
            setSidebarExpanded(false);
            const wrapper = document.getElementById("wrapper");
            wrapper.classList.remove("toggled");
        }
    };

    const fetchJobSuggestion = async () => {
        try {
            const response = await getJobSuggestions();
            if (response && response.statusCode === 200) {
                const data = response.jobTitle.map(job => `${job.name}`);
                setSuggestionsOption(data);
            }
        } catch (e) {
            console.error("Error fetching job data:", e);
        }
    }

    const fetchCityState = async () => {
        try {
            const response = await getCityState();
            if (response && response.statusCode === 200) {
                const data = response.cities.map(city => `${city.city}, ${city.state}`);
                setCityOptions(data);
            }
        } catch (error) {
            console.error("Error fetching city/state data:", error);
        }
    };

    const handleJobTitleChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length >= 3) {
            const filtered = suggestionOptions.filter(option =>
                option.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredJobSuggestions(filtered);
            setShowJobSuggestions(true);
        } else {
            setFilteredJobSuggestions([]);
            setShowJobSuggestions(false);
        }
    };

    const handleCityChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length >= 3) {
            const filtered = cityOptions.filter(option =>
                option.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredCitySuggestions(filtered);
            setShowCitySuggestions(true);
        } else {
            setFilteredCitySuggestions([]);
            setShowCitySuggestions(false);
        }
    };

    const selectJobSuggestion = (value, field) => {
        field.onChange(value);
        setShowJobSuggestions(false);
    };

    const selectCitySuggestion = (value, field) => {
        field.onChange(value);
        setShowCitySuggestions(false);
    };

    const handleSpecificCityChange = (e) => {
        setIsSpecificCityRequired(e.target.value === "yes");
    };

    return (
        <>
            <div id="wrapper" onClick={closeSidebar}>
                <EmployerSidebar sidebarExpanded={sidebarExpanded} />
                <div id="navbar-wrapper">
                    <EmployerNavBar />
                </div>
                <div className="custom-form-container">

                    <form className="custom-form" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col className="text-center">
                                <h2>Add job basics</h2>
                            </Col>
                        </Row>

                        <div className="form-section">
                            <label>Job Title</label>
                            {console.log('erroros form', errors)}
                            <Controller
                                name="jobTitle"
                                control={control}
                                render={({ field }) => (
                                    <div className="autocomplete-wrapper">
                                        <input
                                            type="text"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleJobTitleChange(e);
                                            }}
                                        />
                                        {showJobSuggestions && filteredJobSuggestions.length > 0 && (
                                            <ul className="suggestions-list">
                                                <li className="suggestions-header">Popularly used titles</li>
                                                {filteredJobSuggestions.map((suggestion, index) => (
                                                    <li
                                                        key={index}
                                                        className="suggestion-item"
                                                        onClick={() => selectJobSuggestion(suggestion, field)}
                                                    >
                                                        {suggestion}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                )}
                            />
                            {errors.jobTitle && <div className="error-message">{errors.jobTitle.message}</div>}
                        </div>
                        {/* Job Posting Location */}
                        <div className="form-section">
                            <label>Job Posting Location</label>
                            <Controller
                                name="jobType"
                                control={control}
                                render={({ field }) => (
                                    <select {...field}>
                                        <option value="">Select job type</option>
                                        <option value="onSite">On-Site</option>
                                        <option value="remote">Remote</option>
                                    </select>
                                )}
                            />
                            {errors.jobType && <div className="error-message">{errors.jobType.message}</div>}
                        </div>

                        {/* Fields for On-Site Jobs */}
                        {watchJobType === 'onSite' && (
                            <>
                                <div className="form-section">
                                    <label>City</label>
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="autocomplete-wrapper">
                                                <input
                                                    type="text"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        handleCityChange(e);
                                                    }}
                                                />
                                                {showCitySuggestions && filteredCitySuggestions.length > 0 && (
                                                    <ul className="suggestions-list">
                                                        <li className="suggestions-header">City Suggestions</li>
                                                        {filteredCitySuggestions.map((suggestion, index) => (
                                                            <li
                                                                key={index}
                                                                className="suggestion-item"
                                                                onClick={() => selectCitySuggestion(suggestion, field)}
                                                            >
                                                                {suggestion}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    />
                                    {errors.city && <div className="error-message">{errors.city.message}</div>}
                                </div>


                                <div className="form-section">
                                    <label>Area</label>
                                    <Controller
                                        name="area"
                                        control={control}
                                        render={({ field }) => <input type="text" {...field} />}
                                    />
                                    {errors.area && <div className="error-message">{errors.area.message}</div>}
                                </div>

                                <div className="form-section">
                                    <label>Pin Code</label>
                                    <Controller
                                        name="pinCode"
                                        control={control}
                                        render={({ field }) => <input type="text" {...field} />}
                                    />
                                    {errors.pinCode && <div className="error-message">{errors.pinCode.message}</div>}
                                </div>

                                <div className="form-section">
                                    <label>Street Address</label>
                                    <Controller
                                        name="street"
                                        control={control}
                                        render={({ field }) => <input type="text" {...field} />}
                                    />
                                    {errors.street && <div className="error-message">{errors.street.message}</div>}
                                </div>
                            </>
                        )}

                        {/* Fields for Remote Jobs */}

                        {watchJobType === 'remote' && (
                            <>
                                {/* Radio Buttons for Specific City */}

                                {/* <div className="form-section">
                                    <label>Is there a specific city for the remote job?</label>
                                    <Controller
                                        name="isSpecificCityRequired"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="radio-group">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="yes"
                                                        checked={field.value === 'yes'}
                                                        onChange={() => field.onChange('yes')}
                                                    /> Yes
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="no"
                                                        checked={field.value === 'no'}
                                                        onChange={() => field.onChange('no')}
                                                    /> No
                                                </label>
                                            </div>
                                        )}
                                    />
                                </div>

                                {watchIsSpecificCityRequired === 'yes' && (
                                    <div className="form-section">
                                        <label>Specific City Field</label>
                                        <Controller
                                            name="specificCityField"
                                            control={control}
                                            render={({ field }) => <input type="text" {...field} />}
                                        />
                                        {errors.specificCityField && <div className="error-message">{errors.specificCityField.message}</div>}
                                    </div>
                                )} */}

                                <div className="form-section">
                                    <label>City (Optional)</label>
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="autocomplete-wrapper">
                                                <input
                                                    type="text"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        handleCityChange(e);
                                                    }}
                                                />
                                                {showCitySuggestions && filteredCitySuggestions.length > 0 && (
                                                    <ul className="suggestions-list">
                                                        <li className="suggestions-header">City Suggestions</li>
                                                        {filteredCitySuggestions.map((suggestion, index) => (
                                                            <li
                                                                key={index}
                                                                className="suggestion-item"
                                                                onClick={() => selectCitySuggestion(suggestion, field)}
                                                            >
                                                                {suggestion}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    />
                                    {errors.city && <div className="error-message">{errors.city.message}</div>}
                                </div>
                            </>
                        )}
                        <button className="submit-btn" type="submit">Continue</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default JobBasics;
