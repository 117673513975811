import CryptoJS from "crypto-js";

// Encryption function
export const encryptParam = (data, secretKey) => {
  const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString();
  return encodeURIComponent(encrypted); // Makes it URL-safe
};

// Decryption function
export const decryptParam = (encryptedData, secretKey) => {
  try {
    const decodedData = decodeURIComponent(encryptedData);
    const bytes = CryptoJS.AES.decrypt(decodedData, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};