import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { POST_LOGIN } from "../../../helpers/urlHelper";
import { setup2FA } from "../../../helpers/backendHelper";
import PinVerify from "../../../components/common/PinVerify";
import { Button } from "react-bootstrap";


const TwoFactorAuth = ({ handleClose,emailOrMobileNumber,onComplete }) => {

    const [QRCode, setQRCode] = useState("")

    useEffect(() => {
        setup2FA().then((s) => {
            setQRCode(s);
        })
    }, [])
    return (
        <>
            <div className="mt-2">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="card-title mb-0">Two Factor Setup</h5>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className="vh-100 d-flex justify-content-center">
                                    <div className="form-access my-auto">
                                        <form>
                                            <h3 className="text-center mb-4">2FA Setup</h3>
                                            <p className="text-center mb-4">
                                                Scan the QR Code from your authenticator App
                                            </p>

                                            <div className="text-center mb-4">
                                                <img src={QRCode} alt="QR Code" />
                                            </div>
                                            <PinVerify
                                                emailorMobileNumber={emailOrMobileNumber}
                                                type="twoFa"
                                                userType="Employer"
                                                 onComplete={onComplete}
                                            />

                                        </form>
                                    </div>
                                </div>
                                <Button className="edit-btn" onClick={handleClose}>Close</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>)
}
export default TwoFactorAuth;