module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    //  API_URL:"http://api.walkupp.com:3030/jobportal",
  // API_URL_IP: "http://localhost:3030/jobportal",
    API_URL_IP:"http://103.235.105.108:3030/jobportal"
  },
};
