import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import AccessibilityPageFooter from "../../components/footerAccessibilityPageFooter";
import "../../styles/ThemeColors.css";

function AccountSettings() {
  return (
    <div className="mt-4">
      <h2>Email Settings</h2>
      <Container className="mt-4 justify-content-center">
        <Row>
          <Col
            xs={6} md={4}
            className="border box-shadow border-0 border-top border-bottom p-3"
          >
            <label>Account Type: </label>
            <p>Employer</p>
          </Col>
          <Col
            xs={6} md={4}
            className="border box-shadow border-0 border-top border-bottom p-3"
          >
            <Button className="btn-colors">Change Account Type</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={4} className="border box-shadow border-0 border-top p-3">
            <label>Email: </label>
            <p>test@gmail.com</p>
          </Col>
          <Col xs={6} md={4} className="border box-shadow border-0 border-top  p-3">
            <Button className="btn-colors">Change Email</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={4} className="border box-shadow border-0  border-bottom p-3">
            <label>Password: </label>
            <p>.......</p>
          </Col>
          <Col xs={6} md={4} className="border box-shadow border-0  border-bottom p-3">
            <Button className="btn-colors">Change Password</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={4} className="border box-shadow border-0 border-top p-3">
            <label>Phone Number: </label>
            <p>+91 8045676870</p>
          </Col>
          <Col xs={6} md={4} className="border box-shadow border-0 border-top  p-3">
            <Button className="btn-colors">Change Phone Number</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={4} className="border box-shadow border-0  border-bottom p-3">
            <label>Two-factore authentication(2FA): </label>
            <p>Active</p>
          </Col>
          <Col
            xs={6} md={4}
            className="border box-shadow border-0  border-bottom p-3"
          ></Col>
        </Row>
        <Row>
          <Col
            xs={6} md={4}
            className="border box-shadow border-0 border-top border-bottom p-3"
          >
            <label>Third-party Aplications </label>
            <p>No Third-party application have to access your account</p>
          </Col>
          <Col
            xs={6} md={4}
            className="border box-shadow border-0 border-top border-bottom p-3"
          ></Col>
        </Row>
        <Row>
          <Col
            xs={6} md={4}
            className="border box-shadow border-0 border-top border-bottom p-3"
          >
            <p>test@gmail.com</p>
          </Col>
          <Col
            xs={6} md={4}
            className="border box-shadow border-0 border-top border-bottom p-3"
          >
            <Button className="btn-colors">sign out</Button>
          </Col>
        </Row>
      </Container>
      <div>
        <AccessibilityPageFooter />
      </div>
    </div>
  );
}

export default AccountSettings;
