


import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Nav,
    Row,
    Tab,
    Image
} from "react-bootstrap";
import Swal from 'sweetalert2';
import CommonTable from "../../../components/common/CommonTable";
import { getInterviewScheduleByStatus, getJobApplicationByEmployerId, getJobApplicationDetailsByJobId, updateApplicationByEmployer, updateApplicationByStatusByEmployer, updateInterviewStatus } from "../../../helpers/backendHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
import EmployerSidebar from "../../../components/EmployerSidebar";
import { useSelector } from "react-redux";
import { interviewConfirmNowLaterSwalHelper, interviewConfrimSwalHelper, interviewHireRejectSwalHelper, interviewSwalSuccessHelper } from "../../../helpers/interviewHelper";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";
import InterviewCard from "../../../helpers/Employers/Interviews/InterViewCard";
import Nodata from "../../../assets/images/no-data.webp";

const InterviewPage = () => {
    const { user } = useSelector(state => ({
        user: state.Login.user,
    }));

    const navigate = useNavigate();
    const [applicationStatus, setApplicationStatus] = useState('upcoming');
    const [sidebarExpanded, setSidebarExpanded] = useState(false);
    const [applicationDetails, setApplicationDetails] = useState([]);
    const [activePage,setActivePage] = useState(1);
    const [totalpage,setTotalPage] = useState();
    const [applicationDetailsPayload,setApplicationDetailsPayload] = useState({});    

    const fetchApplicationDetails = async (data) => {
        getInterviewScheduleByStatus(data)
            .then(data => {
                console.log("data interviews",data);
                
                if (data && data.statusCode === 200 && data.interviews.length > 0) {
                    setTotalPage(data.totalPages);
                    const appliedJobData = data.interviews.map(applicationData => {
                        return {
                            id: applicationData.interview._id,
                            interviewType: applicationData.interview.interviewType,
                            interviewDateTime: applicationData.interview.interview_date_time,
                            interviewDuration: applicationData.interview.interview_duration,
                            jobStatus: applicationData.interview.status,
                            jobTitle: applicationData.jobs.jobBasics.jobTitle,
                            candidateName: applicationData.jobSeeker.firstName,
                            email: applicationData.jobSeeker.email,
                            mobileNumber: applicationData.jobSeeker.mobileNumber,
                            profileImage: applicationData.jobSeeker.jobSeekerProfileImage,
                            resumePath: applicationData.applications.resumePath,
                            applicationId: applicationData.interview.applicationId,
                            applicationStatus: applicationData.applications.applicationStatus
                        };
                    });          
                    setApplicationDetails(appliedJobData);
                    console.log("appliedJobData",appliedJobData);
                    
                } else {
                    setApplicationDetails([]);
                }
            })
            .catch(error => {
                console.error("Error fetching interview schedule:", error);
            });
    };


    useEffect(() => {
        const applicationInputData = {
            status: applicationStatus,
            page: activePage
        };
        setApplicationDetailsPayload(applicationInputData);
        console.log("applicationInputData",applicationInputData);
        
        
        fetchApplicationDetails(applicationInputData);
    }, [applicationStatus,activePage]);

    const TabColumn = ['Name', 'Email', 'JobTitle', 'JobStatus', 'MobileNumber', 'Resume', 'Action'];
    const upcomingInterviewOption = ['Hired', 'Reject'];
    const completedInterviewOption = ['Hired', 'Reject'];

    const OnStatusChange = async (status, row, hiredStatus) => {
        console.log('hiredStatus', status, row, hiredStatus);
        let statusMsg = "";
        let data = {};
        let row_id = row.applicationId;

        if (status === "completed") {
            statusMsg = "completed";
            data = {
                inputData: {
                    interviewId: row.id,
                    appliedStatus: 'interview',
                    status: 'completed',
                }
            };
        } else if (status === "Reject") {
            statusMsg = "rejected";
            data = {
                inputData: {
                    interviewId: row.id,
                    appliedStatus: 'rejected',
                    status: 'rejected',
                },
                id: row.id,
            };
        }
        else{
            const confirmedResult = await interviewHireRejectSwalHelper();
            if(confirmedResult.isConfirmed){
                const payload = { id: row_id, payload: {status: 'hired'}};
                console.log("payload",payload);
                const response = await updateApplicationByStatusByEmployer(payload);
                if (response.statusCode === 200) {
                    interviewSwalSuccessHelper("hired");
                }
                fetchApplicationDetails(applicationDetailsPayload);
            }
            else{
                const payload = { id: row_id, payload: {status: 'rejected'}};
                console.log("rejected_payload",payload);
                const response = await updateApplicationByStatusByEmployer(payload);

                if (response.statusCode === 200) {
                    interviewSwalSuccessHelper("rejected");
                }
                fetchApplicationDetails(applicationDetailsPayload);
            }
        }

        // const confirmed = await interviewConfrimSwalHelper(statusMsg);
        // if (confirmed.isConfirmed) {
        //     const applicationUpdate = await updateInterviewStatus(data);
        //     const response = await updateApplicationByStatusByEmployer(applicationStatus)

        //     if (applicationUpdate.statusCode === 200) {
        //         interviewSwalSuccessHelper(statusMsg);
        //     }
        //     fetchApplicationDetails();
        // }

        if(statusMsg === 'completed'){
            const confirmed = await interviewConfirmNowLaterSwalHelper();
            if (confirmed.isConfirmed) {
                const confirmedResult = await interviewHireRejectSwalHelper();
                if(confirmedResult.isConfirmed){
                    const payload = { id: row_id, payload: {status: 'hired'}};
                    console.log("payload",payload);
                    const applicationUpdate = await updateInterviewStatus(data);
                    const response = await updateApplicationByStatusByEmployer(payload);

                    if (applicationUpdate.statusCode === 200 && response.statusCode === 200) {
                        interviewSwalSuccessHelper(statusMsg);
                    }
                    fetchApplicationDetails(applicationDetailsPayload);
                }
                else{
                    const payload = { id: row_id, payload: {status: 'rejected'}};
                    console.log("rejected_payload",payload);
                    const applicationUpdate = await updateInterviewStatus(data);
                    const response = await updateApplicationByStatusByEmployer(payload);

                    if (applicationUpdate.statusCode === 200 && response.statusCode === 200) {
                        interviewSwalSuccessHelper(statusMsg);
                    }
                    fetchApplicationDetails(applicationDetailsPayload);
                }
            }
            else{
                const applicationUpdate = await updateInterviewStatus(data);
                if (applicationUpdate.statusCode === 200) {
                    interviewSwalSuccessHelper(statusMsg);
                }
                fetchApplicationDetails(applicationDetailsPayload);
            }
        }
        else if(statusMsg === 'rejected'){
            const confirmed = await interviewConfrimSwalHelper(statusMsg);
            if (confirmed.isConfirmed) {
                const payload = { id: row_id, payload: {status: 'rejected'}};
                console.log("rejected_payload",payload);
                const applicationUpdate = await updateInterviewStatus(data);
                const response = await updateApplicationByStatusByEmployer(payload);

                if (applicationUpdate.statusCode === 200 && response.statusCode === 200) {
                    interviewSwalSuccessHelper(statusMsg);
                }
                fetchApplicationDetails(applicationDetailsPayload);
            }
        }
        
    };

    // const OnStatusChange2 = async (row, hiredStatus) => {
    //     console.log('hiredStatus', row, hiredStatus);
    //     let rowId = row.applicationId;

    //     const confirmedResult = await interviewHireRejectSwalHelper();
    //     if(confirmedResult.isConfirmed){
    //         const payload = { id: rowId, payload: {status: 'hired'}};
    //         console.log("payload",payload);
    //         const response = await updateApplicationByStatusByEmployer(payload);
    //         if (response.statusCode === 200) {
    //             interviewSwalSuccessHelper("hired");
    //         }
    //         fetchApplicationDetails(applicationDetailsPayload);
    //     }
    //     else{
    //         const payload = { id: rowId, payload: {status: 'rejected'}};
    //         console.log("rejected_payload",payload);
    //         const response = await updateApplicationByStatusByEmployer(payload);

    //         if (response.statusCode === 200) {
    //             interviewSwalSuccessHelper("rejected");
    //         }
    //         fetchApplicationDetails(applicationDetailsPayload);
    //     }
        
    // };

    const handleNavClick = (status) => {
        setApplicationStatus(status);
    };

    const closeSidebar = (event) => {
        if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
            setSidebarExpanded(false);
            const wrapper = document.getElementById("wrapper");
            wrapper.classList.remove("toggled");
        }
    };

    return (
        <>
            <div id="wrapper" onClick={closeSidebar}>
                <EmployerSidebar sidebarExpanded={sidebarExpanded} />
                <div id="navbar-wrapper">
                    <EmployerNavBar />
                </div>

                <section id="content-wrapper">
                    <Container>
                        <Row className="justify-content-between">
                            <Col sm={3}>
                                <h3>InterviewDetails</h3>
                            </Col>
                            <Col sm={6} className="text-end mt-1 mt-sm-0">
                                <Link to="/empformone">
                                    <Button className="btn-color-primary">Post Jobs</Button>
                                </Link>
                            </Col>
                        </Row>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="upcoming">
                            <Row className="mt-3">
                                <Col md={12} sm={6}>
                                    <Nav variant="pills" className="flex-row">
                                        <Nav.Item>
                                            <Nav.Link eventKey="upcoming" onClick={() => handleNavClick('upcoming')}>
                                                Upcoming
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="completed" onClick={() => handleNavClick('completed')}>
                                                Completed
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>

                            <Row className="mt-3 p-1 p-sm-2 bg-white rounded">
                                <Col sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="upcoming">
                                            {applicationDetails.length > 0 ? (
                                                <InterviewCard
                                                    columns={TabColumn}
                                                    data={applicationDetails}
                                                    statusOption={upcomingInterviewOption}
                                                    OnStatusChange={OnStatusChange}
                                                    tab={'upcoming'}
                                                    type={'interview'}
                                                    totalPage={totalpage}
                                                    activePage={activePage}
                                                    setActive={setActivePage}
                                                />
                                            ) : (
                                                <Image className="nodata-img" src={Nodata} />
                                            )}
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="completed">
                                            {applicationDetails.length > 0 ? (
                                                <InterviewCard
                                                    columns={TabColumn}
                                                    data={applicationDetails}
                                                    statusOption={completedInterviewOption}
                                                    OnStatusChange={OnStatusChange}
                                                    tab={'completed'}
                                                    type={'interview'}
                                                    totalPage={totalpage}
                                                    activePage={activePage}
                                                    setActive={setActivePage}
                                                />
                                            ) : (
                                                <Image className="nodata-img" src={Nodata} />
                                            )}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default InterviewPage;

