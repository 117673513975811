import { Field, Formik, Form as FormikForm } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { Badge, Button, Card, Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Pagination from 'react-bootstrap/Pagination';
import { BsBookmarkDash, BsBookmarkDashFill } from "react-icons/bs";
import { CiFilter, CiGrid41 } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';
import "../assets/css/ViewJobs.css";
import Navbar from "../components/Navbar";
import { deleteSavedJobs, getAllJobs, getAllSavedJobs, getCityState, getJobSuggestions, savedJobs, searchAndFilter } from '../helpers/backendHelper';
import ViewJobsFilters from './ViewJobsFilters';
import { decryptParam, encryptParam } from '../helpers/UrlEncryptDecrypt';
import { useSelector } from 'react-redux';
import Nodata from "../assets/images/no-data.webp";

const ViewJobsNew = () => {

    const { user } = useSelector((state) => ({
        user: state.Login.user,
    }));

    const isFirstRender = useRef(true);

    document.title = "Jobs";

    let { jobTitle, joblocation, isSearchFromHome } = useParams();
    const secretKey = process.env.REACT_APP_WALKUPP_SECRET_KEY || "walkupp-secret-key";

    jobTitle = decryptParam(jobTitle,secretKey);
    joblocation = decryptParam(joblocation,secretKey);
    // console.log("params of jobsTitle, jobslocation, isSearchFromHome", jobTitle, joblocation, isSearchFromHome);

    const [isSearchedHome, setIsSearchedHome] = useState(isSearchFromHome);

    const [defaultCheck, setDefaultCheck] = useState('');
    const [isToggled, setIsToggled] = useState(false);
    const [isToggledMark, setIsToggledMark] = useState([]);
    const [show, setShow] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [filterJobs,setFilterJobs] = useState([]);
    const [jobSuggestions, setJobSuggestions] = useState([]);
    const [allCityState, setAllCityState] = useState([]);
    const [searchFilters, setSearchFilters] = useState({});
    const [mainFilters,setMainFilters] = useState({});
    const [mainFilterData, setMainFilterData] = useState({});
    const [fieldError, setFieldError] = useState("");
    const [activePageJobs, setActivePageJobs] = useState(1);
    const [activePageFilters, setActivePageFilters] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [totalCount, setTotalCount] = useState();
    const [allSavedJobs,setAllSavedJobs] = useState([]);
    const [bookMarkClicked,setBookMarkClicked] = useState(false);


    // const [jobTitle, setJobTitle] = useState(null);
    const [menuIsOpenTitle, setMenuIsOpenTitle] = useState(false);
    const [searchInputTitle, setSearchInputTitle] = useState("");
    // const [joblocation, setJobLocation] = useState(null);
    const [menuIsOpenLocation, setMenuIsOpenLocation] = useState(false);
    const [searchInputLocation, setSearchInputLocation] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDefaultChange = (event) => {
        setDefaultCheck(event.target.value);
    };

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    const fetchSearchFromHomeData = async () => {
        let payload = {}
        
        if(jobTitle === null || jobTitle === "null" ){
            payload = {
                location: joblocation,
                status: "opened",
                page: activePageFilters
            }
            const payload2 = {
                location: joblocation
            }

            setSearchFilters(payload2);
            fetchSearchFilter(payload);
        }
        else if(joblocation === null || joblocation === "null" ){
            payload = {
                jobTitle: jobTitle,
                status: "opened",
                page: activePageFilters
            }
            const payload2 = {
                jobTitle: jobTitle
            }

            setSearchFilters(payload2);
            fetchSearchFilter(payload);
        }
        else {
            payload = {
                jobTitle: jobTitle,
                location: joblocation,
                status: "opened",
                page: activePageFilters
            }
            const payload2 = {
                jobTitle: jobTitle,
                location: joblocation
            }
            setSearchFilters(payload2);
            fetchSearchFilter(payload);
        }
        
    }

    useEffect(() => {
        const isSearchHome = isSearchFromHome === 'true' || isSearchFromHome === true;        
        if (isSearchHome) {            
            fetchSearchFromHomeData();
            setIsSearchedHome(false);
        }

    }, [isSearchedHome]);


    const fetchjobs = async (data) => {    
        console.log("data of payload all jobs",data);  
        try {
            const isSearchHome = isSearchFromHome === 'true' || isSearchFromHome === true;        
            if(!isSearchHome){
                setFilterJobs([]);
                const response = await getAllJobs(data);
                console.log("jobs response",response);
                if (response && response.statusCode === 200) {
                    console.log("response of all jobs",response.jobs);
                    setJobs(response.jobs);
                    setTotalPages(response.totalPages);
                    setFilterJobs([]);
                }
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const fetchSearchFilter = async (data) => {
        try {            
            const updatedFilters = {...mainFilters,...data};
            console.log("data payload of filters in mainFilters",updatedFilters);
            setMainFilters(updatedFilters);

            const storeSearchFilter = await searchAndFilter(updatedFilters);
            if (storeSearchFilter.statusCode === 200) {
                console.log("storeSearchFilter", storeSearchFilter);
                setFilterJobs(storeSearchFilter.jobs);
                setTotalPages(storeSearchFilter.totalPages);
                setTotalCount(storeSearchFilter.totalCount);
                setJobs([]);
                if(storeSearchFilter.page === 1){
                    setActivePageFilters(1);
                }
            }
        } catch (error) {
            console.log("error of fetchSearchFilter", error);
        }
        
        // if (data !== undefined) {
        //     try {            
        //         const updatedFilters = {...mainFilters,...data};
        //         console.log("data payload of filters in mainFilters",updatedFilters);
        //         setMainFilters(updatedFilters);
    
        //         const storeSearchFilter = await searchAndFilter(updatedFilters);
        //         if (storeSearchFilter.statusCode === 200) {
        //             console.log("storeSearchFilter", storeSearchFilter);
        //             setFilterJobs(storeSearchFilter.jobs);
        //             setTotalPages(storeSearchFilter.totalPages);
        //             setTotalCount(storeSearchFilter.totalCount);
        //             setJobs([]);
        //             if(storeSearchFilter.page === 1){
        //                 setActivePageFilters(1);
        //             }
        //         }
        //     } catch (error) {
        //         console.log("error of fetchSearchFilter", error);
        //     }
        // }
        
    }

    const validationschema1 = Yup.object({
        jobTitle: Yup.string(), 
        location: Yup.string()
    });

    const handleSubmit = (values, { setSubmitting }) => {

        if (values.jobTitle === "" && values.location === "") {
            setFieldError("Either Job Title or Location must be provided.");
            setSubmitting(false);
        }
        else {
            setSubmitting(false);
            const payload = {
                jobTitle: values.jobTitle,
                location: values.location,
                status: "opened",
                page: activePageFilters
            };

            let searchPayload = {};
            searchPayload["jobTitle"]=values.jobTitle;
            searchPayload["location"]=values.location;

            setSearchFilters(searchPayload);
            fetchSearchFilter(payload);
        }
    };

    useEffect(() => {
        if (fieldError !== null) {
            const timerError = setTimeout(() => {
                setFieldError("");
            }, 7000);
            return () => clearTimeout(timerError);
        }
    }, [fieldError]);
    

    const Savejobs = async (jobId) => {
        const payload = {
            jobId : jobId
        }
        try {
            await savedJobs(payload);
            console.log(`job ${jobId} saved successfully`);
        }   
        catch (error) {
            console.log(`failed to save job ${jobId}`, error);
        }
    }

    const savedJobsDelete = async (jobId) => {
        const payload = {
            jobId : jobId
        }
        try {
            await deleteSavedJobs(payload);
            console.log(`job ${jobId} deleted successfully`);
        }
        catch (error) {
            console.log(`failed to delete job ${jobId}`, error);
        }
    }

    const getSavedJobs = async () => {
        try {
            const response = await getAllSavedJobs();
            console.log("response all saved jobs",response);
            setAllSavedJobs(response.jobs);
        } catch (error) {
            console.log("error.....",error,allSavedJobs);
        }
    }

    useEffect(()=>{
        if(user && Object.keys(user).length !== 0){
            getSavedJobs();
        }
    },[bookMarkClicked]);

    const handleFilterData = (data) => {
        setMainFilterData(data);
    }


    //  save & unsave bookmark

    const handleToggleMark = (jobId) => {
        setBookMarkClicked(!bookMarkClicked);        
        const isMarked = (allSavedJobs || []).some((jobs)=> jobs.job._id === jobId);
        console.log("isMarked",isMarked);
        
        try {
            if(isMarked){
                savedJobsDelete(jobId);
            }
            else{
                Savejobs(jobId);
            }
        } catch (error) {
            console.log("error updating saved jobs",error);
        }
    };

    const fetchCityState = async () => {
        try {
            const response = await getCityState();
            if (response && response.statusCode === 200) {
                const data = response.cities;
                setAllCityState(data);
            }
        } catch (error) {
            console.error("Error fetching city/state data:", error);
        }
    };

    const fetchJobSuggestion = async () => {
        try {
            const response = await getJobSuggestions();
            if (response && response.statusCode === 200) {
                const data = response.jobTitle.map(job => `${job.name}`);
                setJobSuggestions(data);
            }
        } catch (error) {
            console.error("Error fetching job data:", error);
        }
    }

    // const handleSelectChange = (selectedOption) => {
    //     setJobTitle(selectedOption ? selectedOption.value : "");
    //     setMenuIsOpenTitle(false);
    // };

    // const handleInputChange = (inputValue) => {
    //     setSearchInputTitle(inputValue);
    //     setMenuIsOpenTitle(inputValue.length > 2);
    // };

    const optionsTitle = jobSuggestions.map((job) => ({
        value: job?.toLowerCase(),
        label: job,
    }));

    const CustomSelectTitle = ({ field, form, options, placeholder }) => {
        return (
            <Select
                className='w-100'
                name={field.name}
                value={options.find(option => option.value === field.value)}
                onChange={(option) => form.setFieldValue(field.name, option ? option.value : '', setFieldError(""))}
                options={options}
                isSearchable
                isClearable
                placeholder={placeholder}
                components={{ DropdownIndicator: null }}
            />
        );
    };

    // const handleSelectChangeLocation = (selectedOption) => {
    //     setJobLocation(selectedOption ? selectedOption.value : "");
    //     setMenuIsOpenLocation(false);
    // };

    // const handleInputChangeLocation = (inputValue) => {
    //     setSearchInputLocation(inputValue);
    //     setMenuIsOpenLocation(inputValue.length > 2);
    // };

    const optionsLocation = allCityState.map((location) => ({
        value: location ? location?.city?.toLowerCase() : {},
        label: `${location.city}, ${location.state}`
    }));

    const CustomSelectLocation = ({ field, form, options, placeholder, handleInputChange }) => {
        return (
            <Select
                className='w-100'
                name={field.name}
                value={options.find(option => option.value === field.value)}
                onChange={(option) => form.setFieldValue(field.name, option ? option.value : '', setFieldError(""))}
                options={options}
                isSearchable
                isClearable
                placeholder={placeholder}
                onInputChange={handleInputChange}
                components={{ DropdownIndicator: null }}
            />
        );
    };

    useEffect(() => {
        fetchCityState();
        fetchJobSuggestion();
    }, []);


    // pagination all jobs
    
    useEffect(() => {
        if(filterJobs.length === 0){
            fetchjobs(activePageJobs);
        }
    }, [activePageJobs]);

    // Handle page change
    const handlePageChangeJobs = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setActivePageJobs(pageNumber);
        }
    }

    // Render pagination numbers dynamically
    const renderPageNumbersJobs = () => {
        const pages = [];

        // Display pagination for less than 5 pages without ellipsis
        
        if (totalPages <= 5) {
            for (let number = 1; number <= totalPages; number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === activePageJobs} onClick={() => handlePageChangeJobs(number)}>
                        {number}
                    </Pagination.Item>
                );
            }
        }

        else {
            // Display ellipsis and limited page numbers if more than 5 pages
            if (activePageJobs > 2) {
                pages.push(<Pagination.Item key={1} onClick={() => handlePageChangeJobs(1)}>{1}</Pagination.Item>);
                if (activePageJobs > 3) pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }

            for (let number = Math.max(1, activePageJobs - 1); number <= Math.min(totalPages, activePageJobs + 1); number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === activePageJobs} onClick={() => handlePageChangeJobs(number)}>
                        {number}
                    </Pagination.Item>
                );
            }

            if (activePageJobs < totalPages - 2) {
                pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
                pages.push(
                    <Pagination.Item key={totalPages} onClick={() => handlePageChangeJobs(totalPages)}>
                        {totalPages}
                    </Pagination.Item>
                );
            }
        }

        return pages;
    };


    // pagination filters jobs

    useEffect(() => {
        if(isFirstRender.current){
            isFirstRender.current = false;
            return;
        }

        if(jobs.length === 0) {
            const payload = {
                page: activePageFilters,
                status: "opened",
                ...searchFilters
            }
            try {
                fetchSearchFilter(payload);
            } catch (error) {
                console.error("Error fetching search filters", error);
            }            
        }
    }, [activePageFilters,searchFilters]);

    // Handle page change

    const handlePageChangeFilters = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setActivePageFilters(pageNumber);
        }
    };

    // Render pagination numbers dynamically
    const renderPageNumbersFilters = () => {
        const pages = [];

        // Display pagination for less than 5 pages without ellipsis
        if (totalPages <= 5) {
            for (let number = 1; number <= totalPages; number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === activePageFilters} onClick={() => handlePageChangeFilters(number)}>
                        {number}
                    </Pagination.Item>
                );
            }
        }

        else {
            // Display ellipsis and limited page numbers if more than 5 pages
            if (activePageFilters > 2) {
                pages.push(<Pagination.Item key={1} onClick={() => handlePageChangeFilters(1)}>{1}</Pagination.Item>);
                if (activePageFilters > 3) pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }

            for (let number = Math.max(1, activePageFilters - 1); number <= Math.min(totalPages, activePageFilters + 1); number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === activePageFilters} onClick={() => handlePageChangeFilters(number)}>
                        {number}
                    </Pagination.Item>
                );
            }

            if (activePageFilters < totalPages - 2) {
                pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
                pages.push(
                    <Pagination.Item key={totalPages} onClick={() => handlePageChangeFilters(totalPages)}>
                        {totalPages}
                    </Pagination.Item>
                );
            }
        }

        return pages;
    };


    return (
        <Container fluid className="p-0 bg-white viewjobs">
            <Navbar />

            <div className="job-listing p-1 pt-5 p-sm-5 ">
                <h2 className="fs-1 fw-bolder text-center">Job Listing</h2>
                <p className="mt-4 fs-5 text-center">We delivered blazing fast & striking work solution</p>

                <Container className='mt-4'>
                    <Row className="justify-content-center my-4 my-sm-0">
                        <Col xs={11} sm={10} md={12} lg={9} xl={10}>
                            <Card style={{ maxWidth: "1400px", borderRadius: '10px', backgroundColor: '#fff', borderColor: "" }}>
                                <Formik
                                    initialValues={{
                                        jobTitle: "",
                                        location: "",
                                    }}
                                    validationSchema={validationschema1}
                                    onSubmit={(values, actions) => handleSubmit(values, actions)}
                                >
                                    {({ isSubmitting }) => (
                                        <FormikForm>
                                            <InputGroup className="ps-md-4 d-flex flex-column flex-md-row align-items-stretch">
                                                <div className="w-100 d-flex flex-column justify-content-between align-items-start me-md-3 py-2 px-3 px-md-0" style={{ flex: '1' }}>
                                                    <Form.Label className='category-title'>What are you looking for?</Form.Label>

                                                    <Field
                                                        name="jobTitle" // The name attribute for the field
                                                        component={CustomSelectTitle}
                                                        options={optionsTitle}
                                                        placeholder="Search jobTitle"
                                                    // handleInputChange={handleInputChange}
                                                    />
                                                </div>

                                                <div className="w-100 d-flex flex-column justify-content-between align-items-start border-md-start mt-3 mt-md-0 py-0 px-3 pb-3 py-md-2" style={{ flex: '1', paddingLeft: '10px', borderLeft: '1px solid #e8e8e8' }}>
                                                    <Form.Label className='ms-1 text-start category-title'>Location</Form.Label>

                                                    <Field
                                                        name="location" // The name attribute for the field
                                                        component={CustomSelectLocation}
                                                        options={optionsLocation}
                                                        placeholder="Select location"
                                                    />
                                                </div>

                                                <Button
                                                    className='joblist-btn'
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    SEARCH
                                                </Button>
                                            </InputGroup>
                                        </FormikForm>
                                    )}
                                </Formik>
                            </Card>

                            {fieldError === "" ? "" : <p className='global-error text-center'>Either Job Title or Location must be provided.</p>}

                        </Col>
                    </Row>
                </Container>
            </div>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    <p className='fs-3 text-center filter-text'>Filter By</p>
                    <ViewJobsFilters sentDataToParent={handleFilterData} searchApi={fetchSearchFilter} />
                </Offcanvas.Body>
            </Offcanvas>

            <Container className='mt-5'>
                <Row>
                    <Col lg={4} xl={3} className='d-none d-lg-block'>
                        <p className='fs-3'>Filter By</p>
                        <ViewJobsFilters sentDataToParent={handleFilterData} searchApi={fetchSearchFilter} />
                    </Col>

                    <Col className='d-block d-lg-none'>
                        <Button className='w-100 p-1 filter-btn bg-transparent text-dark my-4' onClick={handleShow}><p className='d-flex justify-content-center align-items-center mt-1' style={{ fontWeight: "500", fontSize: "19px" }}><CiFilter className='me-2 fs-5 fw-bolder' />Filter</p></Button>
                    </Col>

                    <Col lg={8} xl={9}>
                        <div className='d-flex justify-content-between mb-3'>
                        {filterJobs.length === 0 ? <p></p> : <p className='jobsfound-text text-secondary mt-2 me-2'> <span className='text-dark jobsfound-text me-2 '>{ totalCount }</span>jobs found</p> }
                            <div className='d-flex align-items-center'>
                                <div className='d-flex'>
                                    <h5 className='me-2 mt-2 jobsfound-text'>Sort: </h5>
                                    <InputGroup className='px-3 me-2 bg-muted border border-muted rounded-pill'>
                                        <Form.Control
                                            className='forms-control'
                                            as="select"
                                            size="sm"
                                            value={defaultCheck}
                                            onChange={handleDefaultChange}
                                            style={{ width: "90px" }}
                                        >
                                            <option value="Default">Default</option>
                                            <option value="Latest">Latest</option>
                                            <option value="Oldest">Oldest</option>
                                        </Form.Control>
                                        <InputGroup.Text style={{ fontSize: '15px', padding: '0px', border: 'none', boxShadow: 'none', backgroundColor: 'white', cursor: 'pointer' }}>
                                            <IoIosArrowDown />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </div>
                                <div className='grid-toggle' onClick={handleToggle}>
                                    {isToggled ? <RxHamburgerMenu className='grid-icons' /> : <CiGrid41 className='grid-icons' />}
                                </div>
                            </div>
                        </div>

                        { jobs.length !== 0 ?
                            <div>
                            {
                                isToggled ?
                                    <div>
                                        <Row>
                                            {jobs.map((grid) =>
                                                <Col sm={6} key={grid._id}>
                                                    <Card className="job-card2 mb-3">
                                                        <Card.Body>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="job-card-star">
                                                                    <FaStar className="star-icon" />
                                                                </div>
                                                                <div className="job-card-avatar2 text-center">
                                                                    <Link className="avatar-icon" to='/'>A</Link>
                                                                </div>
                                                                { user && Object.keys(user).length !== 0 ?
                                                                    <div className='grid-toggle2' onClick={() => handleToggleMark(grid._id)}>
                                                                        {allSavedJobs && allSavedJobs.some((jobs)=> jobs.job._id === grid._id) ? <BsBookmarkDashFill className='m-auto' /> : <BsBookmarkDash className='m-auto' />}
                                                                    </div> 
                                                                    : null
                                                                }
                                                            </div>

                                                            <div className="mt-3">
                                                                <Badge bg="light" className="full-time-badge mt-4 mb-1">{grid.jobDetails.jobType}</Badge>
                                                                <h4 className="mt-2 mb-2 text-fulltime2 job-title2 cursor-pointer">{grid.jobBasics.jobTitle}</h4>
                                                                <p className="salary-range2 mt-1 mb-3 mt-xl-3 mb-md-5">
                                                                    {grid.salary?.range?.minimum}-{grid.salary?.range?.maximum} <span>/ yearly</span>
                                                                </p>
                                                            </div>

                                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                                                <p className="text-muted location">{grid.jobBasics.jobLocation ? grid.jobBasics.jobLocation.city : ""}</p>
                                                                <Link to={`/job/detail/${encryptParam(grid._id,secretKey)}`} className='details-button'>DETAILS</Link>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>

                                    </div>

                                    : <div>

                                        {jobs.map((job) =>
                                            <div className="job-card1" key={job._id}>
                                                <Row className='w-100 m-0'>
                                                    <Col xs={12} md={5} lg={4} xl={5} className='d-flex align-items-center'>
                                                        <div className="job-card-star">
                                                            <FaStar className="star-icon" />
                                                        </div>

                                                        <div className="job-card-left m-sm-1 m-2">
                                                            <div className="job-card-avatar">
                                                                <Link className="avatar-circle" to='/jobs'>A</Link>
                                                            </div>
                                                            <div className="job-card-info text-start">
                                                                <Link className='text-fulltime' to='/jobs'>{job.jobDetails.jobType}</Link>
                                                                <Link className='job-title text-fulltime2' to=''>{job.jobBasics.jobTitle}</Link>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col sm={7} md={4} lg={4} xl={4} className='d-flex align-items-center'>
                                                        <div className="m-sm-1 m-2">
                                                            <p className="job-location text-muted text-start cursor-pointer">{job.jobBasics.jobLocation ? job.jobBasics.jobLocation.city : ""}</p>
                                                            <p className="job-salary text-start">{job.salary?.range?.minimum}-{job.salary?.range?.maximum}<span className='job-experience'> / yearly</span></p>
                                                        </div>
                                                    </Col>

                                                    <Col sm={5} md={3} lg={4} xl={3} className='detail-btn-content d-flex align-items-center justify-content-end'>
                                                        <div className="d-flex align-items-center m-sm-1 m-2">
                                                            { user && Object.keys(user).length !== 0 ?
                                                                <div className='grid-toggle2 me-3' onClick={() => handleToggleMark(job._id)}>
                                                                    {allSavedJobs && allSavedJobs.some((jobs)=> jobs.job._id === job._id) ? <BsBookmarkDashFill className='m-auto' /> : <BsBookmarkDash className='m-auto' />}
                                                                </div> 
                                                                : null
                                                            }
                                                            <Link to={`/job/detail/${encryptParam(job._id,secretKey)}`} className='details-button'>DETAILS</Link>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                        )}
                                    </div>
                            }

                                <Pagination className="justify-content-center mt-5 custom-pagination">
                                    <Pagination.First onClick={() => handlePageChangeJobs(1)} disabled={activePageJobs === 1} />
                                    <Pagination.Prev onClick={() => handlePageChangeJobs(activePageJobs - 1)} disabled={activePageJobs === 1} />
                                    {renderPageNumbersJobs()}
                                    <Pagination.Next onClick={() => handlePageChangeJobs(activePageJobs + 1)} disabled={activePageJobs === totalPages} />
                                    <Pagination.Last onClick={() => handlePageChangeJobs(totalPages)} disabled={activePageJobs === totalPages} />
                                </Pagination>

                            </div>

                        : filterJobs.length !== 0 ? 
                            <div>
                            {
                                isToggled ?
                                    <div>
                                        <Row>
                                            {filterJobs.map((grid) =>
                                                <Col sm={6} key={grid._id}>
                                                    <Card className="job-card2 mb-3">
                                                        <Card.Body>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="job-card-star">
                                                                    <FaStar className="star-icon" />
                                                                </div>
                                                                <div className="job-card-avatar2 text-center">
                                                                    <Link className="avatar-icon" to='/'>A</Link>
                                                                </div>
                                                                { user && Object.keys(user).length !== 0 ?
                                                                    <div className='grid-toggle2' onClick={() => handleToggleMark(grid._id)}>
                                                                        {allSavedJobs && allSavedJobs.some((jobs)=> jobs.job._id === grid._id) ? <BsBookmarkDashFill className='m-auto' /> : <BsBookmarkDash className='m-auto' />}
                                                                    </div>
                                                                    : null
                                                                }
                                                            </div>

                                                            <div className="mt-3">
                                                                <Badge bg="light" text="success" className="full-time-badge mt-4 mb-1">{grid.jobDetails.jobType}</Badge>
                                                                <h4 className="mt-2 mb-2 text-fulltime2 job-title2 cursor-pointer">{grid.jobBasics.jobTitle}</h4>
                                                                <p className="salary-range2 mt-1 mb-3 mt-xl-3 mb-md-5">
                                                                    {grid.salary?.range?.minimum}-{grid.salary?.range?.maximum} <span>/ yearly</span>
                                                                </p>
                                                            </div>

                                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                                                <p className="text-muted location">{grid.jobBasics.jobLocation ? grid.jobBasics.jobLocation.city : ""}</p>
                                                                <Link to={`/job/detail/${encryptParam(grid._id,secretKey)}`} className='details-button'>DETAILS</Link>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>

                                    </div>

                                    : <div>

                                        {filterJobs.map((job) =>
                                            <div className="job-card1" key={job._id}>
                                                <Row className='w-100 m-0'>
                                                    <Col xs={12} md={5} lg={4} xl={5} className='d-flex align-items-center'>
                                                        <div className="job-card-star">
                                                            <FaStar className="star-icon" />
                                                        </div>

                                                        <div className="job-card-left m-sm-1 m-2">
                                                            <div className="job-card-avatar">
                                                                <Link className="avatar-circle" to='/jobs'>A</Link>
                                                            </div>
                                                            <div className="job-card-info text-start">
                                                                <Link className='text-fulltime' to='/jobs'>{job.jobDetails.jobType}</Link>
                                                                <Link className='job-title text-fulltime2' to=''>{job.jobBasics.jobTitle}</Link>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col sm={7} md={4} lg={4} xl={4} className='d-flex align-items-center'>
                                                        <div className="m-sm-1 m-2">
                                                            <p className="job-location text-muted text-start cursor-pointer">{job.jobBasics.jobLocation ? job.jobBasics.jobLocation.city : ""}</p>
                                                            <p className="job-salary text-start">{job.salary?.range?.minimum}-{job.salary?.range?.maximum}<span className='job-experience'> / yearly</span></p>
                                                        </div>
                                                    </Col>

                                                    <Col sm={5} md={3} lg={4} xl={3} className='detail-btn-content d-flex align-items-center justify-content-end'>
                                                        <div className="d-flex align-items-center m-sm-1 m-2">
                                                            { user && Object.keys(user).length !== 0 ?
                                                                <div className='grid-toggle2 me-3' onClick={() => handleToggleMark(job._id)}>
                                                                    {allSavedJobs && allSavedJobs.some((jobs)=> jobs.job._id === job._id) ? <BsBookmarkDashFill className='m-auto' /> : <BsBookmarkDash className='m-auto' />}
                                                                </div> 
                                                                : null
                                                            }
                                                            <Link to={`/job/detail/${encryptParam(job._id,secretKey)}`} className='details-button'>DETAILS</Link>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                        )}
                                    </div>
                            }

                                    <Pagination className="justify-content-center mt-5 custom-pagination">
                                        <Pagination.First onClick={() => handlePageChangeFilters(1)} disabled={activePageFilters === 1} />
                                        <Pagination.Prev onClick={() => handlePageChangeFilters(activePageFilters - 1)} disabled={activePageFilters === 1} />
                                        {renderPageNumbersFilters()}
                                        <Pagination.Next onClick={() => handlePageChangeFilters(activePageFilters + 1)} disabled={activePageFilters === totalPages} />
                                        <Pagination.Last onClick={() => handlePageChangeFilters(totalPages)} disabled={activePageFilters === totalPages} />
                                    </Pagination>

                            </div>

                        : 
                        // <p className='text-center mt-5'>No Jobs Found</p>
                        <Image className='nodata-img-jobs' src={Nodata} />

                        }

                    </Col>

                </Row>
            </Container>

        </Container>
    )
}

export default ViewJobsNew;