import React from "react";
import {
  Navbar,
  Nav,
  Dropdown,
  Button,
  Container,
  Image,
  Offcanvas
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faBell,
  faCog,
  faQuestionCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import "../styles/Main.css";
import { logoutToken } from "../helpers/utility";
import icon1 from "../assets/images/icon-1.png";
import NewLogo from "../assets/images/New Logo.svg";
import { FaChevronRight } from "react-icons/fa6";
import { PiSignOutBold } from "react-icons/pi";
import { FaBookmark } from "react-icons/fa6";
 
const NavbarComponent = (props) => {

  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));

  const handleLogout = async (e) => {
    e.preventDefault();
    await logoutToken();
  };

  const navButtons=[
    {
      name : "Home",
      route : "/home"
    },
    {
      name : "About",
      route : "/about"
    },
    {
      name : "Jobs",
      route : "/jobs"
    },
    {
      name : "Courses",
      route : "/courses"
    },
    {
      name : "Wildcard",
      route : "/Wildcard"
    }
  ]

  return (
    <Navbar expand="lg" className="navbar navbar-expand-lg navbar-light navbar-bg-color">
      <Container fluid className="navbar-container nav-container">
        <Navbar.Brand as={Link} to="/home" className="navbar-brand d-flex text-white pt-2">
          <Image src={NewLogo} fluid className="nav-logo" />
        </Navbar.Brand>

        <Nav className="ml-auto d-flex flex-row align-items-center d-block d-lg-none">
            { user && Object.keys(user).length !== 0 ?
              <Nav.Link as={Link} to="/savedjobs" className="nav-link" title="Saved Jobs">
                <FaBookmark className="mb-1 me-2 text-dark"/>
              </Nav.Link> : null
            }
            
            <Nav.Link as={Link} to="/message" className="nav-link" title="Messages">
              <FontAwesomeIcon icon={faEnvelope} size={50} className="mx-2 text-dark" />
            </Nav.Link>
            <Nav.Link as={Link} to="/Notification" className="nav-link" title="Notifications">
              <FontAwesomeIcon icon={faBell} size={50} className="mx-2 text-dark" />
            </Nav.Link>

            <Navbar.Toggle aria-controls="offcanvasNavbar" className="bg-white" />
        </Nav>
        
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          className="navbar-offcanvas offcanvas-height"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="mx-auto">
              {/* <Nav.Link as={Link} to="/home" className="nav-link navbar-text navbar-text-bold border border-danger d-none d-lg-block">
                <span className="nav-button">Home</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/about" className="nav-link navbar-text navbar-text-bold d-none d-lg-block">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/jobs" className="nav-link navbar-text navbar-text-bold d-none d-lg-block">
                Jobs
              </Nav.Link>
              <Nav.Link as={Link} to="/courses" className="nav-link navbar-text navbar-text-bold d-none d-lg-block">
                Courses
              </Nav.Link>
              <Nav.Link as={Link} to="/wilecard" className="nav-link navbar-text navbar-text-bold d-none d-lg-block">
                Wilecard
              </Nav.Link> */}

              { navButtons.map((val,index)=>
                <Nav.Link key={index} as={Link} to={val.route} className="nav-link navbar-text-bold d-none d-lg-block">{val.name}</Nav.Link>
              )}

              <Nav.Link as={Link} to="/home" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Home</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/about" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">About</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/jobs" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Jobs</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/courses" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Courses</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/Wildcard" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Wildcard</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/companyreviews" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Company reviews</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/salaryguide" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Salary guide</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/employer" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Employers</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/profile" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Profile</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/myreview" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">My Reviews</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/settings" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Settings</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/helpcentre" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Help centre</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              <Nav.Link as={Link} to="/terms" className="nav-link navbar-text-bold d-flex d-lg-none justify-content-between menu-content">
                <p className="mt-2">Terms</p>
                <FaChevronRight className="mt-2"/>
              </Nav.Link>
              

            </Nav>
            <div>
              <Nav className="ml-auto">
                { user && Object.keys(user).length !== 0 ? 
                  <Nav.Link as={Link} to="/savedjobs" className="nav-link d-none d-lg-block" title="Saved Jobs">
                    <FaBookmark className="mb-1"/>
                  </Nav.Link> : null
                }
                
                <Nav.Link as={Link} to="/message" className="d-none d-lg-block" title="Messages">
                  <FontAwesomeIcon icon={faEnvelope} size={55} className="ms-1" />
                </Nav.Link>
                <Nav.Link as={Link} to="/Notification" className="nav-link d-none d-lg-block" title="Notifications">
                  <FontAwesomeIcon icon={faBell} size={50} className="ms-1" />
                </Nav.Link>

                {user && Object.keys(user).length !== 0 ? (
                  <>
                    <Dropdown drop="down" className="d-none d-lg-block" title="Profile">
                      <Dropdown.Toggle as={Button} variant="link" className="text-black p-2" id="dropdown-basic">
                        <Image src={icon1} height="17" className="mb-1" />
                      </Dropdown.Toggle>  

                      <Dropdown.Menu align="end" className="flex-row">
                        <Dropdown.Item as={Link} to="/jobSeeker/profile" className="nav-background" title="Profile">
                          <span className="text-decoration-none text-dark p-2 nav-link navbar-text">
                            Profile
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/myjobs" className="nav-background" title="My Jobs">
                          <FontAwesomeIcon icon={faCog} className="mr-2 white-icon text-dark" />
                          <span className="text-decoration-none text-dark p-2">
                            My Jobs
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/myreviews" className="nav-background" title="My Reviews">
                          <FontAwesomeIcon icon={faStar} className="mr-2" />
                          <span className="text-decoration-none text-dark p-2">
                            My Reviews
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/settings" className="nav-background" title="Settings">
                          <FontAwesomeIcon icon={faCog} className="mr-2" />
                          <span className="text-decoration-none text-dark p-2">
                            Settings
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/helpcenter" className="nav-background" title="Help Center">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                          <span className="text-decoration-none text-dark p-2">
                            Help Center
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={handleLogout} className="nav-background" title="Logout">Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <div as={Link} to="" className="navbar-text d-flex d-lg-none menu-content">
                      <div className="cursor-pointer d-flex justify-content-start">
                        <PiSignOutBold className="mt-1 fs-5 text-decoration-none text-dark"/>
                        <p className="ms-1 navbar-text-bold" onClick={handleLogout}>SignOut</p>
                      </div>
                    </div>
                  </>
                  
                ) : null}
                {user && Object.keys(user).length !== 0 ? null : (
                  <Nav.Link as={Link} to="/auth/signup" className="nav-link mb-1 navbar-text-bold">
                    SignUp/SignIn
                  </Nav.Link>
                )}
                <div className="space-div d-flex d-lg-none">

                </div>
                
              </Nav>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
