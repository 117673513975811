import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Card, InputGroup } from "react-bootstrap";
import $, { data } from 'jquery';
import MultiRangeSlider from '../components/MultiRangeSlider';
import "../assets/css/ViewJobs.css";
import { FiMinus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { object } from 'prop-types';


const ViewJobsFilters = ({sentDataToParent, searchApi}) =>{

    const [service, setService] = useState('');
    const [category, setCategory] = useState('');
    // const [country, setCountry] = useState('');
    // const [states, setStates] = useState({});
    const [dropdown, setDropdown] = useState({});
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const [isOpenSearch, setIsOpenSearch] = useState(true);
    const [isOpenSite, setIsOpenSite] = useState(true);
    const [isOpenStates, setIsOpenStates] = useState(true);
    const [isOpenJobtype, setIsOpenJobtype] = useState(true);
    const [isOpenExperience, setIsOpenExperience] = useState(true);
    const [isOpenSalary, setIsOpenSalary] = useState(true);
    const [isOpenCategory, setIsOpenCategory] = useState(true);

    const [checkboxSite, setCheckboxSite] = useState({
        // remote: false,
        // onsite: false
    });

    const [checkboxJobType, setCheckboxJobType] = useState({
        // internship: false,
        // fulltime: false,
        // parttime: false
    });

    const [checkboxExperience, setCheckboxExperience] = useState({
        // fresher: false,
        // onetothreeyear: false,
        // fourtosixyear: false,
        // sevenplusyear: false
    });

    const handleCheckboxSite = async (event) => {
        const { id, checked } = event.target;

        const updatedSite = checked ? { jobType2: id } : { jobType2: '' };        
        setCheckboxSite(updatedSite);

        const payload = {
            ...updatedSite,
            page: 1,
            status: "opened"
        };
        // console.log("payload of site checkbox jobType2", payload);
        await searchApi(payload);

        // if(updatedSite.jobType2 !== null){
        //     const payload = {
        //         ...updatedSite,
        //         page: 1,
        //         status: "opened"
        //     };
        //     console.log("payload of site checkbox jobType2 not null", payload);
        //     await searchApi(payload);
        // }
        // else{
        //     const payload = {
        //         ...updatedSite,
        //         page: 1,
        //         status: "opened"
        //     };
        //     console.log("payload of site checkbox jobType2 null", payload);
        //     await searchApi(payload);
        // }
    };

    const handleCheckboxJobType = async(event) => {
        const { id, checked } = event.target;

        const updatedJobType = checked ? { jobType : id } : { jobType : '' };
        setCheckboxJobType(updatedJobType);

        const payload = {
            ...updatedJobType,
            page: 1,
            status: "opened"
        };
        console.log("payload of jobType checkbox",payload);
        await searchApi(payload);
    };

    const handleCheckboxExperience = async(event) => {
        const { id, checked } = event.target;

        const updateExperience = checked ? { experiance : id } : { experiance : ''};
        setCheckboxExperience(updateExperience);

        if(updateExperience.experiance === 'fresher'){
            const payload = {
                minExp : 0,
                maxExp : 1,
                page: 1,
                status: "opened"
            };
    
            console.log("handleCheckboxExperience payload fresher",payload);
            await searchApi(payload);
        }
        else if(updateExperience.experiance === '1to3year'){
            const payload = {
                minExp : 1,
                maxExp : 3,
                page: 1,
                status: "opened"
            };
    
            console.log("handleCheckboxExperience payload 1to3year",payload);
            await searchApi(payload);   
        }
        else if(updateExperience.experiance === '4to6year'){
            const payload = {
                minExp : 4,
                maxExp : 6,
                page: 1,
                status: "opened"
            };
    
            console.log("handleCheckboxExperience payload 4to6year",payload);
            await searchApi(payload);   
        }
        else if(updateExperience.experiance === '7plusyear'){
            const payload = {
                minExp : 7,
                maxExp : "",
                page: 1,
                status: "opened"
            };
    
            console.log("handleCheckboxExperience payload 7plusyear",payload);
            await searchApi(payload);   
        }
        else{
            const payload = {
                minExp : "",
                maxExp : "",
                page: 1,
                status: "opened"
            };
    
            console.log("handleCheckboxExperience payload 7plusyear",payload);
            await searchApi(payload);
        }

        // const payload = {
        //     ...updateExperience,
        //     page: 1,
        //     status: "opened"
        // };

        // console.log("handleCheckboxExperience payload",payload);
        // await searchApi(payload);
    };

    // const searchRef = useRef(null);
    const SiteRef = useRef(null);
    const StatesRef = useRef(null);
    const jobtypeRef = useRef(null);
    const experienceRef = useRef(null);
    const salaryRef = useRef(null);
    const categoryRef = useRef(null);

    const elements = [
        // { ref: searchRef, isOpen: isOpenSearch },
        { ref: SiteRef, isOpen: isOpenSite },
        { ref: StatesRef, isOpen: isOpenStates },
        { ref: jobtypeRef, isOpen: isOpenJobtype },
        { ref: experienceRef, isOpen: isOpenExperience },
        { ref: salaryRef, isOpen: isOpenSalary },
        { ref: categoryRef, isOpen: isOpenCategory },
    ];

    useEffect(() => {
        elements.forEach(({ ref, isOpen }) => {
            if (ref.current) {
                if (isOpen) {
                    $(ref.current).slideDown();
                } else {
                    $(ref.current).slideUp();
                }
            }
        });
    }, [isOpenSite, isOpenStates, isOpenJobtype, isOpenExperience, isOpenSalary, isOpenCategory]);


    const [filtersData, setFiltersData] = useState({
        ...checkboxSite,
        ...checkboxJobType,
        ...checkboxExperience
    });

    let dropDownfield = {}
    
    const handleEducationchange = async(e) => {
        // dropDownfield["educationLevel"]=e.target.value;
        // // setDropdown({...dropdown,...dropDownfield});
        // setFiltersData({...filtersData,...dropDownfield});
        // console.log("dropDownfield",dropDownfield);

        const payload = {
            educationLevel : e.target.value,
            page: 1,
            status: "opened"
        }
        setFiltersData(payload);
        console.log("dropDownfield",payload);
        
        await searchApi(payload);
    };

    const handleCategoryChange = async(e) => {
        dropDownfield["category"]=e.target.value;
        // setDropdown({...dropdown,...dropDownfield});
        setFiltersData({...filtersData,...dropDownfield});

        // await searchApi(dropDownfield);
    };

    // console.log("dropdown",dropdown);

    const handleDataChange = (data) => {
        setFiltersData({...filtersData,...data});
    };
    
    const toggleSite = () => {
        setIsOpenSite(!isOpenSite);  // Toggle the site state
    };
    

    const toggleStates = () => {
        setIsOpenStates(!isOpenStates);
    };

    const toggleJobtype = () => {
        setIsOpenJobtype(!isOpenJobtype);
    };

    const toggleExperience = () => {
        setIsOpenExperience(!isOpenExperience);
    };

    const toggleSalary = () => {
        setIsOpenSalary(!isOpenSalary);
    };

    const toggleCategory = () => {
        setIsOpenCategory(!isOpenCategory);
    };

    // const [selectedOption, setSelectedOption] = useState('');
    // const [selectedOptionExperience, setSelectedOptionExperience] = useState('');

    // const handleCheckboxChange = (event) => {
    //     const { name } = event.target;
    //     setSelectedOption(name);
    // };

    // const handleCheckboxexperience = (event) => {
    //     const { name } = event.target;
    //     setSelectedOptionExperience(name);
    // };

    return(
        <>
            <Card className='mt-3 filter-card'>
                {/* Site Section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Site</p>
                        <div onClick={toggleSite}>
                            {isOpenSite ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div className='mb-2' ref={SiteRef} style={{ display: 'block' }}>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                type="checkbox"
                                id="remote"
                                className="filter-checkbox"
                                // checked={checkboxSite.remote}
                                checked={checkboxSite.jobType2 === 'remote'} 
                                onChange={handleCheckboxSite}
                            />
                            <label htmlFor="remote" className="filter-label">
                                Remote
                            </label>
                        </div>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                type="checkbox"
                                id="onsite"
                                className="filter-checkbox"
                                // checked={checkboxSite.onsite}
                                checked={checkboxSite.jobType2 === 'onsite'} 
                                onChange={handleCheckboxSite}
                            />
                            <label htmlFor="onsite" className="filter-label">
                                Onsite
                            </label>
                        </div>
                        {/* <div className="d-flex align-items-center">
                            <Form.Check
                                type="checkbox"
                                id="hybrid"
                                className="filter-checkbox"
                                checked={checkboxSite.hybrid}
                                onChange={handleCheckboxSite}
                            />
                            <label htmlFor="hybrid" className="filter-label">
                                Hybrid
                            </label>
                        </div> */}
                    </div>
                </div>

                <hr />

                {/* States Section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Education level</p>
                        <div onClick={toggleStates}>
                            {isOpenStates ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div className='mb-2' ref={StatesRef} style={{ display: 'block' }}>
                        <InputGroup className='my-2 dropdown-border'>
                            <Form.Control
                                className='forms-control'
                                as="select"
                                // value={dropdown}
                                onChange={handleEducationchange}
                            >
                                <option value="">Select Degree</option>
                                <option value="none">None</option>
                                <option value="diplama">Diplama</option>
                                <option value="bachelor degree">Bachelor's degree</option>
                                <option value="master degree">Master's degree</option>
                            </Form.Control>
                            <InputGroup.Text className='arrow-icon2'>
                                <IoIosArrowDown />
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </div>

                <hr />

                {/* Job type section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Job Type</p>
                        <div onClick={toggleJobtype}>
                            {isOpenJobtype ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div
                        ref={jobtypeRef}
                        className={`collapse-content mb-2 ${isOpenJobtype ? 'open' : 'closed'}`}  // Conditional class based on state
                    >
                        <Form>
                            <div className='mt-1 d-flex justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        id="fulltime"
                                        className="filter-checkbox"
                                        checked={checkboxJobType.jobType === 'fulltime'}
                                        onChange={handleCheckboxJobType}
                                    />
                                    <label htmlFor="fulltime" className="filter-label">
                                        Full time
                                    </label>
                                </div>
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>1</p>
                                </div> */}
                            </div>

                            <div className='mt-1 d-flex justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        id="parttime"
                                        className="filter-checkbox"
                                        checked={checkboxJobType.jobType === 'parttime'}
                                        onChange={handleCheckboxJobType}
                                    />
                                    <label htmlFor="parttime" className="filter-label">
                                        Part time
                                    </label>
                                </div>
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>10</p>
                                </div> */}
                            </div>

                            <div className='mt-1 d-flex justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        id="internship"
                                        className="filter-checkbox"
                                        checked={checkboxJobType.jobType === 'internship'}
                                        onChange={handleCheckboxJobType}
                                    />
                                    <label htmlFor="internship" className="filter-label">
                                        Internship
                                    </label>
                                </div>
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>1</p>
                                </div> */}
                            </div>
                        </Form>
                    </div>
                </div>

                <hr />

                {/* Experience section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Experience</p>
                        <div onClick={toggleExperience}>
                            {isOpenExperience ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div
                        ref={experienceRef}
                        className={`collapse-content mb-2 ${isOpenExperience ? 'open' : 'closed'}`}  // Conditional class based on state
                    >
                        <Form>
                            <div className='mt-1 d-flex justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        id="fresher"
                                        className="filter-checkbox"
                                        checked={checkboxExperience.experiance === 'fresher'}
                                        onChange={handleCheckboxExperience}
                                    />
                                    <label htmlFor="fresher" className="filter-label">
                                        Fresher
                                    </label>
                                </div>
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>1</p>
                                </div> */}
                            </div>

                            <div className='mt-1 d-flex justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        id="1to3year"
                                        className="filter-checkbox"
                                        checked={checkboxExperience.experiance === '1to3year'}
                                        onChange={handleCheckboxExperience}
                                    />
                                    <label htmlFor="1to3year" className="filter-label">
                                        1 - 3 Years
                                    </label>
                                </div>
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>10</p>
                                </div> */}
                            </div>
                            <div className='mt-1 d-flex justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        id="4to6year"
                                        className="filter-checkbox"
                                        checked={checkboxExperience.experiance === '4to6year'}
                                        onChange={handleCheckboxExperience}
                                    />
                                    <label htmlFor="4to6year" className="filter-label">
                                        4 - 6 Years
                                    </label>
                                </div>
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>10</p>
                                </div> */}
                            </div>
                            <div className='mt-1 d-flex justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        id="7plusyear"
                                        className="filter-checkbox"
                                        checked={checkboxExperience.experiance === '7plusyear'}
                                        onChange={handleCheckboxExperience}
                                    />
                                    <label htmlFor="7plusyear" className="filter-label">
                                        7+ Years
                                    </label>
                                </div>
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>10</p>
                                </div> */}
                            </div>
                        </Form>
                    </div>
                </div>

                <hr />

                {/* Salary section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Salary</p>
                        <div onClick={toggleSalary}>
                            {isOpenSalary ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div
                        ref={salaryRef}
                        className={`collapse-content ${isOpenSalary ? 'open' : 'closed'}`}  // Conditional class based on state
                    >
                       
                        <MultiRangeSlider
                            min={0}
                            max={5000000}
                            onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
                            multiRangevalues={handleDataChange}
                            searchApi = {searchApi}
                        />

                    </div>
                </div>

                {/* <hr /> */}

                {/* Category section */}
                {/* <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Category</p>
                        <div onClick={toggleCategory}>
                            {isOpenCategory ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div
                        ref={categoryRef}
                        className={`collapse-content ${isOpenCategory ? 'open' : 'closed'}`}  // Conditional class based on state
                    >
                        <InputGroup className='my-2 dropdown-border'>
                            <Form.Control
                                className='forms-control'
                                as="select"
                                // value={dropdown}
                                onChange={handleCategoryChange}
                            >
                                <option value="">Select Category</option>
                                <option value="python">Python</option>
                                <option value="node">Node</option>
                                <option value="java">Java</option>
                                <option value="C">C</option>
                                <option value="C++">C++</option>
                                <option value="javascript">Javascript</option>
                            </Form.Control>
                            <InputGroup.Text className='arrow-icon2'>
                                <IoIosArrowDown />
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </div> */}

                {/* <hr />

                <div>
                    <Button className='viewjobs-apply-btn' size="lg">Apply Filter</Button>
                    <Button className='clearfilter-btn bg-transparent text-dark mt-2' size="lg">Clear Filter</Button>
                </div> */}
            </Card>
        </>
    )
}

export default ViewJobsFilters;