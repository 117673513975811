import React from 'react';
import Slider from 'react-slick';

// import "../../assets/css/MultiCarousel.css"
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { BsBriefcase } from "react-icons/bs";
import { GoClock } from "react-icons/go";
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import "../../assets/css/home.css";
import icon from "../../assets/images/icon.jpg";
import { encryptParam } from '../../helpers/UrlEncryptDecrypt';

const MultiCarousel = ({ jobs }) => {
    console.log("jobs....",jobs);
    const secretKey = process.env.REACT_APP_WALKUPP_SECRET_KEY || "walkupp-secret-key";
    
    if (jobs.length === 1 || jobs.length === 2) {
        console.log("multi carosal");
        const job = jobs[0];
        return (
            <Row className="justify-content-center">
                <Col md={5} xs={8}>
                    <Card className="shadow border-0 p-1 job-card">
                        <CardBody>
                            <Row>
                                <Col xs={2}>
                                    <Image src={icon} className="img-icon" />
                                </Col>
                                <Col xs={10} className="text-start">
                                    <h6 className='multicarousel-text'>{job.companyName ? job.companyName : ""}</h6>
                                    <p className='multicarousel-text2'>{job.jobBasics.jobLocation ? job.jobBasics.jobLocation.city : ""}</p>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col sm={12}>
                                    <h6 className='multicarousel-text'>{job.jobBasics.jobTitle}</h6>
                                </Col>
                                <Col md={6} className='mt-2 mt-md-1'>
                                    <GoClock className="text-dark fs-5 me-1" />{" "}
                                    <span className='text-muted multicarousel-text2'>{job.jobDetails.jobType}</span>
                                </Col>
                                <Col md={6} className='mt-2 mt-md-1'>
                                    <BsBriefcase BsBriefcase className="text-dark fs-5 me-1" />{" "}
                                    {job.salary?.range?.minimum || 'N/A'} - {job.salary?.range?.maximum || 'N/A'}
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                {/* <Col sm={12} lg={12} xl={6} className='mt-2'>
                                    <div className='d-flex justify-content-center align-items-center bg-white rounded'>
                                        <p className='mt-1 text-muted multicarousel-text2'>Figma</p>
                                    </div>
                                </Col>
                                <Col sm={12} lg={12} xl={6} className='mt-2'>
                                    <div className='d-flex justify-content-center bg-white rounded'>
                                        <p className='mt-1 text-muted multicarousel-text2'>Adobe Photoshop</p>
                                    </div>
                                </Col>
                                <Col sm={12} lg={12} xl={6} className='mt-2'>
                                    <div className='d-flex justify-content-center bg-white rounded'>
                                        <p className='mt-1 text-muted multicarousel-text2'>Adobe Illustrator</p>
                                    </div>
                                </Col>
                                <Col sm={12} lg={12} xl={6} className='mt-2'>
                                    <div className='d-flex justify-content-center bg-white rounded'>
                                        <p className='mt-1 text-muted multicarousel-text2'>Canva</p>
                                    </div>
                                </Col> */}
                            </Row>
                            <Row className='mt-4  d-flex justify-content-end'>
                                <Col sm={9} lg={6} xl={5} className='d-flex justify-content-end'>
                                    <Link to={`/job/detail/${encryptParam(job._id,secretKey)}`} className="text-decoration-none w-100 btn btn-color-primary mobile-view-padding-one">
                                            More Details
                                    </Link>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
    

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {jobs.map((job, index) => (
                <Card className="border-0 p-1 job-card" key={job._id}>
                    <CardBody className='job-card-body'>
                        <Row>
                            <Col xs={2} sm={3} md={2} className=''>
                                <Image src={icon} className="img-icon" />
                            </Col>
                            <Col xs={10} sm={9} md={10} className="text-start">
                                <h6 className='multicarousel-text'>{job.companyName}</h6>
                                <p className='multicarousel-text2'>{job.jobBasics.jobLocation ? job.jobBasics.jobLocation.city : ""}</p>
                            </Col>
                        </Row>
                        <div className="mt-3">
                            <h6 className='multicarousel-text'>{job.jobBasics.jobTitle}</h6>
                        </div>
                        <Row className="mt-1 job-icons">
                            {/* <Col sm={12}>
                                <h6 className='multicarousel-text'>{job.jobBasics.jobTitle}</h6>
                            </Col>   */}
                            <Col md={6} className='mt-2 mt-md-1'>
                                <GoClock className="text-dark fs-5 me-1" />{" "}
                                <span className='text-muted multicarousel-text2'>{job.jobDetails.jobType}</span>
                            </Col>
                            <Col md={6} className='mt-2 mt-md-1'>
                                <BsBriefcase BsBriefcase className="text-dark fs-5 me-1" />{" "}
                                <span className='text-muted multicarousel-text2'>{job.salary?.range?.minimum || 'N/A'} - {job.salary?.range?.maximum || 'N/A'}
                                </span>
                            </Col>
                        </Row>
                        <Row className='mt-1 job-main'>
                            {/* <Col sm={12} lg={12} xl={6} className='mt-2'>
                                <div className='d-flex justify-content-center align-items-center bg-white rounded'>
                                    <p className='mt-1 text-muted multicarousel-text2'>Figma</p>
                                </div>
                            </Col>
                            <Col sm={12} lg={12} xl={6} className='mt-2'>
                                <div className='d-flex justify-content-center bg-white rounded'>
                                    <p className='mt-1 text-muted multicarousel-text2'>Adobe Photoshop</p>
                                </div>
                            </Col>
                            <Col sm={12} lg={12} xl={6} className='mt-2'>
                                <div className='d-flex justify-content-center bg-white rounded'>
                                    <p className='mt-1 text-muted multicarousel-text2'>Adobe Illustrator</p>
                                </div>
                            </Col>
                            <Col sm={12} lg={12} xl={6} className='mt-2'>
                                <div className='d-flex justify-content-center bg-white rounded'>
                                    <p className='mt-1 text-muted multicarousel-text2'>Canva</p>
                                </div>
                            </Col> */}
                        </Row>
                        {/* <Row className='mt-4  d-flex justify-content-end'>
                            <Col sm={9} lg={8} xl={8} className='d-flex justify-content-end'>
                                <Link to={`/job/${job._id}/${true}`} className="text-decoration-none w-100 btn btn-color-primary mobile-view-padding-one">
                                        More Details
                                </Link>
                            </Col>
                        </Row> */}
                        <div className='mt-4 w-100 d-flex justify-content-end'>
                            <Link to={`/job/detail/${encryptParam(job._id,secretKey)}`} className="text-decoration-none py-2 btn btn-color-primary mobile-view-padding-one">
                                    More Details
                            </Link>
                        </div>
                    </CardBody>
                </Card>
            ))}
        </Slider>
    );
};


const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            Previous
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            Next
        </div>
    );
};

export default MultiCarousel;
