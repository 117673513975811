import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Image, Card } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import "../../../styles/ThemeColors.css";
import Pagination from 'react-bootstrap/Pagination';
import { encryptParam } from "../../../helpers/UrlEncryptDecrypt";
import { logDOM } from "@testing-library/react";
import Nodata from "../../../assets/images/no-data.webp";

const MyJobsApplied = ({ jobs,totalPage,fetchJob, viewAppliedStatus }) => {

  const secretKey = process.env.REACT_APP_WALKUPP_SECRET_KEY || "walkupp-secret-key";

  const [activePage,setActivePage] = useState(1);
  console.log("jobs",jobs);
  

  useEffect(() => {
    fetchJob(activePage);
  }, [activePage, viewAppliedStatus]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber); 
  }

  const renderPageNumbersJobs = () => {
    const pages = [];
    
    if (totalPage <= 5) {
        for (let number = 1; number <= totalPage; number++) {
            pages.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }
    }

    else {
        if (activePage > 2) {
            pages.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>{1}</Pagination.Item>);
            if (activePage > 3) pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        for (let number = Math.max(1, activePage - 1); number <= Math.min(totalPage, activePage + 1); number++) {
            pages.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (activePage < totalPage - 2) {
            pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
            pages.push(
                <Pagination.Item key={totalPage} onClick={() => handlePageChange(totalPage)}>
                    {totalPage}
                </Pagination.Item>
            );
        }
    }

    return pages;
  };

  return (
    <Container>
      {jobs.length > 0 ? (
        <>
        {
          jobs.map((job) => (
            <Card key={job._id} className="my-3" style={{ border: "none", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
              <Card.Body>
                {/* <Card.Title>{job.jobId.jobBasics.jobTitle}</Card.Title> */}
                <Card.Subtitle className="mb-2 text-muted"><p>{ job.jobId.jobBasics.jobTitle }</p></Card.Subtitle>
                <Row className="mb-3">
                  <Col xs={6}>
                    <p>Status: { job.applicationStatus === "active" ? job.subApplicationStatus : job.applicationStatus }</p>
                  </Col>
                  <Col xs={6} className="text-end">
                    <p>Applied: {new Date(job.applicationDate).toLocaleDateString()}</p>
                  </Col>
                </Row>
                <Link to={`/jobs/detail/${encryptParam(job.jobId?._id,secretKey)}/${true}`} className="viewDetails-btn">
                  View Details
                </Link>
              </Card.Body>
            </Card>
          ))
        }
        <Pagination className="justify-content-center mt-5 custom-pagination">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={activePage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
          {renderPageNumbersJobs()}
          <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPage} />
          <Pagination.Last onClick={() => handlePageChange(totalPage)} disabled={activePage === totalPage} />
        </Pagination>
        </>
      ) : 
      // (
      //   <>
      //     <Row className="justify-content-center">
      //       <Col className="text-center">
      //         <Image
      //           src="https://media.istockphoto.com/id/913685766/vector/clipboard-with-checklist.jpg?s=612x612&w=0&k=20&c=J5NuNqbu4cIz4HwKG8qc_Y_b6mUPwILzazJF1PqqFGM="
      //           rounded
      //           fluid
      //           style={{ width: "200px" }}
      //         />
      //       </Col>
      //     </Row>
      //     <Row className="justify-content-center mt-3">
      //       <Col className="text-center">
      //         <strong>No applications yet</strong>
      //       </Col>
      //     </Row>
      //     <Row className="justify-content-center mt-3">
      //       <Col className="text-center">
      //         <p className="text-secondary">
      //           Keep track of job applications here.
      //         </p>
      //       </Col>
      //     </Row>
      //     <Row className="justify-content-center mt-3">
      //       <Col className="text-center">
      //         <Link to="/" className="text-decoration-none text-colors">
      //           <strong>Not seeing applications?</strong>
      //         </Link>
      //       </Col>
      //     </Row>
      //     <Row className="justify-content-center mt-3">
      //       <Col className="text-center">
      //         <Button variant="primary btn-colors">
      //           <strong>Find jobs</strong>
      //           <FaArrowRight />
      //         </Button>
      //       </Col>
      //     </Row>
      //   </>
      // )

      <Image className='nodata-img' src={Nodata} />
      }
      

    </Container>
  );
};

export default MyJobsApplied;
