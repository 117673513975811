import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Image from "react-bootstrap/Image";
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Form as BootstrapForm, Button} from 'react-bootstrap';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import userImage from "../../../assets/images/user.webp";
import NavbarComponent from '../../../components/Navbar';
import CustomProfileModal from '../../../components/common/CustomProfileModal';
import { getCityState, getJobSeeker, jobSeekerImageUpdate, jobSeekerProfileUpdate } from '../../../helpers/backendHelper';
import * as url from "../../../helpers/urlHelper";
import "../../../styles/Myprofile.css";
import { BiDownload } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";

const cities = [
    'New York, NY',
    'Los Angeles, CA',
    'Chicago, IL',
    'Houston, TX',
    'Phoenix, AZ',
    'Philadelphia, PA',
    'San Antonio, TX',
    'San Diego, CA',
    'Dallas, TX',
    'San Jose, CA'
];

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobileNumber: Yup.string(),
    cityState: Yup.string().required('City State is required'),
    pinCode: Yup.string().required('Pin Code is required'),
    comment: Yup.string()
});

const JobSeekerProfile = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [dummyImage, setDummyImage] = useState(userImage);
    const [userData, setUserData] = useState({
        firstName: "",
        email: "",
        mobileNumber: "",
        cityState: "",
        pinCode: "",
        country: "",
        comment: "",
        isProfileUpdate: false,
        jobSeekerProfileImage: userImage,
        jobSeekerCoverImage: userImage
    });
    const [editMode, setEditMode] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [isTwoFAEnabled, setIsTwoFAEnabled] = useState(false);
    const [isSetup2Fa, setIsSetup2Fa] = useState(false);
    const [allCityState, setAllCityState] = useState([]);
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [editableImage, setEditableImage] = useState();

    const [popupForSkill, setPopupForSkill] = useState(false);
    const [popupSkill, setPopupSkill] = useState(false);

    useEffect(() => {
        fetchUserData();
    }, [editMode]);

    useEffect(() => {
        fetchCityState();
    }, []);

    const fetchCityState = async () => {
        try {
            const response = await getCityState();
            if (response && response.statusCode === 200) {
                const data = response.cities;
                setAllCityState(data);
            }
        } catch (error) {
            console.error("Error fetching city/state data:", error);
        }
    };

    const updateAvatar = async (imgSrc) => {
        const base64ToFile = (base64String, fileName) => {
            const arr = base64String.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], fileName, { type: mime });
        };

        const formData = new FormData();
        const file = base64ToFile(imgSrc, `${editableImage}.png`);
        formData.append(editableImage, file);

        try {
            await jobSeekerImageUpdate(formData);
            fetchUserData();
        } catch (error) {
            console.error("Error updating avatar:", error);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await getJobSeeker();
            if (response && response.statusCode === 200) {
                const data = response.jobseeker;
                setUserData(data);
                console.log("userdata.....",userData);
                
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleEdit = () => {
        setEditMode(!editMode);
    };

    const handleChangeTwoFA = () => {
        setIsSetup2Fa(!isSetup2Fa);
    };

    const handleClose2Fa = () => {
        setIsSetup2Fa(false);
    };

    const handleSave = async (values, { setSubmitting }) => {
        try {
            const payload = {
                email: values.email,
                mobileNumber: values.mobileNumber || "null",
                firstName: values.firstName,
                cityState: values.cityState,
                pinCode: values.pinCode,
                country: values.country,
                comment: values.comment
            };
            console.log("payload",payload);
            const updateData = await jobSeekerProfileUpdate(payload);
            console.log("updateData",updateData);
            if (updateData && updateData.statusCode === 200) {
                localStorage.setItem("isProfileUpdated", "true");
                Swal.fire('Updated!', 'Profile updated successfully', 'success');
                navigate('/jobs');
            }
            setEditMode(false);
        } catch (error) {
            console.error("Error updating profile:", error);
        }
        setSubmitting(false);
    };

    const handleCamaraClick = (value) => {
        setEditableImage(value);
        setModalOpen(true);
    };

    const getSuggestions = (inputValue) => {
        return allCityState.filter(city =>
            city.toLowerCase().includes(inputValue.trim().toLowerCase())
        );
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(file){
            console.log("selected file",file.name);
        }
    }

    // const optionsLast = [
    //     { value: "2024", label: "2024" },
    //     { value: "2023", label: "2023" },
    //     { value: "2022", label: "2022" },
    //     { value: "2021", label: "2021" },
    //     { value: "2020", label: "2020" },
    //     { value: "2019", label: "2019" },
    //     { value: "2018", label: "2018" },
    //     { value: "2017", label: "2017" },
    //     { value: "2016", label: "2016" },
    //     { value: "2015", label: "2015" },
    //     { value: "2014", label: "2014" },
    // ];

    const optionsYears = [
        { value: "0 Year", label: "0 Year" },
        { value: "1 Year", label: "1 Year" },
        { value: "2 Years", label: "2 Years" },
        { value: "3 Years", label: "3 Years" },
        { value: "4 Years", label: "4 Years" },
        { value: "5 Years", label: "5 Years" },
        { value: "6 Years", label: "6 Years" },
        { value: "7 Years", label: "7 Years" },
        { value: "8 Years", label: "8 Years" },
        { value: "9 Years", label: "9 Years" },
        { value: "10 Years", label: "10 Years" },
    ];

    const optionsMonths = [
        { value: "0 Month", label: "0 Month" },
        { value: "1 Month", label: "1 Month" },
        { value: "2 Months", label: "2 Months" },
        { value: "3 Months", label: "3 Months" },
        { value: "4 Months", label: "4 Months" },
        { value: "5 Months", label: "5 Months" },
        { value: "6 Months", label: "6 Months" },
        { value: "7 Months", label: "7 Months" },
        { value: "8 Months", label: "8 Months" },
        { value: "9 Months", label: "9 Months" },
        { value: "10 Months", label: "10 Months" },
    ];

    // const CustomSelectTitle = ({ field, form, options, placeholder }) => {
    //     return (
    //         <Select
    //             className='w-100'
    //             name={field.name}
    //             value={options.find(option => option.value === field.value)}
    //             onChange={(option) => form.setFieldValue(field.name, option ? option.value : '', "jfsad")}
    //             options={options}
    //             isSearchable
    //             isClearable
    //             placeholder={placeholder}
    //             components={{ DropdownIndicator: null }}
    //         />
    //     );
    // };

    const [selectedOptionLast, setSelectedOptionLast] = useState(null);
    const [selectedOptionYears, setSelectedOptionYears] = useState(null);
    const [selectedOptionMonths, setSelectedOptionMonths] = useState(null);

    const handleSelectChangeLast = (option) => {
        setSelectedOptionLast(option);
    };
    const handleSelectChangeYears = (option) => {
        setSelectedOptionYears(option);
    };
    const handleSelectChangeMonths = (option) => {
        setSelectedOptionMonths(option);
    };

    const customStyles = {
        control: (provided) => ({
          ...provided,
          padding: '5px',
          borderRadius: '20px',
          borderColor: '#d9d9d9',
          boxShadow: 'none',
          '&:hover': { borderColor: '#b3b3b3' },
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#717B9E',
        }),
        menu: (provided) => ({
          ...provided,
          borderRadius: '8px',
        }),
    };

    return (
        <>
            <NavbarComponent />
            <Container className='mt-lg-4 mt-3'>
                <Row>
                    {modalOpen && (
                        <CustomProfileModal
                            updateAvatar={updateAvatar}
                            closeModal={() => setModalOpen(false)}
                            showModal={modalOpen}
                            editableImage={editableImage}
                        />
                    )}
                    <Col lg={6}>
                        <Card className="bg-white border-0">
                            {/* <div className="rounded-3 mb-4 position-relative bg-white rounded">
                                <Image
                                    src={
                                        userData.jobSeekerCoverImage === "null"
                                            ? dummyImage
                                            : `${url.IMAGE_HOST_URL}${userData.jobSeekerCoverImage}`
                                    }
                                    className="w-100 img-fluid rounded-3 cover-image"
                                />
                                <span>
                                    <FontAwesomeIcon
                                        icon={faCamera}
                                        size="lg"
                                        className="position-absolute camera-icon"
                                        onClick={() => handleCamaraClick('coverImage')}
                                    />
                                </span>
                                <Image
                                    src={
                                        userData.employerProfileImage === "null"
                                            ? dummyImage
                                            : `${url.IMAGE_HOST_URL}${userData.jobSeekerProfileImage}`
                                    }
                                    className="img-fluid rounded-3 rounded-circle profile-image-circle"
                                />
                                <FontAwesomeIcon
                                    icon={faCamera}
                                    size="lg"
                                    className="camera-icon2 position-absolute"
                                    onClick={() => handleCamaraClick('profileImage')}
                                />
                            </div> */}
                            
                            <div className="background-container">
                                <Image
                                    src={
                                        userData.jobSeekerCoverImage === "null"
                                            ? dummyImage
                                            : `${url.IMAGE_HOST_URL}${userData.jobSeekerCoverImage}`
                                    }
                                    className="background-image"
                                />
                                <div className="profile-container">
                                    <Image
                                        src={
                                            userData.employerProfileImage === "null"
                                                ? dummyImage
                                                : `${url.IMAGE_HOST_URL}${userData.jobSeekerProfileImage}`
                                        }
                                        className="profile-image"
                                    />
                                    <FontAwesomeIcon
                                    icon={faCamera}
                                    size="lg"
                                    className="camera-icon"
                                    onClick={() => handleCamaraClick("profileImage")}
                                    />
                                </div>
                                <FontAwesomeIcon
                                    icon={faCamera}
                                    size="lg"
                                    className="background-camera"
                                    onClick={() => handleCamaraClick("coverImage")}
                                />
                            </div>
                            
                            <div className="text-center mt-5">
                                <h5 className="mt-sm-3">{userData.firstName}</h5>
                                <p className="text-muted">{userData.cityState}, {userData.country}</p>
                            </div>
                            <div className="p-3">
                                <h5>Info</h5>
                                    {   editMode === false ? <p className="text-justify">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                            enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                                            in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                            nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                            sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p> : <textarea
                                                id="multiLineInput"
                                                rows="4"
                                                placeholder="Add a summary here....."
                                                className="form-control"
                                                style={{ height: '120px', width: '100%' }}
                                                disabled={!editMode}
                                            /> 
                                    }
                                
                            </div>
                        </Card>
                    </Col>
                    <Col className="mt-4 mt-lg-0" lg={6}>
                        <Card className="border-0 bg-white">
                            <CardHeader className="p-0">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={activeTab === '1' ? 'active cursor-pointer' : 'tab-head'}
                                            onClick={() => toggleTab('1')}
                                        >
                                            Profile
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={activeTab === '2' ? 'active cursor-pointer' : 'tab-head'}
                                            onClick={() => toggleTab('2')}
                                        >
                                            Resume
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={activeTab === '3' ? 'active cursor-pointer' : 'tab-head'}
                                            onClick={() => toggleTab('3')}
                                        >
                                            Skills
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={activeTab === '4' ? 'active cursor-pointer' : 'tab-head'}
                                            onClick={() => toggleTab('4')}
                                        >
                                            Personal details
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={activeTab === '5' ? 'active cursor-pointer' : 'tab-head'}
                                            onClick={() => toggleTab('5')}
                                        >
                                            Employment
                                        </NavLink>
                                    </NavItem>
                                    
                                </Nav>
                            </CardHeader>
                            <CardBody>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Formik
                                            initialValues={{
                                                firstName: userData.firstName,
                                                email: userData.email,
                                                mobileNumber: userData.mobileNumber,
                                                cityState: userData.cityState,
                                                pinCode: userData.pinCode,
                                                country: userData.country
                                            }}
                                            validationSchema={validationSchema}
                                            onSubmit={handleSave}
                                            enableReinitialize
                                        >
                                            {formik => (
                                                <Form>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label for="firstName">First Name</Label>
                                                                <Field
                                                                    type="text"
                                                                    name="firstName"
                                                                    id="firstName"
                                                                    className="form-control"
                                                                    disabled={!editMode}
                                                                />
                                                                <ErrorMessage name="firstName" component="div" className="text-danger" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Col md={12}>
                                                            <h5>Address</h5>
                                                        </Col>
                                                    </Row> */}
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="country">Country</Label>
                                                                <Field
                                                                    type="text"
                                                                    name="country"
                                                                    id="country"
                                                                    className="form-control"
                                                                    disabled={!editMode}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="cityState">City</Label>

                                                                <select
                                                                    id="cityState"
                                                                    name="cityState"
                                                                    className="form-control"
                                                                    onChange={(e) => formik.setFieldValue('cityState', e.target.value)}
                                                                    value={formik.values.cityState}
                                                                    disabled={!editMode}
                                                                >
                                                                    <option value="">Select a city</option>
                                                                    {allCityState.map((cityObj) => (
                                                                        <option key={cityObj._id} value={`${cityObj.city}, ${cityObj.state}`}>
                                                                            {cityObj.city}, {cityObj.state}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="pinCode">Pincode</Label>
                                                                <Field
                                                                    type="text"
                                                                    name="pinCode"
                                                                    id="pinCode"
                                                                    className="form-control"
                                                                    disabled={!editMode}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="email">Email</Label>
                                                                <Field
                                                                    type="email"
                                                                    name="email"
                                                                    id="email"
                                                                    className="form-control"
                                                                    disabled
                                                                />
                                                                <ErrorMessage name="email" component="div" className="text-danger" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="mobileNumber">Phone</Label>
                                                                <Field
                                                                    type="text"
                                                                    name="mobileNumber"
                                                                    id="mobileNumber"
                                                                    className="form-control"
                                                                    disabled={!editMode}
                                                                />
                                                                <ErrorMessage name="mobileNumber" component="div" className="text-danger" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Button className="edit-btn" onClick={editMode ? formik.handleSubmit : handleEdit}>
                                                        {editMode ? 'Save' : 'Edit'}
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>

                                    </TabPane>

                                    <TabPane tabId="2">
                                        <p>Resume</p>

                                        <Row className="mt-4">
                                            <Col>
                                                <span>File Name</span>
                                                <p className="text-secondary">uploaded date</p>
                                            </Col>

                                            <Col className="d-flex justify-content-end">
                                                <div className="d-flex">
                                                    <div className="resume-icon-div me-3">
                                                        <BiDownload className="text-primary" />
                                                    </div>

                                                    <div className="resume-icon-div">
                                                        <RiDeleteBinLine className="text-primary" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="resume-border">
                                            <input
                                                id="fileUpload"
                                                className="border border-danger"
                                                type="file"
                                                style={{ display: "none" }}
                                                onChange={handleFileChange}
                                            />
                                            <label htmlFor="fileUpload" className="d-flex justify-content-center">
                                                <Button className="rounded-pill bg-transparent text-primary border border-primary cursor-pointer">Update resume</Button>
                                            </label>
                                            <p className="mt-3 text-secondary text-center">Accepted file types are PDF, DOCX, RTF or TXT.</p>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="3">
                                        <div className="d-flex justify-content-between">
                                            <p>Skills</p>
                                            <p className="text-primary fw-bolder cursor-pointer" onClick={() => setPopupForSkill(true)}>Add details</p>
                                        </div>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                <td className="text-muted">Skills</td>
                                                {/* <td className="text-muted">Version</td>
                                                <td className="text-muted">Last used</td> */}
                                                <td className="text-muted">Experience</td>
                                                <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Reacts Js</td>
                                                    {/* <td>-</td>
                                                    <td>2024</td> */}
                                                    <td>0 Year 3 Months</td>
                                                    <td>
                                                    <FiEdit2 className="cursor-pointer edit-btn-profile" onClick={() => setPopupForSkill(true)} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Row>
                                            <Col className="mt-3" xs={12}>
                                                <span className="text-muted">Certifications</span>
                                                <div className="d-flex">
                                                    <span className="text-primary cursor-pointer">Add Certifications</span>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Modal
                                            size="lg"
                                            show={popupForSkill}
                                            onHide={() => setPopupForSkill(false)}
                                            aria-labelledby="example-modal-sizes-title-lg"
                                        >
                                            <Modal.Body className="px-3 px-md-5 py-4 py-md-5">
                                                <p className="fs-5">Skills <span className=" ms-2 profile-color-green">Add %</span></p>
                                                <p className="profile-page-text">Mention skills like programming languages (Java, Python), softwares (Microsoft Word, Excel) and more, to show your technical expertise.</p>
                                                <div>
                                                    <Row className="mt-4">
                                                        <Col xs={12}>
                                                            <BootstrapForm.Group className="mb-3" controlId="formBasicEmail">
                                                                <BootstrapForm.Label>Skill / software name <span className="text-danger">*</span></BootstrapForm.Label>
                                                                <BootstrapForm.Control type="text" placeholder="Skill / Software name" className="BootstrapForms" />
                                                            </BootstrapForm.Group>
                                                        </Col>

                                                        {/* <Col md={6} className="mt-3">
                                                            <BootstrapForm.Group className="mb-2" controlId="formBasicEmail">
                                                                <BootstrapForm.Label>Software version</BootstrapForm.Label>
                                                                <BootstrapForm.Control type="email" placeholder="Software version" className="BootstrapForms" />
                                                            </BootstrapForm.Group>
                                                        </Col> */}

                                                        {/* <Col md={6} className="mt-3">
                                                            <BootstrapForm.Group className="mb-2" controlId="formBasicEmail">
                                                                <BootstrapForm.Label>Last used</BootstrapForm.Label>

                                                                <Select
                                                                    className="w-100"
                                                                    value={selectedOptionLast}
                                                                    onChange={handleSelectChangeLast}
                                                                    options={optionsLast}
                                                                    placeholder="Last used"
                                                                    isSearchable
                                                                    isClearable
                                                                    components={{ DropdownIndicator: null }}
                                                                    styles={customStyles}
                                                                />
                                                            </BootstrapForm.Group>
                                                        </Col> */}
                                                        
                                                    </Row>

                                                    <Row className="mt-3">
                                                        <Col xs={12}>
                                                            <BootstrapForm.Label>Experience</BootstrapForm.Label>
                                                        </Col>
                                                        <Col md={6}>
                                                            <BootstrapForm.Group className="mb-2" controlId="formBasicEmail">
                                                                <Select
                                                                    className="w-100"
                                                                    value={selectedOptionYears}
                                                                    onChange={handleSelectChangeYears}
                                                                    options={optionsYears}
                                                                    placeholder="Years"
                                                                    isSearchable
                                                                    isClearable
                                                                    components={{ DropdownIndicator: null }}
                                                                    styles={customStyles}
                                                                />
                                                            </BootstrapForm.Group>
                                                        </Col>

                                                        <Col md={6}>
                                                            <BootstrapForm.Group className="mb-2" controlId="formBasicEmail">
                                                                <Select
                                                                    className="w-100"
                                                                    value={selectedOptionMonths}
                                                                    onChange={handleSelectChangeMonths}
                                                                    options={optionsMonths}
                                                                    placeholder="Months"
                                                                    isSearchable
                                                                    isClearable
                                                                    components={{ DropdownIndicator: null }}
                                                                    styles={customStyles}
                                                                />
                                                            </BootstrapForm.Group>
                                                        </Col>
                                                        
                                                        <Col xs={12}>
                                                            <BootstrapForm.Group className="my-3" controlId="formBasicEmail">
                                                                <BootstrapForm.Label>Certifications <span className="text-danger">*</span></BootstrapForm.Label>
                                                                <BootstrapForm.Control type="text" placeholder="Certification name" className="BootstrapForms" />
                                                            </BootstrapForm.Group>
                                                        </Col>

                                                    </Row>

                                                    <div className="mt-4 d-flex justify-content-end align-items-center">
                                                        <Link as="Button" varient="primary" className="cancel-btn me-3" onClick={() => setPopupForSkill(false)}>Cancel</Link>
                                                        <Button variant="primary" className="rounded-pill px-3">Save</Button>
                                                    </div>
            
                                                </div>
                                                     
                                            </Modal.Body>
                                        </Modal>

                                    </TabPane>

                                    <TabPane tabId="4" className="p-2">
                                        <span className="fs-5">Personal details</span>
                                        <FiEdit2 className="ms-3 mb-1 cursor-pointer edit-btn-profile" />

                                        <div className="mt-2">
                                            <Row className="p-0">
                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Gender</span>
                                                    <div className="d-flex">
                                                        <span className="text-primary cursor-pointer">male</span>
                                                    </div>
                                                </Col>

                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Martial Status</span>
                                                    <div className="d-flex">
                                                        <span className="text-primary cursor-pointer">Single</span>
                                                    </div>
                                                </Col>

                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Date of birth</span>
                                                    <p className="text-primary cursor-pointer">Add DOB</p>
                                                </Col>

                                                {/* <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Category</span>
                                                    <p className="text-primary cursor-pointer">Add Category</p>
                                                </Col> */}

                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Career break</span>
                                                    <p className="text-primary cursor-pointer">Add Career break</p>
                                                </Col>

                                                {/* <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Work permit</span>
                                                    <p className="text-primary cursor-pointer">Add Work permit</p>
                                                </Col> */}

                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Permanent address</span>
                                                    <p className="text-primary cursor-pointer">Add Permanent address</p>
                                                </Col>

                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Languages</span>
                                                    <p className="text-primary cursor-pointer">Add Languages</p>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                            <td className="text-muted ps-0">Languages</td>
                                                            <td className="text-muted ps-0">Proficiency</td>
                                                            <td className="text-muted ps-0">Read</td>
                                                            <td className="text-muted ps-0">Write</td>
                                                            <td className="text-muted ps-0">Speek</td>
                                                            <td></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="ps-0">English</td>
                                                                <td className="ps-0">Expert</td>
                                                                <td className="ps-0">Yes</td>
                                                                <td className="ps-0">Yes</td>
                                                                <td className="ps-0">Yes</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="ps-0">Hindi</td>
                                                                <td className="ps-0">Begginer</td>
                                                                <td className="ps-0">No</td>
                                                                <td className="ps-0">No</td>
                                                                <td className="ps-0">Yes</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>

                                                {/* <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Differently abled</span>
                                                    <p className="text-primary">Add Differently abled</p>
                                                </Col> */}

                                            </Row>

                                            <hr />

                                        </div>
                                    </TabPane>

                                    <TabPane tabId="5" className="p-2">
                                        <span className="fs-5">Employment</span>
                                        <FiEdit2 className="ms-3 mb-1 cursor-pointer edit-btn-profile" />

                                        <div className="mt-2">
                                            <Row className="p-0">
                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Company name</span>
                                                    <div className="d-flex">
                                                        <span className="text-primary cursor-pointer">Add Company name</span>
                                                    </div>
                                                </Col>

                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Job title</span>
                                                    <div className="d-flex">
                                                        <span className="text-primary cursor-pointer">Add Job title</span>
                                                    </div>
                                                </Col>

                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Job summary</span>
                                                    <div className="d-flex">
                                                        <span className="text-primary cursor-pointer">Add Job summary</span>
                                                    </div>
                                                </Col>

                                                <Col className="mt-3" xs={12}>
                                                    <span className="text-muted">Job duration</span>
                                                    <div className="d-flex">
                                                        <span className="text-primary cursor-pointer">Add Job duration</span>
                                                    </div>
                                                </Col>

                                            </Row>

                                            <hr />

                                        </div>
                                    </TabPane>

                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default JobSeekerProfile;

