import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../assets/css/employer/jobDescription.css"
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import EmployerSidebar from "../../../components/EmployerSidebar";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { employerPostJob } from "../../../helpers/backendHelper";
import { decryptParam, encryptParam } from "../../../helpers/UrlEncryptDecrypt";

function JobDescription({ onContinue }) {


  const navigate = useNavigate();
  const secretKey = process.env.REACT_APP_WALKUPP_SECRET_KEY || "walkupp-secret-key";

  const params = useParams();
  let job_id = params.job_id;
  job_id = decryptParam(job_id,secretKey);
  const [jobDescription, setJobDescription] = useState("");
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);



  const handleContinue = (e) => {
    e.preventDefault();

    if (loading) return;  

    const data = {
      jobId: job_id,
      isDraft: true,
      jobDescription: jobDescription,
    };
    console.log('data description', data);
    setLoading(true); 

    employerPostJob(data)
      .then((x) => {
      
        console.log('api calls')

        setLoading(false); 
        if (x && x.statusCode === 200) {
          navigate(`/employer/jobPostReview/${encryptParam(job_id,secretKey)}`);
        }
      })
      .catch((e) => {
        setLoading(false); // Reset loading state on error
        console.log("error", e);
        Swal.fire({
          icon: "error",
          title: "Failed to Job Post",
          text: "Error occurred",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Close",
        });
      });
  };

  const closeSidebar = (event) => {
    if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
      setSidebarExpanded(false);
      const wrapper = document.getElementById("wrapper");
      wrapper.classList.remove("toggled");
    }
  };

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  return (

    <div id="wrapper" onClick={closeSidebar}>
      <EmployerSidebar sidebarExpanded={sidebarExpanded} />
      <div id="navbar-wrapper">
        <EmployerNavBar />
      </div>
      <Container className="mt-3">
        <Row className="justify-content-center desc-job-container">
          <Col xs={12} sm={12} md={12} lg={11} className="p-0">
            <Card className="p-lg-3 p-3 bg-light border-0 rounded">
              <Row>
                <Col>
                  <h4>Describe the job</h4>
                </Col>
              </Row>
              <div className="mt-2">
                <ReactQuill
                  value={jobDescription}
                  onChange={setJobDescription}
                  className="react-quill-editor"
                />
              </div>
              <Row className="mt-5 justify-content-end">
                <Col>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleContinue}
                    disabled={loading}  
                  >
                    {loading ? "Loading..." : "Continue"}
                  </Button>

                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default JobDescription;
