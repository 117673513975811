import Swal from "sweetalert2";


export const interviewConfrimSwalHelper = async(message) => {
           return   await Swal.fire({
                title: 'Are you sure?',
                text: `Are you sure you want to ${message} this candidate?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, ${message} it!`
            });
        }

export const interviewConfirmNowLaterSwalHelper = async() => {
    return await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to Complete the Interview?',
        icon: 'warning',
        showCancelButton: false,
        showDenyButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: 'Now',
        denyButtonText: 'Later',
    })
}

export const interviewHireRejectSwalHelper = async() => {
    return await Swal.fire({
        // title: 'Are you sure?',
        text: 'Give the Hire/Reject interview result',
        icon: 'question',
        showCancelButton: false,
        showDenyButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#4CAF50',
        // cancelButtonColor: '#d33',
        denyButtonColor: '#d33',
        confirmButtonText: 'Hire',
        denyButtonText: 'Reject',
    })
}
        
export const interviewSwalSuccessHelper = async (message) =>{
    
   return await Swal.fire({
        title: 'Success',
        text: `candidate ${message} successfully!`,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
    })

}                              
   