import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes, { func } from "prop-types";
import "../assets/css/MultiRangeSlider.css";
import { Button } from "react-bootstrap";

const MultiRangeSlider = ({ min, max, onChange, multiRangevalues, searchApi }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const [yearlyToggle, setYearlyToggle] = useState(null);

  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  function handleToggleMonthly(num) {
    setYearlyToggle(!yearlyToggle);
  }

  const handleSalaryFilterClick = async() => {
    const data = {
      minSalary : minVal,
      maxSalary : maxVal
    };
    console.log('handleSalaryFilterClick', data);
    
    if (multiRangevalues) {
      multiRangevalues(data);
    } else {
      console.error("multiRangevalues function is not defined");
    }

    await searchApi(data);
  };
  

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  return (
    <div className="salary-slider">
      <div className="inputs-row mb-4">
        <input
          className="show-values"
          type="number"
          maxLength="7"
          min={min}
          max={max}
          value={minVal}
          onChange={(e) => {
            let value = e.target.value;

            // Ensure value is non-negative and within the allowed range
            if (value >= 0 && value.length <= 7) {
              const numericValue = Math.min(Number(value), maxVal - 10000);
              setMinVal(numericValue);
              minValRef.current = numericValue;
            } else if (value < 0) {
              // If user tries to enter a negative value, reset to 0 or minimum allowed
              setMinVal(0);
              minValRef.current = 0;
            }
          }}
        />

        <span> - </span>

        <input
          className="show-values"
          type="number"
          maxLength="7"
          min={min}
          max={max}
          value={maxVal}
          onChange={(e) => {
            let value = e.target.value;

            // Ensure value is numeric and non-negative, and has no more than 7 digits
            if (value >= 0 && value.length <= 7) {
              const numericValue = Math.max(Number(value), minVal + 10000); // Ensure it follows the minVal constraint
              setMaxVal(numericValue);
              maxValRef.current = numericValue;
            } else if (value < 0) {
              // If negative value is entered, reset it to minVal + 10000 or a default positive value
              const positiveValue = Math.max(0, minVal + 10000);
              setMaxVal(positiveValue);
              maxValRef.current = positiveValue;
            }
          }}
        />
        <span className="inr">INR</span>
      </div>

      <div className="multirange">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={(event) => {
            const value = Math.min(Number(event.target.value), maxVal);
            setMinVal(value);
            minValRef.current = value;
          }}
          className="thumb thumb--left"
          style={{ zIndex: minVal > max - 100 && "5" }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={(event) => {
            const value = Math.max(Number(event.target.value), minVal);
            setMaxVal(value);
            maxValRef.current = value;
          }}
          className="thumb thumb--right"
        />

        <div className="slider">
          <div className="slider__track" />
          <div ref={range} className="slider__range" />
        </div>
      </div>

      <div className="payment-buttons">
        <Button className="payment-btn" onClick={() => handleSalaryFilterClick()}>Set</Button>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
