import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../assets/css/employer/jobDescription.css";
import "../../assets/css/employer/reviewJobPost.css";

// Schedule and Job Type options
const scheduleOptions = [
    "Fixed shift", "Day shift", "Monday to Friday", "Morning shift", "Evening shift",
    "Night shift", "US shift", "UK shift", "Overnight shift", "Rotational shift",
    "Weekend availability", "Other"
];

const jobTypeOptions = [
    "Full-time", "Permanent", "Part-time", "Contract", "Temporary", "Internship", "Volunteer", "Freelance"
];

const jobType2 = [
    "onSite", "remote"
];
const experience = [
    "Fresher", "1+", "2+", "5+", "10+"
];
const hires = [
    1, 2, 3, 4, 5, 10, 20, 50, 100
];
const timeLine = [
    "Less than 2 weeks", "2 to 4 weeks", "More than 4 weeks"
];

const EditModal = ({ show, handleClose, handleSave, field, value, setValue }) => {

    const [error, setError] = useState("");

    console.log('inside the edit model')
    const handleToggle = (option) => {
        if (value.includes(option)) {
            console.log('value editmodel', value)
            setValue(value.filter(v => v !== option));
        } else {
            setValue([...value, option]);
        }
    };

    const handleSingleToggle = (option) => {
        if (value === option) {
            setValue("");
        } else {
            setValue(option);
        }
    };

    const isMultiSelect = field === "Schedule" || field === "Job Type";

    const isDropdown = field === "Job Type 2" || field === "experience" || field === "number of hire" || field === "recruitment timeline"

    const options = field === "Schedule" ? scheduleOptions : jobTypeOptions;

    const dropdownOption =
        field === "Job Type 2"
            ? jobType2
            : field === "experience"
                ? experience
                : field === "number of hire"
                    ? hires
                    : timeLine;

    // const handleSubmitSave = () => {
    //     if(field === "minimum and maximum salary"){
    //         if(value[0] > value[1]){
    //             setError("Maximum salary must be greater than or equal to the minimum salary");
    //             return;
    //         }
    //         else{
    //             handleSave();
    //         }
    //     }
    //     else{
    //         handleSave();
    //     }
    // }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit {field}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {field === "Schedule" ? (
                    <div className="d-flex flex-wrap">
                        {options.map(option => (
                            <Button
                                key={option}
                                variant={value.includes(option) ? "primary" : "outline-primary"}
                                onClick={() => handleToggle(option)}
                                className="m-1"
                            >
                                {option}
                            </Button>
                        ))}
                    </div>
                ) : field === "Job Type" ? (
                    <div className="d-flex flex-wrap">
                        {options.map(option => (
                            <Button
                                key={option}
                                variant={value.includes(option) ? "primary" : "outline-primary"}
                                onClick={() => handleSingleToggle(option)}
                                className="m-1"
                            >
                                {option}
                            </Button>
                        ))}
                    </div>
                ) : field === "planned date" ? (
                    <>
                        <Form>
                            <div className="ms-3 d-flex align-items-center">
                                <Form.Check
                                    type="radio"
                                    id="radioYes"
                                    value="Yes"
                                    checked={value === "Yes"}
                                    onChange={(e) => setValue(e.target.value)}
                                    className="filter-radio"
                                />
                                <label htmlFor="radioYes" className="filter-label">Yes</label>
                            </div>
                            <div className="my-2 ms-3 d-flex align-items-center">
                                <Form.Check
                                    type="radio"
                                    id="radioNo"
                                    value="No"
                                    checked={value === "No"}
                                    onChange={(e) => setValue(e.target.value)}
                                    className="filter-radio "
                                />
                                <label htmlFor="radioNo" className="filter-label">No</label>
                            </div>
                        </Form>
                    </>
                ) : field === "date" ? (
                    <>
                        <Form.Control
                            type="date"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </>
                ) : field === "minimum and maximum salary" ? (
                    <>
                        <Form.Group>
                            <Form.Label>Maximum Salary Per Annum</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="30K"
                                value={value[1]}
                                isInvalid={!!error} // Highlight input if there's an error
                                onChange={(e) => {
                                    const updatedValue = [...value];
                                    const newMax = e.target.value;

                                    if (Number(newMax) < Number(updatedValue[0])) {
                                        setError("Maximum salary must be greater than or equal to the minimum salary");
                                        return;
                                    }

                                    updatedValue[1] = newMax;
                                    setValue(updatedValue);
                                    setError(""); // Clear error when valid
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-4">
                            <Form.Label>Minimum Salary Per Annum</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="10K"
                                value={value[0]}
                                isInvalid={!!error} // Highlight input if there's an error
                                onChange={(e) => {
                                    const updatedValue = [...value];
                                    updatedValue[0] = e.target.value;
                                    setValue(updatedValue);

                                    // Check if minimum salary is greater than maximum salary
                                    if (Number(updatedValue[0]) > Number(updatedValue[1])) {
                                        setError("Minimum salary cannot be greater than maximum salary");
                                    } else {
                                        // Clear the error when it's valid
                                        setError("");
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                        </Form.Group>


                    </>
                ) : field === "Job Description" ? (
                    <>
                        <ReactQuill
                            value={value}
                            onChange={(content) => setValue(content)}
                            className="react-quill-editor"
                        />
                    </>
                ) : isDropdown ? (
                    <>
                        <Form.Select aria-label="Default select example" value={value} onChange={(e) => setValue(e.target.value)}>
                            {dropdownOption.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Select>
                    </>
                ) : (
                    <Form.Control
                        type="text"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;
