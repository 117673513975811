import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Container, Image } from 'react-bootstrap';
import EmployerSidebar from '../../../components/EmployerSidebar';
import EmployerNavBar from '../../../components/Employer/EmployerNavbar';
import { getJobApplicationByEmployerId, getAllJobSeeker } from '../../../helpers/backendHelper';
import { useParams } from 'react-router-dom';
import '../../../assets/css/employer/Candidate.css';
import { IMAGE_HOST_URL } from '../../../helpers/urlHelper';
import getImage from '../../../assets/images/user.webp';
import { faCheck, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from 'react-bootstrap/Pagination';
import Nodata from "../../../assets/images/no-data.webp";

const AllCandidates = () => {
  const params = useParams();
  const jobId = params.jobId;
  const isFromJob = params.isFromJob;
  const [loading, setLoading] = useState(true);
  const [jobSeekerData, setJobSeekerData] = useState([]);
  const [activePage,setActivePage] = useState(1);
  const [totalPage,setTotalPage] = useState();
  
console.log(typeof(jobSeekerData));

  useEffect(() => {
    if (loading) {
      fetchCandidates(activePage);
    }
  }, [loading, activePage]);

  const fetchCandidates = async (data) => {
    try {
      const response = await getAllJobSeeker(data);
      if (response && response.statusCode === 200) {
        // Filter if needed
        let jobSeekers = response.jobseeker;
        if (isFromJob === 'true' && jobId) {
          jobSeekers = jobSeekers.filter((job) => job.jobId === jobId);
        }
        setJobSeekerData(jobSeekers);
        console.log("jobSeekerData",response);
        setTotalPage(response.totalPages);
        
      } else {
        console.error('Failed to fetch candidates');
      }
    } catch (e) {
      console.error('Error during fetch', e);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber); 
  }

  // Render pagination numbers dynamically
  const renderPageNumbersJobs = () => {
    const pages = [];

    // Display pagination for less than 5 pages without ellipsis
    
    if (totalPage <= 5) {
        for (let number = 1; number <= totalPage; number++) {
            pages.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }
    }

    else {
        // Display ellipsis and limited page numbers if more than 5 pages
        if (activePage > 2) {
            pages.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>{1}</Pagination.Item>);
            if (activePage > 3) pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        for (let number = Math.max(1, activePage - 1); number <= Math.min(totalPage, activePage + 1); number++) {
            pages.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (activePage < totalPage - 2) {
            pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
            pages.push(
                <Pagination.Item key={totalPage} onClick={() => handlePageChange(totalPage)}>
                    {totalPage}
                </Pagination.Item>
            );
        }
    }

    return pages;
  };

  return (
    <>
      <div id="wrapper">
        <EmployerSidebar />
        <div id="navbar-wrapper">
          <EmployerNavBar />
        </div>
        <div className="candidate-dashboard">
          <div className="header">
            <h4>Candidates</h4>
          </div>

          <Container>
            {loading ? (
              <p>Loading candidates...</p>
            ) : jobSeekerData === undefined ? (
              <Image className='nodata-img' src={Nodata} />
            ) : (
              
              <Row>
                { jobSeekerData.length > 0 ?
                <>
                  {jobSeekerData.map((candidate)=>(

                  <Col xs={12}>
                    <div key={candidate.id} className="profileCard">
                      <div className="profileCard-header px-3 pt-3">
                        <Row className='w-100'>
                          <Col md={2} lg={1}>
                            <img src={`${candidate.jobSeekerProfileImage ? IMAGE_HOST_URL + candidate.jobSeekerProfileImage : getImage}`} alt="user" className="profileCard-image" />
                          </Col>

                          <Col md={10} lg={11} className=''>
                            <Row className='w-100'>
                              <Col lg={6}>
                                <h2 className='mt-2 mt-md-0'>{candidate.firstName}</h2>
                                <p>{candidate.mobileNumber}</p>
                                <p>{candidate.email}</p>
                              </Col>

                              <Col lg={6}>
                                <p className='mt-lg-2'>{candidate.cityState}</p>
                                <p>{candidate.country}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* <div className="w-100 profileCard-info">
                          <Row>
                            <Col md={6}>
                              <h2>{candidate.firstName}</h2>
                              <p>{candidate.mobileNumber}</p>
                              <p>{candidate.email}</p>
                            </Col>

                            <Col md={6}>
                              <p>{candidate.cityState}</p>
                              <p>{candidate.country}</p>
                            </Col>
                          </Row>
                        </div> */}
                      </div>
                      <div className="profileCard-footer">
                        <button className="resume-btn" onClick={() => downloadFile(`http://localhost:3030/${candidate.resumePath}`)}>
                          Resume <FontAwesomeIcon icon={faDownload} />
                        </button>
                          {/* <div className="action-buttons">
                            <button
                              className="select-btn"
                              // onClick={() => OnStatusChange('SetupInterView', candidate)}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button
                              className="reject-btn"
                              // onClick={() => OnStatusChange('Reject', candidate)}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div> */}

                      </div>
                    </div>
                  </Col>
                  )) }

                  <Pagination className="justify-content-center mt-5 custom-pagination">
                    <Pagination.First onClick={() => handlePageChange(1)} disabled={activePage === 1} />
                    <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
                    {renderPageNumbersJobs()}
                    <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPage} />
                    <Pagination.Last onClick={() => handlePageChange(totalPage)} disabled={activePage === totalPage} />
                  </Pagination>
                </> :
                <Image className='nodata-img' src={Nodata} />
                }
              </Row>
            )}
          </Container>
        </div>
      </div>
    </>
  );
};

// Function to download files
const downloadFile = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'resume.pdf');
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default AllCandidates;
