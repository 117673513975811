import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Badge, Card, Col, Container, Image, Row } from "react-bootstrap";
import Pagination from 'react-bootstrap/Pagination';
import { getAllSavedJobs } from "../helpers/backendHelper";
import { Link } from "react-router-dom";
import { BsBookmarkDash, BsBookmarkDashFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { CiGrid41 } from "react-icons/ci";
import "../assets/css/SavedJobs.css";
import Nodata from "../assets/images/no-data.webp";

const SavedJobs = () => {

    const [isToggled, setIsToggled] = useState(false);
    const [allSavedJobs,setAllSavedJobs] = useState([]);
    const [activePage,setActivePage] =  useState(1);
    const [totalPage,setTotalPage] = useState();

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    const getSavedJobs = async (data) => {
        console.log("payload data",data);
        
        try {
            const response = await getAllSavedJobs(data);
            console.log("response all saved jobs",response);
            setAllSavedJobs(response.jobs);
            setTotalPage(response.totalPages);            
        } catch (error) {
            console.log("error.....",error,allSavedJobs);
        }
    }

    useEffect(()=>{   
        const payload = {
            page:activePage
        }
        getSavedJobs(payload);
    },[activePage]);

    const handlePageChange = (pageNumber) => {
        if(pageNumber > 0 && pageNumber <= totalPage){
            setActivePage(pageNumber);
        }
    }

    // Render pagination numbers dynamically
    const renderPageNumbersJobs = () => {
        const pages = [];
        
        // Display pagination for less than 5 pages without ellipsis
        
        if (totalPage <= 5) {
            for (let number = 1; number <= totalPage; number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                        {number}
                    </Pagination.Item>
                );
            }
        }

        else {
            // Display ellipsis and limited page numbers if more than 5 pages
            if (activePage > 2) {
                pages.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>{1}</Pagination.Item>);
                if (activePage > 3) pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }

            for (let number = Math.max(1, activePage - 1); number <= Math.min(totalPage, activePage + 1); number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                        {number}
                    </Pagination.Item>
                );
            }

            if (activePage < totalPage - 2) {
                pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
                pages.push(
                    <Pagination.Item key={totalPage} onClick={() => handlePageChange(totalPage)}>
                        {totalPage}
                    </Pagination.Item>
                );
            }
        }

        return pages;
    };

    return (
        <div>
            <Navbar />
            
            <Container>
                <div className="mt-4 d-flex justify-content-between">
                    <strong className="fs-5">Saved Jobs</strong>
                    { allSavedJobs && allSavedJobs.length !==0 ?
                        <div className='grid-toggle' onClick={handleToggle}>
                            {isToggled ? <RxHamburgerMenu className='m-auto' /> : <CiGrid41 className='m-auto' />}
                        </div> 
                        : null
                    }
                </div>

                {
                    allSavedJobs && allSavedJobs.length !== 0 ? 
                    <div>
                        {
                            isToggled ?
                                <div className="mt-2">
                                    <Row>
                                        {allSavedJobs.map((grid) =>
                                            <Col sm={6} lg={4} key={grid.job._id}>
                                                <Card className="job-card2 my-2">
                                                    <Card.Body>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="job-card-star">
                                                                <FaStar className="star-icon" />
                                                            </div>
                                                            <div className="job-card-avatar2 text-center">
                                                                <Link className="avatar-icon" to='/'>A</Link>
                                                            </div>
                                                            {/* <div className='grid-toggle2' onClick={() => handleToggleMark(grid._id)}>
                                                                {allSavedJobs.some((jobs)=> jobs.job._id === grid._id) ? <BsBookmarkDashFill className='m-auto' /> : <BsBookmarkDash className='m-auto' />}
                                                            </div> */}
                                                        </div>

                                                        <div className="mt-3">
                                                            <Badge bg="light" className="full-time-badge mt-4 mb-1">{grid.job.jobDetails.jobType}</Badge>
                                                            <h4 className="mt-2 mb-2 text-fulltime2 job-title2 cursor-pointer">{grid.job.jobBasics.jobTitle}</h4>
                                                            <p className="salary-range2 mt-1 mb-3 mt-xl-3 mb-md-5">
                                                                {grid.job.salary?.range?.minimum}-{grid.job.salary?.range?.maximum} <span>/ yearly</span>
                                                            </p>
                                                        </div>

                                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                                            <p className="text-muted location">{grid.job.jobBasics.jobLocation ? grid.job.jobBasics.jobLocation.city : ""}</p>
                                                            <Link to={`/job/detail/${grid.job._id}`} className='details-button'>DETAILS</Link>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </div>

                                : <div>

                                    {allSavedJobs.map((jobs) =>
                                        <div className="job-card1 mt-3" key={jobs.job._id}>
                                            <Row className='w-100 m-0'>
                                                <Col xs={12} md={5} lg={4} xl={5} className='d-flex align-items-center'>
                                                    <div className="job-card-star">
                                                        <FaStar className="star-icon" />
                                                    </div>

                                                    <div className="job-card-left m-sm-1 m-2">
                                                        <div className="job-card-avatar">
                                                            <Link className="avatar-circle" to='/jobs'>A</Link>
                                                        </div>
                                                        <div className="job-card-info text-start">
                                                            <Link className='text-fulltime' to='/jobs'>{jobs.job.jobDetails.jobType}</Link>
                                                            <Link className='job-title text-fulltime2' to=''>{jobs.job.jobBasics.jobTitle}</Link>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col sm={7} md={4} lg={4} xl={4} className='d-flex align-items-center'>
                                                    <div className="m-sm-1 m-2">
                                                        <p className="job-location text-muted text-start cursor-pointer">{jobs.job.jobBasics.jobLocation ? jobs.job.jobBasics.jobLocation.city : ""}</p>
                                                        <p className="job-salary text-start">{jobs.job.salary?.range?.minimum}-{jobs.job.salary?.range?.maximum}<span className='job-experience'> / yearly</span></p>
                                                    </div>
                                                </Col>

                                                <Col sm={5} md={3} lg={4} xl={3} className='detail-btn-content d-flex align-items-center justify-content-end'>
                                                    <div className="d-flex align-items-center m-sm-1 m-2">
                                                        {/* <div className='grid-toggle2 me-3' onClick={() => handleToggleMark(job._id)}>
                                                            {allSavedJobs.some((jobs)=> jobs.job._id === job._id) ? <BsBookmarkDashFill className='m-auto' /> : <BsBookmarkDash className='m-auto' />}
                                                        </div> */}
                                                        <Link to={`/job/detail/${jobs.job._id}`} className='details-button'>DETAILS</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </div>
                        }

                    <Pagination className="justify-content-center mt-5 custom-pagination">
                        <Pagination.First onClick={() => handlePageChange(1)} disabled={activePage === 1} />
                        <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
                        {renderPageNumbersJobs()}
                        <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPage} />
                        <Pagination.Last onClick={() => handlePageChange(totalPage)} disabled={activePage === totalPage} />
                    </Pagination>
                    </div>
                    : <Image className='nodata-img' src={Nodata} />
                }
                    
            </Container>
        </div>
    );
}

export default SavedJobs;